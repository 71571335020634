import React, { ReactElement, useEffect, useState } from "react";
import { styles } from "../styles";
import GroupIcon from "@mui/icons-material/Group";
import CurrencyYenSharpIcon from "@mui/icons-material/CurrencyYenSharp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import ButtonArrow from "../../components/GreenCity/ButtonArrow";
import { useLocation, useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setGroupInfo, clearGroupInfo } from "../../redux/Reducers/gcGroupReducer";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LoadingModal from "../../components/LoadingModal";
import CancelConfirmationModal from "../../components/Models/GreenCity/CancelConfirmation";
// interface Props {}

function GroupOwnerSettingPage(): ReactElement {
  const [cancelRequests, setCancelRequests] = React.useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const { user } = useSelector((state: any) => state.userAuth);
  const { groupInfo } = location.state || {}; // Destructure your props
  const { user } = useSelector((state: any) => state.userAuth);
  const [accountInfo,setAccountInfo]=useState<any>();

  console.log("groupInfo in GroupOwnerSettingPage", groupInfo, user);

  const getCancelRequests = async () => {
    try {
      const res = await gcServices.getCancelRequestsOfGroup(groupInfo?.id);
      setCancelRequests(res?.post_event_users);
    } catch (error) {
      console.error(error);
    }
  };

  const getGroupBalance=async ()=>{
    try {
      const res = await gcServices.getGroupBalance(groupInfo?.id);
      console.log(res?.wallet_information?.balance?.money?.amount);
     setAccountInfo(res?.wallet_information?.balance?.money?.amount);
    } catch (error) {
      console.error(error);
    }
  }

  const getGroupInfo = () => {
    return {
      id: groupInfo.id,
      groupName: groupInfo.name,
      groupIsPublic: groupInfo?.group_type,
      groupImage: groupInfo?.group_icon ? `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}` : "",
      groupMainImage: groupInfo?.banner_image ? `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.banner_image}` : "", // From state
      groupDesc: groupInfo?.description, // From state
      groupAnnouncements: groupInfo?.member_group_activities, // From state
      groupWebURLs: groupInfo?.social_links, // You can replace this with the actual value
      groupApprovalRequired: true,
      piiRequired: groupInfo?.personal_information_needed ? "YES" : "NO",
      groupIsLimited: true,
      groupMaxMembers: groupInfo.participants_count,
      groupMemberShipFeeAmount: groupInfo?.joining_fee_amount,
      groupPaymentAutomatic: groupInfo?.payment_type,
      groupAutomaticPaymentDay: groupInfo?.membership_fee_deduction_date,
      isMonthly: groupInfo?.fee_type === "monthly", // Static value
      groupPaymentField: groupInfo?.fee_amount,
      groupPaymentDescription: groupInfo?.fee_explanation,
      user: groupInfo.user,
      owner: groupInfo.owner,
      role:groupInfo.owner.id===user.id?'leader':groupInfo.role,
      memberGroupUsers: groupInfo?.member_group_users,
    };
  };

  const handleReview = () => {
    dispatch(clearGroupInfo());
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/groupinforeview");
  };

  const invite = () => {
    // Dispatch action to save group details
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/invitation");
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const res = await gcServices?.checkIsMembership(user.id, groupInfo?.id);
      await gcServices.deleteMember(res?.member_group_users[0]?.id);
      navigate(-1);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // const inviteMembers = () => {
  //   // Dispatch action to save group details
  //   dispatch(setGroupInfo(getGroupInfo()));
  //   navigate("/invite");
  // };

  // const handleMembers = () => {
  //   navigate("/members", {
  //     state: {
  //       groupInfo: groupInfo,
  //     },
  //   });
  // };

  const handleMemberList = () => {
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/memberlist", {
      state: {
        groupInfo: groupInfo,
      },
    });
  };

  const navigateToViewGroup = (post_type: "public" | "private" | "private_users") => {
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/viewGroupPost", {
      state: {
        post_type: post_type,
      },
    });
  };

  // const createPrivateUserPost = () => {
  //   dispatch(setGroupInfo(getGroupInfo()));
  //   navigate("/createGroupPost", {
  //     state: {
  //       post_type: "private_users",
  //     },
  //   });
  // };

  const navigateToGroupCalendar = () => {
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/groupeventscalendar");
  };

  const navigateToCancelRequests = () => {
    // dispatch(setGroupInfo(getGroupInfo()));
    navigate("/cancelrequests", {
      state: {
        groupId: groupInfo?.id,
      },
    });
  };

  const navigateToGroupEventSetting = () => {
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/groupeventsetting");
  };

  const navigateToGroupMemberFee = () => {
    navigate("/groupmembersfee", {
      state: {
        groupInfo: groupInfo,
      },
    });
  };

  const navigateToGroupPaymentHistory = () => {
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/grouppaymenthistory");
  };

  useEffect(() => {
    getCancelRequests();
    getGroupBalance();
    // eslint-disable-next-line
  }, []);

  const navigatePaytoStoreQR = () => {
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/paytostoreqr");
  };

  const navigatePaytoUser = () => {
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/paytouser");
  };
  const groupReimbursements = () => {
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/groupreimbursements");
  };

  return (
    <div style={{ paddingTop: 100, marginLeft: 16 }}>
      <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
      <Box style={{ padding: 16 }}>
        <Typography style={{ fontWeight: "700" }}>
          <img
            src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`}
            alt={"user_img"}
            style={{
              ...styles.farmProfileIcon,
              height: 50,
              width: 50,
              margin: 0,
            }}
          />
          &nbsp;{groupInfo?.name}
        </Typography>
        {groupInfo.owner.id === user.id && <Typography style={{ fontSize: 10, textAlign: "center" }}>あなたはこのグループの「リーダー」です。</Typography>}
        {groupInfo?.role === "sub-leader" && <Typography style={{ fontSize: 10, textAlign: "center" }}>あなたはこのグループの「サブリーダー」です。</Typography>}
        {groupInfo?.role === "finance" && <Typography style={{ fontSize: 10, textAlign: "center" }}>あなたはこのグループの「会計」です。</Typography>}
        {groupInfo?.role === "member" && <Typography style={{ fontSize: 10, textAlign: "center" }}>あなたはこのグループの「メンバー」です。</Typography>}
      </Box>

      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <SupervisedUserCircleOutlinedIcon /> グループページ
        </p>
        {/* // */}

        <ButtonArrow
          title="グループトップ"
          onClick={() => {
            handleReview();
          }}
        />

        {/* <ButtonArrow
          title="グループへの招待リンク"
          disabled={groupInfo?.role === "member"}
          onClick={() => {
            invite();
          }}
        /> */}
      </div>

      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <BorderColorOutlinedIcon /> お支払い関係
        </p>
        {/* // */}

        <ButtonArrow title="みんなの投稿" onClick={() => navigateToViewGroup("public")} />

        <ButtonArrow title="イベント投稿" onClick={navigateToGroupEventSetting} />

        <ButtonArrow title="グループ内の投稿" onClick={() => navigateToViewGroup("private")} />

        <ButtonArrow
          disabled={groupInfo?.role === "member" || groupInfo?.role === "finance"}
          title="グループ内のプライベート投稿"
          onClick={() => navigateToViewGroup("private_users")}
        />
        {/* <ButtonArrow disabled={groupInfo?.role === "member"} title="グループ内のプライベート投稿" onClick={() => navigateToViewGroup("private_users")} /> */}

        {/* <ButtonArrow title="グループ内のプライベート投稿" onClick={() => {}} /> */}
      </div>
      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <GroupIcon /> 投稿
        </p>

        <ButtonArrow
          title="メンバー一覧"
          onClick={() => {
            handleMemberList();
          }}
        />

        <ButtonArrow
          disabled={groupInfo?.role === "member" || groupInfo?.role === "finance"}
          title="メンバーを招待する"
          onClick={() => {
            invite();
          }}
        />
      </div>

      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <CurrencyYenSharpIcon
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              width: 26,
              height: 26,
              borderRadius: 13,
              padding: 2,
              margin: 2,
            }}
          />
          お支払い関係
        </p>
        {groupInfo?.role!=='member'&&<Typography style={{ fontSize: 10, textAlign: "center" }}>グループの「めぶくPay」残高：{accountInfo} 円</Typography>}

        <ButtonArrow
          disabled={groupInfo?.role === "member"}
          title="メンバーに送金する"
          onClick={() => {
            navigatePaytoUser();
          }}
        />
        <ButtonArrow
          disabled={groupInfo?.role === "member"}
          title="店舗にて支払う"
          onClick={() => {
            navigatePaytoStoreQR();
          }}
        />
        <ButtonArrow
          disabled={groupInfo?.role === "member"}
          title="立て替え精算"
          onClick={() => {
            groupReimbursements();
          }}
        />
        <ButtonArrow
          disabled={groupInfo?.role === "member" || groupInfo?.fee_amount===0}
          // disabled={groupInfo.fee_amount === null}
          title="支払い状況"
          onClick={navigateToGroupMemberFee}
        />
        {/* <ButtonArrow
          disabled={!groupInfo.is_paid}
          title="支払い状況"
          onClick={navigateToGroupMemberFee}
        /> */}
        <ButtonArrow
          disabled={groupInfo?.role === "member"}
          title="イベントキャンセルの精算"
          onClick={() => {
            navigateToCancelRequests();
          }}
          badge={cancelRequests}
        />
        <ButtonArrow disabled={groupInfo?.role === "member"} title="利用履歴" onClick={navigateToGroupPaymentHistory} />
      </div>

      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <InsertCommentIcon />
          チャット
        </p>

        <ButtonArrow
          title="チャットリスト"
          disabled={groupInfo?.role === "member" || groupInfo?.role === "finance"}
          onClick={() => {
            navigate("/chatmembers", {
              state: {
                groupInfo: groupInfo,
              },
            });
          }}
        />
      </div>

      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <CalendarMonthIcon
            style={{
              borderWidth: 1,
              width: 26,
              height: 26,
              padding: 2,
              margin: 2,
            }}
          />
          カレンダー
        </p>

        <ButtonArrow title="イベントカレンダーを見る" onClick={navigateToGroupCalendar} />
      </div>

      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <SettingsOutlinedIcon
            style={{
              borderWidth: 1,
              width: 26,
              height: 26,
              padding: 2,
              margin: 2,
            }}
          />
          そのほか
        </p>

        {/* <ButtonArrow
          title="グループを閉じる"
          disabled={
            groupInfo?.role == "member" || groupInfo?.role === "finance"|| groupInfo?.role === "sub-leader"
          }
          onClick={() => {}}
        /> */}
        <ButtonArrow disabled={groupInfo.owner.id === user.id} title="グループから脱退する" onClick={() => setShowConfirmationModal(true)} />
       
      </div>
      <LoadingModal visible={loading} />
      <CancelConfirmationModal
        message={"このグループを退会してもよろしいですか?"}
        showButton
        onClose={() => setShowConfirmationModal(false)}
        open={showConfirmationModal}
        onContinue={handleDelete}
      />
    </div>
  );
}

export default GroupOwnerSettingPage;
