import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, FormControl, MenuItem, Select } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react'
// import { useSelector } from 'react-redux';
import { colors } from '../../../common/colors';
import IconButtonArrow from '../../../components/GreenCity/IconButtonArrow';
import LoadingModal from '../../../components/LoadingModal';
import gcServices from '../../../services/app-services/greenCity/gcServicesR6';
import profile from "../../../assets/images/profile_img.png";

interface Props {
    onNext: (group: any) => void;
    groupId?:string,
    eventId?:string,
}

function SeclectUser({onNext,groupId,eventId}: Props): ReactElement {
    const [isLoading,setIsLoading]=useState(false);
    // const { user } = useSelector((state: any) => state.userAuth);

    const [users,setUsers]=useState([]);
    const [events,setEvents]=useState([])
    const [event,setEvent]=useState({id:'',title:''})
    const [selectedUser,setSelectedUser]=useState<any>();

    useEffect(()=>{
        apiCalls();
    },[])
    const apiCalls = async () => {
        setIsLoading(true);
        try {

          const event=`external/post_events?per_page=1200&q[post_member_group_id_eq]=${eventId}`
          const group=`external/member_group_users?per_page=1200&q[member_group_id_eq]=${groupId}`
         
          const finalURL=groupId?group:event
          const data = await gcServices.getUsersGroupOREvents(finalURL);
          console.log(data);
          if(groupId){
            setUsers(data.member_group_users);
          }else if(eventId){
          setEvents(data.post_events)
          setEvent(data.post_events[0].id)
          if(data.post_events[0].users)
           setUsers(data.post_events[0].users);
          }
         
          setIsLoading(false);
        } catch (error: any) {
          setIsLoading(false);
        }
      };

    const handleSubmit=()=>{
      if(groupId){
        onNext(selectedUser?.user)
      }else{
        onNext(selectedUser)
      }
       
    }

   const  handleGroup=(user:any)=>{
            console.log(user)
            setSelectedUser(user)
   }
   const handleChange=(event:any)=>{
        console.log(event.target.value );
        setEvent(event.target.value);
        const eventDetails :any= events.find((e:any) => e?.id === event.target.value);
        console.log(eventDetails)
        if(eventDetails?.users)
        setUsers(eventDetails?.users);


   }

  return (
    <div style={{height:320,width:'100%',backgroundColor:'whitesmoke',margin:6,padding:4,border:'2px solid gray' ,borderRadius:10}}>

    <Box display="flex" flexDirection="column" sx={{height:260,width:'100%',overflow:'scroll'}}>
{eventId&&
    <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={event}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
           {events?.map((event: any, index: number) => (
          <MenuItem value={event.id}>{event.title}</MenuItem>
           ))}
        </Select>
      </FormControl>}
<>{groupId?<>{users?.map((group: any, index: number) => (
              <IconButtonArrow
              borderColor={group?.id===selectedUser?.id?colors.farm_green500:colors.farm_gray100}
                key={index} // Use a unique key in a real app
                icon={<img alt="" height={26} width={26} style={{ borderRadius: 13 }} src={group?.user?.image !== null ? "data:image/jpeg;base64," + group?.user?.image : profile}  />}
                title={group?.user?.nickname || group?.user?.name} // Title from the group
                // role={group?.owner?.id === user?.id ? "leader" : group?.role} // Title from the group
                onClick={() => {
                   handleGroup(group);
                }}
              />
             
            ))}</>:<>{users?.map((user: any, index: number) => (
              <IconButtonArrow
              borderColor={user?.id===selectedUser?.id?colors.farm_green500:colors.farm_gray100}
                key={index} // Use a unique key in a real app
                icon={<img alt="" height={26} width={26} style={{ borderRadius: 13 }} src={user?.image !== null ? "data:image/jpeg;base64," + user?.image : profile}  />}
                title={user?.nickname || user?.name } // Title from the group
                // role={group?.owner?.id === user?.id ? "leader" : group?.role} // Title from the group
                onClick={() => {
                   handleGroup(user);
                }}
              />
             
            ))}</>}</>
            
          </Box>      

      <Button
        // disabled={!agree}
        color="secondary"
        sx={styles.submitButton}
        size={"large"}
        variant="outlined"
        onClick={handleSubmit}
        endIcon={<FontAwesomeIcon style={{ position: "absolute", top: 0, right: 0, margin: "10px" }} icon={faChevronRight} />}
      >
        つぎへ
      </Button>
      <LoadingModal visible={isLoading} />


    </div>
  )
}
const styles = {
 
    submitButton: {
      width: "80%",
      alignSelf: "center",
      margin: "0 auto",
      display: "flex",
      borderRadius: 20,
      border: "2px solid",
      marginBottom: 8,
    },
   
  };

export default SeclectUser
