import React from "react";
import { Modal, Box, Typography } from "@mui/material";

// Define the props interface
interface CancelConfirmationModalProps {
  open: boolean;
  showButton?: boolean;
  message: string;
  onClose: () => void;
  onContinue: () => void;
  noText?:string;
  yesText?:string;
}

const CancelConfirmationModal: React.FC<CancelConfirmationModalProps> = ({
  open,
  onClose,
  onContinue,
  message,
  noText,
  yesText
}) => {
  //   const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  //   const [showMainModal, setShowMainModal] = useState(open);

  // Update showMainModal when 'open' prop changes
  //   useEffect(() => {
  //     setShowMainModal(open);
  //   }, [open]);

  //   const handleDelete = () => {
  //     setShowMainModal(false); // Hide first modal
  //     onContinue();
  //     setShowSuccessPopup(true);
  //     setTimeout(() => {
  //       setShowSuccessPopup(false);
  //       onClose();
  //     }, 1000);
  //   };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85%",
            maxWidth: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            // opacity: showMainModal ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          <Typography
            variant="body1"
            sx={{ mb: 2, textAlign: "center", padding: "30px 20px" }}
          >
            {message}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                cursor: "pointer",
                width: "50%",
                textAlign: "center",
                border: "0.5px solid gray",
                borderRight: "none",
                borderLeft: "none",
                padding: "10px",
              }}
              onClick={onClose}
            >
              {noText?noText:'近い'}
            </Typography>
            <Typography
              sx={{
                cursor: "pointer",
                width: "50%",
                textAlign: "center",
                border: "0.5px solid gray",
                borderRight: "none",
                padding: "10px",
              }}
              onClick={onContinue}
            >
              {yesText?yesText:'消去'}
            </Typography>
          </Box>
        </Box>
      </Modal>

      {/* <Modal open={showSuccessPopup}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85%",
            maxWidth: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            padding: "20px",
            opacity: showSuccessPopup ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            削除されました
          </Typography>
        </Box>
      </Modal> */}
    </>
  );
};

export default CancelConfirmationModal;
