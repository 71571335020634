import { Box,  Divider, List, ListItem, Typography } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/ja"; // Import Japanese locale
import React, { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import LoadingModal from "../../../components/LoadingModal";
import CancelConfirmationModal from "../../../components/Models/GreenCity/CancelConfirmation";
import ConfirmationModal from "../../../components/Models/GreenCity/ConfirmationModal";
import MessageModal from "../../../components/Models/GreenCity/MessageModal";
import UserCard from "../../../components/Models/GreenCity/UserCard";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import { styles } from "../../styles";


dayjs.locale("ja"); 
function GroupMembers(): ReactElement {
  const location = useLocation();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const { groupInfo } = location.state || {}; // Destructure your props

  const [members, setMembers] = useState([]);
  const [member, setMember] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  console.log(groupInfo);
  const [open, setOpen] = React.useState(false);
  const [deleteId,setId]=useState<any>('');
  const [message,setMessage]=useState('')

  const handleDelete = (id:any) => {
    setId(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    apiCall();
    // eslint-disable-next-line
  }, []);

  const apiCall = async () => {
    const res = await gcServices.getMemberPendingList(groupInfo.id);
    setMembers(res?.member_group_users);
  };

  const handleApprove = async (member: any) => {
    setLoading(true);
    try {
      if (groupInfo?.is_paid) {
        await gcServices.updateMember(member?.id, "unpaid_approved");
      } else {
        await gcServices.updateMember(member?.id, "approved");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDeleteMember = async (id: any) => {
    setLoading(true);
    try {
      let body={
        role:'denied',
        status:'denied'
      }
      await gcServices.updateMemberAccept(deleteId, body);
      apiCall();
      setMessage('拒否されました');
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  
  };

  // const handleDeleteMember = async (id: any) => {
  //   console.log(`User with ID ${id} deleted`);
  //   setLoading(true);
  //   try {
  //     await gcServices.deleteMember(deleteId);
  //     apiCall();
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  const handleMoreOptions = (id: any) => {
    console.log(`More options for user with ID ${id}`);
  };

  return (
    <div style={{ paddingTop: 100, marginLeft: 16 }}>
      <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
      <Box style={{ padding: 16 }}>
        <Typography style={{ fontWeight: "700" }}>
          <img
            src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`}
            alt={"user_img"}
            style={{
              ...styles.farmProfileIcon,
              height: 50,
              width: 50,
              margin: 0,
            }}
          />
          &nbsp;{groupInfo?.name}
        </Typography>
      </Box>
      <List>
        {members?.map((member: any) => (
          <React.Fragment key={member?.id}>
            <ListItem disableGutters>
              <UserCard
                userName={member?.user?.nickname||member?.user?.name}
                role={dayjs(member?.created_at).format(
                  "dddd,MMMM D, YYYY"
                )}
                showApproveIcon={member.status === "pending"}
                onApprove={() => setMember(member)}
                onDelete={() => handleDelete(member.id)}
                showMoreOptionsIcon={false}
                onMoreOptions={() => handleMoreOptions(member.id)}
              />
            </ListItem>
            <Divider component="li" /> {/* Adds a divider between each user */}
          </React.Fragment>
        ))}
      </List>
      <LoadingModal visible={loading} handleClose={() => {}} />
      <ConfirmationModal
        open={member}
        message={"参加リクエストを承認しますか?"}
        onClose={() => setMember(undefined)}
        onContinue={() => {
          handleApprove(member);
          setMember(undefined);
        }}
      />
      <CancelConfirmationModal
      open={open}
      message={'このユーザーのグループ参加を拒否します。よろしいですか?'}
      onClose={handleClose}
      onContinue={()=>{handleClose(); handleDeleteMember(member);}}
      yesText={'はい'}
      noText={'戻る'}
      />
      <MessageModal open={message.length>0} message={message}  onClose={()=>{setMessage('')}}/>
      
    </div>
  );
}

export default GroupMembers;
