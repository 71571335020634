import React, { useLayoutEffect } from "react";
import "./App.css";
import { checkLoggedIn } from "./routing";
import { HashRouter as Router, Routes, Route } from "react-router-dom"; // Change BrowserRouter to HashRouter
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import IntroPage from "./pages/IntroPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { colors } from "./common/colors";
import MyFarm from "./pages/MyFarm";
import DashboardPage from "./pages/DashboardPage";
import CreatePost from "./pages/CreatePost";
import Header from "./components/Header";
import Unauthorized from "./unauthorized";
import VotingSeeMorePage from "./pages/VotingSeeMorePage";
import Login from "./pages/loginUser";
import DetailsViewPage from "./pages/DetailsViewPage";
import AdminHome2 from "./pages/AdminHome";
import { AuthProvider } from "react-auth-verification-context";
import { Translator, Config } from "react-translator-component";
import { useSelector } from "react-redux";
import PostView from "./pages/PostView";
import { language_config } from "./common/constants/language_config";
import UserInfo from "./pages/UserInfo";
import { checkLoggedInAdmin } from "./routing_admin";
import CategoryListPage from "./pages/CategoryListPage";
import setAuthorizationToken from "./services/axios-util/set-authorization-token";
import SuggestionBox from "./pages/SuggestionBox";
import FacilitatorList from "./pages/FacilitatorList";
import PostHistory from "./pages/MyFarmsPages/PostHistory";
import NavigatorLogin from "./pages/AdminNavigator/NavigatorLogin";
import SearchScreen from "./pages/phase2/SearchScreen";
import EveryOnesPostHistory from "./pages/MyFarmsPages/EveryOnesPostHistory";
import CommentHistory from "./pages/MyFarmsPages/CommentHistory";
import CreateGroupStep1 from "./pages/GreenCity/CreateGroupStep1";
import CreateGroupStep2 from "./pages/GreenCity/CreateGroupStep2";
import GroupInfoReview from "./pages/GreenCity/GroupInfoReview";
import Disclosure from "./pages/GreenCity/Disclosure";
import CreateGroupStepPII from "./pages/GreenCity/CreateGroupStepPII";
import GroupOwnerSettingPage from "./pages/GreenCity/GroupOwnerSettingPage";
import GroupSearch from "./pages/GreenCity/GroupSearch";
import ViewGroupPost from "./pages/GreenCity/ViewGroupPost";
import CreateGroupPost from "./pages/GreenCity/CreateGroupPost";
import GroupEventSettingPage from "./pages/GreenCity/GroupEventSettingPage";
import CreateNewEvent from "./pages/GreenCity/CreateNewEvent";
import GroupMembers from "./pages/GreenCity/JoinProcess/GroupMembers";
import MemberList from "./pages/GreenCity/MemberList";
import ChatList from "./pages/GreenCity/chats/ChatList";
import EventDetails from "./pages/GreenCity/EventDetails";
import CalenderEvents from "./pages/GreenCity/CalenderEvents";
import UserChat from "./pages/GreenCity/chats/UserChat";
import GroupPayment from "./pages/GreenCity/JoinProcess/GroupPayment";
import EventApplicationPayment from "./pages/GreenCity/EventApplicationPayment";
import ChatMemberList from "./pages/GreenCity/chats/ChatMemberList";
import GroupEventsCalendar from "./pages/GreenCity/GroupEventsCalendar";
import UserEventPaymentDetails from "./components/Phase2/UserEventPaymentDetails";
import GroupChat from "./pages/GreenCity/GroupChat";
import GroupRoles from "./pages/GreenCity/JoinProcess/GroupRoles";
import CancelRequestsPage from "./pages/CancelRequestsPage";
import EventUsers from "./pages/GreenCity/EventUsers";
import InvitionLinkCreations from "./pages/GreenCity/JoinProcess/Invite/InvitionLinkCreations";
import GroupInvite from "./pages/GreenCity/JoinProcess/Invite/GroupInvite";
import EventPostDetails from "./pages/GreenCity/EventPostDetails";
import UserEventPaymentDetail from "./components/Phase2/UserEventPaymentDetail";
import EventCancelDetails from "./pages/EventCancelDetails";
import GroupMemberFee from "./pages/GreenCity/GroupMemberFee";
import UserChatList from "./pages/GreenCity/chats/UserChatList";
import ScanStoreQR from "./pages/GreenCity/PaytoStore/ScanStoreQR";
import GroupUserPayment from "./pages/GreenCity/GrouptoUserPay/GroupUserPayment";
import PaymentReimbursement from "./pages/GreenCity/payments/PaymentReimbursement";
import PaymentReimbursement2 from "./pages/GreenCity/payments/PaymentReimbursement2";
import ShowReimbursements from "./pages/GreenCity/payments/ShowReimbursements";
import ShowGroupReimbursements from "./pages/GreenCity/payments/ShowGroupReimbursements";
import PaymentHistory from "./pages/GreenCity/payments/PaymentHistory";
import GroupPaymentHistory from "./pages/GreenCity/payments/GroupPaymentHistory";
import CreateLeaderEventPost from "./pages/CreateLeaderEventPost";
import InviteMembers from "./pages/GreenCity/JoinProcess/Invite/InviteMembers";
import ViewPostUser from "./pages/GreenCity/ViewPostUser";
import AddPostUser from "./pages/GreenCity/AddPostUser";

// home page not in use new design////
const HomePage = checkLoggedIn(VotingSeeMorePage);
const AdminHome = checkLoggedInAdmin(AdminHome2);
const PostViewPage = checkLoggedInAdmin(PostView);
const CategoryPage = checkLoggedIn(CategoryListPage);
const FacilitatorPage = checkLoggedIn(FacilitatorList);
const SuggestionPage = checkLoggedIn(SuggestionBox);
const PostCreatePage = checkLoggedIn(CreatePost);
const Dashboard = checkLoggedIn(DashboardPage);
const MyFarmPage = checkLoggedIn(MyFarm);
const VotingSeeMore = checkLoggedIn(VotingSeeMorePage);
const DetailsView = checkLoggedIn(DetailsViewPage);
const PostHistoryScreen = checkLoggedIn(PostHistory);
const EveryOnesPostHistoryScreen = checkLoggedIn(EveryOnesPostHistory);
const CommentHistoryScreen = checkLoggedIn(CommentHistory);
const Search = checkLoggedIn(SearchScreen);
const CreateGroupStep1Screen = checkLoggedIn(CreateGroupStep1);
const CreateGroupStep2Screen = checkLoggedIn(CreateGroupStep2);
const GroupInfoReviewScreen = checkLoggedIn(GroupInfoReview);
const CreateLeaderEventPostScreen = checkLoggedIn(CreateLeaderEventPost);
const DisclosureScreen = checkLoggedIn(Disclosure);
const CreateGroupStepPIIScreen = checkLoggedIn(CreateGroupStepPII);
const GroupOwnerSettingScreen = checkLoggedIn(GroupOwnerSettingPage);
const GroupSearchScreen = checkLoggedIn(GroupSearch);
const ViewGroupPostScreen = checkLoggedIn(ViewGroupPost);
const CreateGroupPostScreen = checkLoggedIn(CreateGroupPost);
const GroupEventSettingScreen = checkLoggedIn(GroupEventSettingPage);
const CreateNewEventScreen = checkLoggedIn(CreateNewEvent);
const MemberListScreen = checkLoggedIn(MemberList);
const GroupMembersScreen = checkLoggedIn(GroupMembers);
const ChatListScreen = checkLoggedIn(ChatList);
const EventDetailsScreen = checkLoggedIn(EventDetails);
const CalenderEventsScreen = checkLoggedIn(CalenderEvents);
const UserChatScreen = checkLoggedIn(UserChat);
const GroupMemberFeeScreen = checkLoggedIn(GroupMemberFee);

const EventApplicationPaymentScreen = checkLoggedIn(EventApplicationPayment);
const GroupPaymentScreen = checkLoggedIn(GroupPayment);
const GroupEventsCalendarScreen = checkLoggedIn(GroupEventsCalendar);
const UserEventPaymentDetailsScreen = checkLoggedIn(UserEventPaymentDetails);
const EventUsersScreen = checkLoggedIn(EventUsers);
const CancelRequestsPageScreen = checkLoggedIn(CancelRequestsPage);
const GroupInviteScreen = checkLoggedIn(GroupInvite);
const GroupRolesScreen = checkLoggedIn(GroupRoles);
const InvitionLinkCreationScreen = checkLoggedIn(InvitionLinkCreations);
const UserChatListScreen = checkLoggedIn(UserChatList);
const ScanStoreQRScreen = checkLoggedIn(ScanStoreQR);
const GroupUserPaymentScreen = checkLoggedIn(GroupUserPayment);

const PaymentReimbursementScreen = checkLoggedIn(PaymentReimbursement);
const PaymentReimbursement2Screen = checkLoggedIn(PaymentReimbursement2);
const EventPostDetailsScreen = checkLoggedIn(EventPostDetails);
const UserEventPaymentDetailScreen = checkLoggedIn(UserEventPaymentDetail);
const EventCancelDetailsScreen = checkLoggedIn(EventCancelDetails);
const ShowReimbursementsScreen = checkLoggedIn(ShowReimbursements);
const ShowGroupReimbursementsScreen = checkLoggedIn(ShowGroupReimbursements);
const PaymentHistoryScreen = checkLoggedIn(PaymentHistory);
const GroupPaymentHistoryScreen = checkLoggedIn(GroupPaymentHistory);
const InviteMembersScreen = checkLoggedIn(InviteMembers);
const ViewPostUserScreen = checkLoggedIn(ViewPostUser);
const AddPostUserScreen = checkLoggedIn(AddPostUser);


function App() {
  Config.default = "ja";
  Config.list = language_config;

  const { user } = useSelector((state: any) => state.userAuth);

  useLayoutEffect(() => {
    // Your layout effect logic here
  }, []);

  useLayoutEffect(() => {
    if (user?.id) {
      setAuthorizationToken(user.auth_token);
    }
  }, [user]);

  const theme = createTheme({
    typography: {
      fontFamily: "mplus-1c-regular",
    },
    palette: {
      primary: {
        main: colors.farm_green500,
        contrastText: "white",
      },
      secondary: {
        main: colors.farm_blue200,
        light: colors.farm_blue200,
        contrastText: "white",
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            userSelect: "text",
          },
        },
      },
    },
  });

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Translator>
          <div className="App">
            <Router>
              <header>
                <Header />
              </header>
              <Routes>
                <Route path="/postview" element={<PostViewPage />} />
                <Route path="/adminhome" element={<AdminHome />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<IntroPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/user" element={<UserInfo />} />
                <Route path="/categories" element={<CategoryPage />} />
                <Route path="/detailsview" element={<DetailsView />} />
                <Route path="/facilitator" element={<FacilitatorPage />} />
                <Route path="/suggestion" element={<SuggestionPage />} />
                <Route path="/post" element={<PostCreatePage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/myfarm" element={<MyFarmPage />} />
                <Route path="/search" element={<Search />} />
                <Route path="/posthistory" element={<PostHistoryScreen />} />
                <Route path="/votingseemore" element={<VotingSeeMore />} />
                <Route path="/navigator" element={<NavigatorLogin />} />
                <Route path="/chatmembers" element={<ChatMemberList />} />
                <Route path="/groupchat" element={<GroupChat />} />

                <Route path="/everyoneshistory" element={<EveryOnesPostHistoryScreen />} />
                <Route path="/commenthistory" element={<CommentHistoryScreen />} />
                <Route path="/creategroupstep1" element={<CreateGroupStep1Screen />} />
                <Route path="/creategroupsteptwo" element={<CreateGroupStep2Screen />} />
                <Route path="/groupinforeview" element={<GroupInfoReviewScreen />} />

                <Route path="/group_search" element={<GroupSearchScreen />} />
                <Route path="/disclosure" element={<DisclosureScreen />} />
                <Route path="/steppii" element={<CreateGroupStepPIIScreen />} />
                <Route path="/groupleader" element={<GroupOwnerSettingScreen />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/viewGroupPost" element={<ViewGroupPostScreen />} />
                <Route path="/createGroupPost" element={<CreateGroupPostScreen />} />
                <Route path="/groupeventsetting" element={<GroupEventSettingScreen />} />
                <Route path="/createnewevent" element={<CreateNewEventScreen />} />
                <Route path="/viewGroupPost" element={<ViewGroupPostScreen />} />
                <Route path="/createGroupPost" element={<CreateGroupPostScreen />} />
                <Route path="/userchat" element={<UserChatScreen />} />
                <Route path="/members" element={<GroupMembersScreen />} />
                <Route path="/memberlist" element={<MemberListScreen />} />
                <Route path="/chatlist" element={<ChatListScreen />} />
                <Route path="/userchatlist" element={<UserChatListScreen />} />
                <Route path="/groupchat" element={<GroupChat />} />
                <Route path="/eventdetail" element={<EventDetailsScreen />} />
                <Route path="/calenderevent" element={<CalenderEventsScreen />} />
                <Route path="/createleadereventpost" element={<CreateLeaderEventPostScreen />} />

                <Route path="/grouppayment" element={<GroupPaymentScreen />} />
                <Route path="/eventapplicationpayment" element={<EventApplicationPaymentScreen />} />
                <Route path="/groupeventscalendar" element={<GroupEventsCalendarScreen />} />
                <Route path="/usereventpaymentdetailscreen" element={<UserEventPaymentDetailsScreen />} />

                <Route path="/grouproles" element={<GroupRolesScreen />} />
                <Route path="/cancelrequests" element={<CancelRequestsPageScreen />} />
                <Route path="/invitation" element={<InvitionLinkCreationScreen />} />
                <Route path="/groupinvitation" element={<GroupInviteScreen />} />

                <Route path="/eventusers" element={<EventUsersScreen />} />

                <Route path="/groupmembersfee" element={<GroupMemberFeeScreen />} />
                <Route path="/paytostoreqr" element={<ScanStoreQRScreen />} />
                <Route path="/paytouser" element={<GroupUserPaymentScreen />} />

                <Route path="/paymentreimbursement" element={<PaymentReimbursementScreen />} />
                <Route path="/paymentreimbursement2" element={<PaymentReimbursement2Screen />} />
                <Route path="/reimbursements" element={<ShowReimbursementsScreen />} />
                <Route path="/groupreimbursements" element={<ShowGroupReimbursementsScreen />} />
                <Route path="/paymenthistory" element={<PaymentHistoryScreen />} />
                <Route path="/grouppaymenthistory" element={<GroupPaymentHistoryScreen />} />

                <Route path="/eventpostdetails" element={<EventPostDetailsScreen />} />
                <Route path="/usereventpaymentdetail" element={<UserEventPaymentDetailScreen />} />

                <Route path="/eventcanceldetails" element={<EventCancelDetailsScreen />} />
                <Route path="/invite" element={<InviteMembersScreen />} />
                <Route path="/viewpostuser" element={<ViewPostUserScreen />} />
                <Route path="/addpostuser" element={<AddPostUserScreen />} />


                <Route path="*" element={<NoPage />} />
              </Routes>
            </Router>
          </div>
        </Translator>
      </ThemeProvider>
    </AuthProvider>
  );
}

const NoPage = () => {
  return <h1>Page not found 404</h1>;
};

export default App;

declare global {
  interface Window {
    PortalAPP: { GetPersonalBasicInfo: any };
    UploadImage: any;
    getAccessToken: any;
    GetAccessToken: any;
    getPersonalBasicInfo: any;
    portalAppGetAccessTokenCallBack: any;
  }
}
