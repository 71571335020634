import { Avatar, Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { useLocation, useNavigate } from "react-router-dom";
import TextButton from "../../components/GreenCity/TextButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Separator from "../../components/Separator";
import { useSelector } from "react-redux";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import VerticalListP2 from "../../components/lists/VerticalListP2";
import { T } from "react-translator-component";

const AddIcon = () => {
  return (
    <div style={styles.addIcon}>
      <AddRoundedIcon sx={{ width: 20, height: 20 }} />
    </div>
  );
};

function ViewGroupPost(): ReactElement {
  const navigate = useNavigate();
  const state = useLocation().state;
  console.log("state: ", state);
  const groupInfo = useSelector((state: any) => state.gcGroup);
  // const { user } = useSelector((state: any) => state.userAuth);
  const [groupPost, setGroupPost] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  // eslint-disable-next-line
  const [pagination, setPagination] = useState<any>({});

  console.group(groupInfo);

  const getGroupPost = async (page: number) => {
    let res = await gcServices.getGroupPosts(groupInfo?.id, state?.post_type !== "public",state?.post_type === "private_users", page);
    setLoading(false);
    setLoadingMore(false);
    setGroupPost((pre: any[]) => [...pre, ...res.posts]);
    setPagination(res.pagination);
  };
  const getGroupPrivatePosts = async (page: number) => {
    let res = await gcServices.getGroupPosts(groupInfo?.id, state?.post_type !== "public",state?.post_type === "private_users", page);
    setLoading(false);
    setLoadingMore(false);
    setGroupPost((pre: any[]) => [...pre, ...res.posts]);
    setPagination(res.pagination);
  };

  const getUserPost = async (page: number) => {
    let res = await gcServices.getUserPosts(state?.user.id, page);
    setLoading(false);
    setLoadingMore(false);
    setGroupPost((pre: any[]) => [...pre, ...res.posts]);
    setPagination(res.pagination);
  };

  const loadMore = () => {
    if (pagination?.next_page > 0) {
      setLoadingMore(true);
      if (state.post_type === "user") {
        console.log('here>>>>')
        getUserPost(pagination.next_page);
      } else if(state.post_type === "private_users"){
        getGroupPrivatePosts(pagination.next_page);
      }
       else {
        getGroupPost(pagination.next_page);
      }
    }
  };

  const getTitle = () => {
    let title = "";
    if (state.post_type === "public") {
      title = "みんなの投稿";
    } else if (state.post_type === "private") {
      title = "グループ内の投稿";
    } else if (state.post_type === "private_users") {
      title = "グループ内のプライベート投稿";
    } else if (state.post_type === "user") {
      title = state.user.nickname + " の投稿";
    }

    return title;
  };

  useEffect(() => {
    if (state.post_type === "user") {
      getUserPost(1);
    }else if(state.post_type === "private_users"){
      getGroupPrivatePosts(1);
    } else {
      getGroupPost(1);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div style={styles.container}>
      <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />

      <Box component="div" style={styles.topButtonContainer}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {state?.post_type === "user" && <Avatar src={"data:image/jpeg;base64," + state?.user?.image} sx={{ height: 26, width: 26, marginRight: "10px" }} />}
          <Typography style={styles.everyonePostStyle}>{getTitle()}</Typography>
        </div>
        {(groupInfo?.role==='leader' || groupInfo?.role==='sub-leader') && state?.post_type !== "user" && (
          <TextButton onClick={() => navigate("/createGroupPost", { state: { post_type: state?.post_type } })} Icon={AddIcon} title="投稿する" />
        )}
        
      </Box>
      <Separator sx={{ marginTop: "10px" }} color="gray" />
      {loading ? (
        <Box sx={{ marginTop: "100px", alignItems: "center", justifyContent: "center", display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <VerticalListP2
          page="group"
          showUser={state.post_type !== "public"}
          items={groupPost}
          order={""}
          handleReport={function (post_id: number, status: any): void {
            throw new Error("Function not implemented.");
          }}
          onLike={function (post_id: number): void {
            throw new Error("Function not implemented.");
          }}
          onUnLike={function (post_id: number): void {
            throw new Error("Function not implemented.");
          }}
          likeLoading={false}
        />
      )}
      {pagination?.next_page > 0 && (
        <Stack sx={{ width: "100%", justifyContent: "center" }} spacing={2}>
          {loadingMore ? (
            <Box sx={{ alignItems: "center", justifyContent: "center", display: "flex", marginTop: "10px" }}>
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Button onClick={loadMore} size="small">
              {T("Load_More")}
            </Button>
          )}
        </Stack>
      )}
    </div>
  );
}

export default ViewGroupPost;

const styles = {
  container: {
    paddingTop: 90,
  },
  addIcon: {
    border: "2px solid",
    borderRadius: 5,
    borderColor: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: "0 10px",
  },

  everyonePostStyle: {
    fontSize: 16,
  },
};
