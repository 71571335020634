import { Box, Button, Checkbox, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import GCBackButton from '../../../components/GreenCity/GCBackButton'
import { styles } from '../../styles';
import profile from "../../../assets/images/profile_img.png";
import Separator from '../../../components/Separator';
import { colors } from '../../../common/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '../../../components/Models/GreenCity/ConfirmationModal';
import gcServices from '../../../services/app-services/greenCity/gcServicesR6';
import LoadingModal from '../../../components/LoadingModal';
import MessageModal from '../../../components/Models/GreenCity/MessageModal';
import SeclectUser from './SeclectUser';

// interface Props {
  
// }

function GroupUserPayment(){
    const groupInfo = useSelector((state: any) => state.gcGroup);
    const navigate=useNavigate();
    const { user } = useSelector((state: any) => state.userAuth);
    const [agree1,setAgree1]=useState(false);
    const [agree2,setAgree2]=useState(false);
    const [agree3,setAgree3]=useState(false);
    const [feeType,setFeeType]=useState('other_fee');
    const [amount,setAmount]=useState<any>()
    const [paymentDescription,setPaymentDescription]=useState('')
    const [groupError,setGroupError]=useState('')
    const [amountError,setAmountError]=useState('')
    const [loading,setLoading]=useState(false)
    const [openModal,setModal]=useState(false);
    const [showGroups,setShowGroups]=useState(false);
    const [showEvents,setShowEvents]=useState(false);
    const [message,setMessage]=useState('');
    const [accountInfo, setAccountInfo]=useState<any>();


    const [selectedUser,setSelectedUser]=useState<any>();
    useEffect(() => {
      window.scrollTo(0, 0);
    
      // eslint-disable-next-line
    }, []);


    const getGroupBalance=async ()=>{
      try {
        const res = await gcServices.getGroupBalance(groupInfo?.id);
        console.log(res?.wallet_information?.balance?.money?.amount);
       setAccountInfo(res?.wallet_information?.balance?.money?.amount);
      } catch (error) {
        console.error(error);
      }
    }

    useEffect(()=>{
      getGroupBalance();
    },[groupInfo])
    
    const handleSubmit=async ()=>{
      if(!selectedUser?.id){
        setGroupError('user required to pay.')
        return
      }

      if(amount===0||amount===undefined){
        setAmountError('amount required to pay.');
        return;
      }

        // let body={
        //   "member_group_id": selectedGroup?.id,
        //   "payment_type": feeType,
        //   "payment_details": paymentDescription,
        //   "from_user_id": user?.id,
        //   "amount": amount
        // }

        // let body1={
        //   "payer_type": "MemberGroup",
        //   "payer_id": groupInfo?.id,
        //   "payee_type": "User",
        //   "payee_id": selectedUser?.id,
        //   "payment_type": feeType,
        //   "payment_details": paymentDescription,
        //   "amount":amount

        // }
        let payment_type=feeType==='monthly'||feeType==='yearly'?'joining_fee':feeType;
        let billing_cycle=feeType!=='monthly'&& feeType!=='yearly'?feeType:'one_time';
        let body={
          "payer_type": "MemberGroup",
          "payer_id": groupInfo?.id,
          "payee_type": "User",
          "payee_id": selectedUser?.id,
          "payment_type": payment_type,
          "billing_cycle": billing_cycle,
          "amount": amount
        }

        console.log(body)
        try {
          setLoading(true);
          let res = await gcServices.payToGroup(body);
          if(res?.success){
          // setMessage('その他考えられそうな文言 <br/>・残高不足のため送金できませんでした。めぶくPayでチャージ後あたらめてお申し込みください。<br/>・エラー <br/>・お申し込み済みです。')
          
             setMessage('送金しました。');
            setTimeout(()=>{
              navigate(-1)
            },3000)
          }else{
            setMessage('その他考えられそうな文言 <br/>・残高不足のため送金できませんでした。めぶくPayでチャージ後あたらめてお申し込みください。<br/>・エラー <br/>・お申し込み済みです。')
          }
         
         } catch (error) {
          setMessage('その他考えられそうな文言 <br/>・残高不足のため送金できませんでした。めぶくPayでチャージ後あたらめてお申し込みください。<br/>・エラー <br/>・お申し込み済みです。')
          
         }finally{
          setLoading(false)
         }


    };

    const handleChange=(e:any)=>{
      console.log(e?.target.value) ;
      setFeeType(e?.target.value);
 }
    

  return (
    <div style={style.container}>
       <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
       <LoadingModal visible={loading} />
       <MessageModal onContinue={()=>{}} message={message} open={message.length>0} onClose={()=>{ setMessage('')}} />
       <ConfirmationModal open={openModal} message={
       <><Typography style={{textAlign:'center'}}>本グループの参加には入会金が必要なため、めぶくPayでの入会金の支払い手続きをお願いいたします。</Typography>
       <Typography style={{textAlign:'center'}}> 支払い完了後、グループコンテンツの閲覧が可能になります。'</Typography> </>} showButton onContinue={()=>{ setModal(false)}} onClose={()=>{ setModal(false)}} />
    <Box style={{ padding: 16 }}>
        <Typography style={{ fontWeight: "700" }}>
          <img
           src={
            user.image !== null
              ? "data:image/jpeg;base64," + user.image
              : profile
          }
            alt={"user_img"}
            style={{
              ...styles.farmProfileIcon,
              height: 50,
              width: 50,
              margin: 0,
            }}
          />
          &nbsp;  {user?.name||user?.nickname}
        </Typography> 
      </Box>
      <div style={{backgroundColor:colors.farm_gray15}}>
      <Separator sx={{ marginTop: "10px",marginBottom: "10px" }} color="gray" />
      <Box  style={{ padding: 16 }}>
      <Typography>お支払い先</Typography>
      <Separator sx={{ marginTop: "2px" }} color={colors.farm_green200} />
{!showGroups&&selectedUser?.id&&
      <Box style={style.marginTB}>
        <Typography style={{ fontWeight: "700" }}>
          <img
            src={ selectedUser.image !== null
              ? "data:image/jpeg;base64," + selectedUser.image
              : profile}
            alt={"img"}
            style={{
              ...styles.farmProfileIcon,
              height: 40,
              width: 40,
              margin: 0,
            }}
          />
          &nbsp;{selectedUser?.nickname||selectedUser?.name}
        </Typography>
      </Box>
      }
      
      {showGroups&&<SeclectUser groupId={groupInfo?.id} onNext={(user)=>{setSelectedUser(user); setShowGroups(false); setGroupError('')}}  />}
      {showEvents&&<SeclectUser eventId={groupInfo?.id} onNext={(user)=>{setSelectedUser(user); setShowEvents(false); setGroupError('')}}  />}
     <div style={{display:'flex',justifyContent:'center',padding:4}}>{!showGroups&&  <Button sx={{color:'gray',borderRadius:20,borderColor:colors.farm_blue200}}  onClick={()=>{setShowGroups(true);setShowEvents(false)}} variant="outlined">メンバーを選ぶ</Button>}</div> 
     <div style={{display:'flex',justifyContent:'center',padding:4}}> {!showEvents&&  <Button  sx={{color:'gray',borderRadius:20,borderColor:colors.farm_blue200}}  onClick={()=>{setShowEvents(true);setShowGroups(false)}} variant="outlined">メンバーを選ぶ</Button>}</div> 
     {(!showGroups||!showEvents)&&<Typography style={{color:'red'}}>{groupError}</Typography>}
      <br/>
      <Typography>種別</Typography>
      <Separator sx={{ marginTop: "2px" }} color={colors.farm_green200} />
      <RadioGroup
          aria-labelledby="payment"
          defaultValue={feeType}
          name="fee"
          onChange={handleChange}
        >
    <FormControlLabel value="event_fee" control={<Radio />} label="イベント費" />
    <FormControlLabel value="joining_fee" control={<Radio />} label="会費" />
   <FormControlLabel
      value="monthly"
      control={<Radio />}
      label={'月会費'}
    />
    <FormControlLabel
      value="yearly"
      control={<Radio />}
      label={ '年会費'}
    />
   <FormControlLabel value="other_fee" control={<Radio />} label="その他" />  
  </RadioGroup>
    
      

      <Typography>金額</Typography>
      <Separator sx={{ marginTop: "2px" }} color={colors.farm_green200} />
      <br/>
      <div style={{display:'flex', justifyContent:'center',alignItems:'baseline'}}>  
      <TextField placeholder='0' type='number' value={amount} onChange={(e)=>{setAmount(Number(e.target.value)); if(Number(e.target.value)>0){setAmountError('')}}}   sx={{ input: { textAlign: 'right' } }} id="outlined-basic"  hiddenLabel variant="outlined" />円</div>
      <Typography style={style.balance}>グループの「めぶくPay」残高：{accountInfo}円</Typography>
      {!showGroups&&<Typography style={{color:'red'}}>{amountError}</Typography>}
      <br/>
      <Typography>メモ</Typography>
      <Separator sx={{ marginTop: "2px" }} color={colors.farm_green200} />
      <br></br>
      <TextField value={paymentDescription} onChange={(e)=>{setPaymentDescription(e.target.value)}} multiline rows={2} hiddenLabel fullWidth variant="outlined" />
      <br/>
<br/>
      <div style={{ padding: "15px 10px" }}>
      <Typography>注意事項</Typography>
      <Separator sx={{ marginTop: "2px" }} color={colors.farm_green200} />
        <FormControlLabel
          control={<Checkbox checked={agree1} onChange={(e) => setAgree1(e.target.checked)} />}
          color="error"
          sx={{ color: false ? colors.farm_red600 : "black", marginTop: "30px" }}
          label={'お支払い後、即時めぶくPayより指定の金額が支払われます。'}
        />
          <FormControlLabel
          control={<Checkbox checked={agree2} onChange={(e) => setAgree2(e.target.checked)} />}
          color="error"
          sx={{ color: false ? colors.farm_red600 : "black", marginTop: "20px" }}
          label={'お支払い後の払い戻しはできません。'}
        />
          <FormControlLabel
          control={<Checkbox checked={agree3} onChange={(e) => setAgree3(e.target.checked)} />}
          color="error"
          sx={{ color: false ? colors.farm_red600 : "black", marginTop: "20px" }}
          label={'注意事項を確認しました。'}
        />
      </div>

      {/* Submit Button */}
      <Button
        disabled={!agree1 || !agree2 || !agree3}
        color="secondary"
        sx={style.submitButton}
        size={"large"}
        variant="outlined"
        onClick={handleSubmit}
        endIcon={<FontAwesomeIcon style={{ position: "absolute", top: 0, right: 0, margin: "10px" }} icon={faChevronRight} />}
      >
        送金する
      </Button>
      </Box>
      </div>
    </div>
  )
}

export default GroupUserPayment


const style = {
  container: {
    paddingTop: 90,
  },
  marginTB:{
    marginTop: "10px",
    marginBottom: "10px"
  },
  balance:{
    fontSize:12,
    color:colors.farm_gray200
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
    marginBottom:8
  },
  
};