import { colors } from "../../../common/colors";

const styles = {
  headerBox: {
    padding: "1px 16px",
    paddingTop: "90px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
  },
  avatar: {
    height: "30px",
    borderRadius: "50%",
    marginRight: "10px",
    objectFit: "cover",
    overFlow: "hidden",
  },
  addIcon: {
    color: "#4792E0",
    fontSize: "35px",
    cursor: "pointer",
    marginRight: "10px",
    marginLeft: "10px",
  },
  container: {
    padding: "20px",
  },
  titleSeprator: {
    borderBottomColor: colors.farm_green500,
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    fontSize: 14,
    fontFamily: "mplus-1c-regular",
  },
  rowMargin: {
    display: "flex",
    alignItems: "center",
    margin: "10px",
  },
  textInput: {
    width: "100%",
    backgroundColor: "white",
  },
  rowBaseLine: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    margin: "10px",
  },
  subtitle: {
    fontSize: 13,
    color: colors.farm_gray200,
    fontFamily: "mplus-1c-regular",
    marginBottom: "20px",
  },
  radioColor: {
    "&, &.Mui-checked": {
      color: colors.farm_blue200,
    },
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
    marginBottom: 8,
  },
  marginTB: {
    margin: "10px",
  },
  smallText: {
    fontSize: 14,
  },
  separator: {
    height: "1px",
    width: "100%",
    backgroundColor: colors.farm_gray100,
    marginTop: "15px",
    marginBottom: "15px",
  },
  separator2: {
    height: "1px",
    width: "100%",
    backgroundColor: "#E8E8E8",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  boxLeft: {
    backgroundColor: colors.farm_gray50,
    width: "90px",
    padding: "5px",
    fontSize: 13,
  },
  boxRight: {
    padding: "5px",
    fontSize: 13,
    flex: 1,
    backgroundColor: "white",
  },
  boxContainer: {
    border: "3px solid #d32f2f",
    flex: 1,
    borderRadius: 2,
    overflow: "hidden",
  },
  inputField: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "100%",
    backgroundColor: "white",
  },
  endIcon: {
    position: "absolute" as "absolute",
    top: 0,
    right: 0,
    margin: "10px",
  },
  uploadImageButton: {
    alignSelf: "center",
    margin: "10px auto",
    maxWidth: 200,
    display: "flex",
    borderRadius: 20,
    fontFamily: "mplus-1c-bold",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  image: {
    height: "200px",
    width: "200px",
  },
  error: {
    textAlign: "center",
  },
  dateContainer: {
    display: "flex",
    gap: 2,
    alignItems: "center",
    marginTop: "10px",
  },
};

export default styles;
