import React, { FunctionComponent, useState, useEffect, ReactElement } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Modal from "@mui/material/Modal";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
interface Props {
  images:any
  isModalOpen:boolean
  onClose():void
}

function ImageViewer({images,isModalOpen,onClose}: Props): ReactElement {
    const defaultImage = require("../../assets/images/default.png");
    // const [isModalOpen, setModalOpen] = useState(false);
    const [errorMap, setErrorMap] = useState<{ [key: number]: boolean }>({});
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    console.log(images)
  return (
    <div>
       <Modal
        open={isModalOpen}
        onClose={onClose}
        aria-labelledby="image-slider"
        aria-describedby="image-slider-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 9999,
          touchAction: "none", // Prevent default touch actions to ensure the TransformWrapper captures all gestures
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: "background.paper",
            borderRadius: "1px",
            overflow: "hidden",
            position: "relative",
            touchAction: "manipulation", // Manipulate touch events within this box
          }}
        >
          {/* Close button */}
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 10000,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Carousel
            responsive={{
              superLargeDesktop: {
                breakpoint: { max: 4000, min: 1024 },
                items: 1,
              },
              desktop: { breakpoint: { max: 1024, min: 768 }, items: 1 },
              tablet: { breakpoint: { max: 768, min: 464 }, items: 1 },
              mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
            }}
            swipeable={true}
            draggable={true}
            showDots={true}
            arrows={true}
            infinite={true}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {images.map((image: any, index: number) => (
              <div
                key={index}
                style={{
                  width: windowSize.width,
                  height: windowSize.height,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <TransformWrapper
                  minScale={1}
                  maxScale={5}
                  wheel={{ disabled: true }} // Disable wheel zoom
                  pinch={{ disabled: false }} // Enable pinch zoom
                  doubleClick={{ disabled: true }} // Disable double-click zoom
                  panning={{ disabled: false }} // Disable panning for more controlled zoom
                >
                  {({ zoomIn, zoomOut }) => (
                    <>
                      <TransformComponent>
                        <div
                          style={{
                            width: windowSize.width,
                            height: windowSize.height,
                            backgroundImage: `url(${
                              errorMap[index]
                                ? defaultImage
                                : `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${image.path}`
                            })`,
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundColor: "#f0f0f0", // Fallback color to ensure the div is visible
                            // touchAction: "none", // Prevent default touch actions on this element
                          }}
                        >
                          {/* Optional: add content inside the div to prevent collapse */}
                          <span style={{ visibility: "hidden" }}>Image</span>
                        </div>
                      </TransformComponent>
                      {/* Zoom In button */}
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          zoomIn();
                        }}
                        sx={{
                          position: "absolute",
                          bottom: 16,
                          left: 16,
                          zIndex: 10000,
                          backgroundColor: "white",
                        }}
                      >
                        <ZoomInIcon />
                      </IconButton>

                      {/* Zoom Out button */}
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          zoomOut();
                        }}
                        sx={{
                          position: "absolute",
                          bottom: 16,
                          left: 64,
                          zIndex: 10000,
                          backgroundColor: "white",
                        }}
                      >
                        <ZoomOutIcon />
                      </IconButton>
                    </>
                  )}
                </TransformWrapper>
              </div>
            ))}
          </Carousel>
        </Box>
      </Modal>
    </div>
  )
}

export default ImageViewer
