import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import ButtonPrimary from "../../ButtonPrimary";
import TextButton from "../../../components/GreenCity/TextButton";

// Define the props interface
interface SuccessModalProps {
  open: boolean;
  showButton?: boolean;
  message: any;
  onClose: () => void;
  onContinue: () => void;
  buttonTitle?: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  open,
  onClose,
  onContinue,
  showButton,
  message,
  buttonTitle = "次",
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="body1">{message}</Typography>
        <ButtonPrimary title={buttonTitle} onClick={onContinue} />
      </Box>
    </Modal>
  );
};

export default SuccessModal;
