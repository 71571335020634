import React, { useEffect, useState } from "react";
import { colors } from "../../common/colors";
import { Box, Button, Typography } from "@mui/material";
import Separator from "../Separator";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import appServices from "../../services/app-services/appServices";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  clearGroupInfo,
  setGroupInfo,
} from "../../redux/Reducers/gcGroupReducer";
import { useAppDispatch } from "../../redux/store";
interface Props {
  item: any;
}

const EventPostDetailSeeMore = ({ item }: Props) => {
  const detail = item?.post_events[0];
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: any) => state.userAuth);
  const groupInfo = useSelector((state: any) => state.gcGroup);

  const [userPaymentDetail, setUserPaymentDetail] = useState<any>({});
  console.log(groupInfo);

  const today = dayjs();
  const event = dayjs(detail?.end_date);
  const EventDatehasPassed = event.isBefore(today);
  console.log(EventDatehasPassed);

  // State to keep track of selected values

  const isMember = (): boolean => {
    if (!item?.member_group) return false;

    const { member_group_users, owner } = item.member_group;

    // Check if the user is a member
    const isUserMember = member_group_users?.some(
      (member: any) => member?.id === user?.id
    );

    // Check if the user is the owner
    const isUserOwner = owner?.id === user?.id;

    // Return true if the user is either a member or the owner
    return isUserMember || isUserOwner;
  };

  const getGroupInfo = (groupInfo: any) => {
    console.log(groupInfo, "ppp");

    return {
      id: groupInfo.id,
      groupName: groupInfo.name,
      groupIsPublic: groupInfo?.group_type,
      groupImage: groupInfo?.group_icon
        ? `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`
        : "",
      groupMainImage: groupInfo?.banner_image
        ? `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.banner_image}`
        : "", // From state
      groupDesc: groupInfo?.description, // From state
      groupAnnouncements: groupInfo?.member_group_activities, // From state
      groupWebURLs: groupInfo?.social_links, // You can replace this with the actual value
      groupApprovalRequired: true,
      piiRequired: groupInfo?.personal_information_needed ? "YES" : "NO",
      groupIsLimited: true,
      groupMaxMembers: groupInfo.participants_count,
      groupMemberShipFeeAmount: groupInfo?.joining_fee_amount,
      groupPaymentAutomatic: groupInfo?.payment_type,
      groupAutomaticPaymentDay: groupInfo?.membership_fee_deduction_date,
      isMonthly: groupInfo?.fee_type === "yearly", // Static value
      groupPaymentField: groupInfo?.fee_amount,
      groupPaymentDescription: groupInfo?.fee_explanation,
      user: groupInfo.user,
      owner: groupInfo.owner,
      memberGroupUsers: groupInfo?.member_group_users,
    };
  };

  const navigateToJoinGroup = (groupInfo: any) => {
    dispatch(clearGroupInfo());
    dispatch(setGroupInfo(getGroupInfo(groupInfo)));
    navigate("/groupinforeview");
  };

  const AddEventActivity = async () => {
    try {
      const body = {
        post_event_id: detail?.id,
        user_id: user?.id,
        status: "applied",
      };
      await appServices.addEventActivity(body);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentDetails = async () => {
    try {
      const res = await appServices.getEventPaymentDetails(
        detail?.id,
        user?.id
      );
      console.log(res);

      setUserPaymentDetail(res[0]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPaymentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTextWithLinks = (text: string) => {
    const urlRegex =
      /\b(?:https?:\/\/)?(?:www\.[^\s]+|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})[\S]*\b/g;

    const replacedText = text?.replace(urlRegex, (url) => {
      if (!/^(?:\d+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/i.test(url)) {
        if (!/^https?:\/\//i.test(url)) {
          url = "https://" + url;
        }
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      } else {
        return url;
      }
    });

    return replacedText?.replace(/\n/g, "<br>");
  };

  const getTruncatedDescription = (text: string, limit: number) => {
    let truncated = text?.slice(0, limit);
    let lastOpen = truncated?.lastIndexOf("<a ");
    let lastClose = truncated?.lastIndexOf("</a>");

    if (lastOpen > lastClose) {
      truncated = truncated?.slice(0, lastOpen) + "...";
    } else if (truncated?.length < text?.length) {
      truncated += "...";
    }

    return renderTextWithLinks(truncated);
  };

  const navigateToPaymentDetails = () => {
    navigate("/usereventpaymentdetailscreen", {
      state: {
        role: "member",
        userPaymentDetail: userPaymentDetail,
      },
    });
  };

  const renderDescription = () => {
    const truncatedDescription = getTruncatedDescription(
      detail?.description,
      80
    );
    const descriptionHtml = true
      ? renderTextWithLinks(detail?.description)
      : truncatedDescription;
    const formatEventDate = (start_date: any, end_date: any) => {
      const startDate = dayjs(start_date);
      const endDate = dayjs(end_date);

      if (startDate.isSame(endDate, "day")) {
        // If start and end dates are the same
        return startDate.format("YYYY年M月D日(ddd)");
      } else {
        // If start and end dates are different
        return `${startDate.format("YYYY年M月D日(ddd)")}~${endDate.format(
          "M月D日(ddd)"
        )}`;
      }
    };
    return (
      <div
        style={{
          fontSize: 14,
          marginLeft: 6,
          marginRight: 6,
          lineBreak: "anywhere",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: descriptionHtml,
          }}
          onClick={(event) => {
            const target = event.target as HTMLElement;
            if (target.tagName === "A") {
              event.preventDefault();
              const url = (target as HTMLAnchorElement).href;
              console.log("Link clicked:", url);
              // You can add additional logic here, such as opening the URL in a new tab/window
              //window.open(url, "_system");
              try {
                const messageData = {
                  url: url,
                  condition: true,
                };
                window.parent.postMessage(
                  { type: "url", data: messageData },
                  "*"
                );
              } catch (error) {
                console.error(error);
              }
            }
          }}
        />
        {/* {isExpanded && ( */}
        <Box sx={{ marginTop: 2, padding: "0 6px" }}>
          <Box sx={{ marginTop: 4 }}>
            <Typography sx={{ textAlign: "left" }}>
              【イベント会場】{" "}
            </Typography>
            <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
            <Typography sx={{ textAlign: "left" }}>
              {detail?.venue_name}
            </Typography>
            <Typography sx={{ textAlign: "left" }}>
              {detail?.postal_code}
            </Typography>
            <Typography sx={{ textAlign: "left" }}>
              {detail?.address}
            </Typography>
          </Box>

          <Box sx={{ marginTop: 4 }}>
            <Typography sx={{ textAlign: "left" }}>
              【WebサイトのURL】
            </Typography>
            <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
            <Link to={detail?.url}>{detail?.url}</Link>
          </Box>

          <Box sx={{ marginTop: 4 }}>
            <Typography sx={{ textAlign: "left" }}>
              【添付ファイル】{" "}
            </Typography>
            <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
            {item?.attachments?.map((item: any, index: number) => (
              <div key={index} style={style.filesBox}>
                <p
                  dangerouslySetInnerHTML={{
                    // __html: renderTextWithLinks(item?.path),
                    __html: `<a href="${
                      process.env.REACT_APP_AWS_PATH_BUCKET
                    }/images${
                      item?.path
                    }" target="_blank" rel="noopener noreferrer">${decodeURIComponent(
                      item?.path.replace(/^\/?\d+-/, "")
                    )}</a>`,
                  }}
                  onClick={(event) => {
                    const target = event.target as HTMLElement;
                    if (target.tagName === "A") {
                      event.preventDefault();
                      const url = (target as HTMLAnchorElement).href;
                      console.log("Link clicked:", url);
                      // You can add additional logic here, such as opening the URL in a new tab/window
                      //window.open(url, "_system");
                      try {
                        const messageData = {
                          url: url,
                          condition: true,
                        };
                        window.parent.postMessage(
                          { type: "url", data: messageData },
                          "*"
                        );
                      } catch (error) {}
                    }
                  }}
                >
                  {/* {item?.name?.startsWith("/")
                    ? item?.name.slice(1)
                    : item?.name} */}
                </p>
              </div>
            ))}
          </Box>

          <Box sx={{ marginTop: 4 }}>
            <Typography sx={{ textAlign: "left" }}>【募集期間】</Typography>
            <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
            <Typography sx={{ marginTop: 1 }}>
              {formatEventDate(
                detail?.application_start_date,
                detail?.application_end_date
              )}
              {/* {dayjs(detail?.application_start_date).format(
                "YYYY年M月D日(ddd)"
              )}
              ~{dayjs(detail?.application_end_date).format("M月D日(ddd)")} */}
            </Typography>
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <Typography sx={{ textAlign: "left" }}>【イベント項目】</Typography>
            <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
            {detail?.participant_settings?.map((item: any, index: any) => {
              return (
                <Box>
                  <Typography>項目 : {item?.name}</Typography>
                  <Typography>
                    参加募集人数 : {item?.number_of_participants}
                  </Typography>
                  {item?.free ? (
                    <Typography>無料 : Yes</Typography>
                  ) : (
                    <Typography>
                      参加料金: {Number(item?.fee_amount)}
                    </Typography>
                  )}
                  <Separator
                    color={colors.farm_green500}
                    sx={{ height: "2px" }}
                  />
                </Box>
              );
            })}
          </Box>

          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box>
              <Typography sx={{ textAlign: "left" }}>
                【その他の情報】
              </Typography>
              <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
              <Typography sx={{ marginTop: 1 }}>
                １つのめぶくIDで5人まで参加のお申し込みがか可能です。
              </Typography>
            </Box>
            {/* <Box>
              <Typography sx={{ marginTop: 1 }}>
                １つのめぶくIDで5人まで参加のお申し込みがか可能です。
              </Typography>
              <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
            </Box> */}

            <Box>
              <Typography sx={{ marginTop: 2 }}>
                【このイベントに参加できる人】
              </Typography>
              <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
              <Typography>{detail?.member_participation_rule}</Typography>
            </Box>
            <Box>
              <Typography sx={{ marginTop: 2 }}>
                【キャンセルについて】{" "}
              </Typography>
              <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
              <Typography>{detail?.application_cancellation_rule}</Typography>
            </Box>

            <Box>
              <Typography sx={{ marginTop: 2 }}>
                お支払い方法は「めぶくPay」です。
              </Typography>
              <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
            </Box>

            {item?.expiry_date && (
              <Box>
                <Typography sx={{ marginTop: 2 }}>
                  投票終了日 : {item?.expiry_date}
                </Typography>
                <Separator
                  color={colors.farm_green500}
                  sx={{ height: "2px" }}
                />
              </Box>
            )}
          </Box>
          {user?.id === item?.member_group?.owner?.id ? (
            <Button
              color="secondary"
              sx={style.submitButton}
              size={"large"}
              variant="outlined"
              onClick={() =>
                navigate("/createnewevent", { state: { event: item } })
              }
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    margin: "10px",
                  }}
                />
              }
            >
              編集/キャンセル
            </Button>
          ) : (
            <Box>
              {userPaymentDetail?.status === "applied" ||
              userPaymentDetail?.status === "Cancel-pending" ? (
                <Button
                  color="secondary"
                  sx={style.submitButton}
                  size={"large"}
                  variant="outlined"
                  onClick={navigateToPaymentDetails}
                  endIcon={
                    <ArrowForwardIosIcon
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        margin: "10px",
                      }}
                    />
                  }
                >
                  詳細を見る
                </Button>
              ) : detail?.participant_settings?.length === 0 &&
                !EventDatehasPassed ? (
                isMember() ? (
                  <Button
                    color="secondary"
                    sx={style.submitButton}
                    size={"large"}
                    variant="outlined"
                    onClick={AddEventActivity}
                    endIcon={
                      <ArrowForwardIosIcon
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          margin: "10px",
                        }}
                      />
                    }
                  >
                    申し込む
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    sx={style.submitButton}
                    size={"large"}
                    variant="outlined"
                    onClick={() => navigateToJoinGroup(item?.member_group)}
                    endIcon={
                      <ArrowForwardIosIcon
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          margin: "10px",
                        }}
                      />
                    }
                  >
                    グループに参加する
                  </Button>
                )
              ) : (
                <></>
              )}

              {!EventDatehasPassed && (
                <>
                  <Separator
                    color={colors.farm_gray15}
                    sx={{ height: "2px" }}
                  />
                  {userPaymentDetail?.status !== "applied" &&
                    userPaymentDetail?.status !== "Cancel-pending" &&
                    detail?.participant_settings?.length > 0 &&
                    (isMember() ? (
                      <Box>
                        <Button
                          color="secondary"
                          sx={style.submitButton}
                          size={"large"}
                          variant="outlined"
                          onClick={() =>
                            navigate("/usereventpaymentdetail", {
                              state: {
                                item: item,
                              },
                            })
                          }
                          endIcon={
                            <ArrowForwardIosIcon
                              sx={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                                margin: "10px",
                              }}
                            />
                          }
                        >
                          申し込む
                        </Button>
                      </Box>
                    ) : (
                      <Box>
                        <Button
                          color="secondary"
                          sx={style.submitButton}
                          size={"large"}
                          variant="outlined"
                          onClick={() =>
                            navigateToJoinGroup(item?.member_group)
                          }
                          endIcon={
                            <ArrowForwardIosIcon
                              sx={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                                margin: "10px",
                              }}
                            />
                          }
                        >
                          グループに参加する
                        </Button>
                      </Box>
                    ))}
                </>
              )}
            </Box>
          )}
        </Box>
        {/* )} */}
        {/* {detail?.description.length > 80 && ( */}
        {/* <span
          style={{
            fontSize: 12,
            color: colors.farm_gray200,
            cursor: "pointer",
            display: "block", // Make sure the text stays on a new line if desired
            marginTop: 8, // Some space between the text and the "See more/less"
          }}
          onClick={toggleDescription}
        >
          {isExpanded ? "See less" : "See more"}
        </span> */}
        {/* )} */}
      </div>
    );
  };

  return <div className="post">{renderDescription()}</div>;
};
const style = {
  optionButton: {
    border: "1px solid #4792E0",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px",
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
  filesBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "25px",
    marginTop: "10px",
    // borderBottom: "1px solid #86BB57",
    padding: "5px 0",
  },
};

export default EventPostDetailSeeMore;
