import { Box } from "@mui/material";
import React, { ReactElement, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import GroupSearchbar from "../../components/GreenCity/GroupSearchbar";
import IconButtonArrow from "../../components/GreenCity/IconButtonArrow";
import { setGroupInfo } from "../../redux/Reducers/gcGroupReducer";

function GroupSearch(): ReactElement {
  const [groups, setGroups] = useState<any>();

  const [reSearch, setReSearch] = useState(false);
  const navigate = useNavigate();
  const dispatch =useDispatch();
  const { user } = useSelector((state: any) => state.userAuth);

  // Memoize groups to avoid unnecessary re-renders if groups don't change
  const memoizedGroups = useMemo(() => groups, [groups]);

  // Memoize handleSearch to avoid re-creating the function on every render
  const handleSearch = useCallback((results: any) => {
    setGroups(results);
    setReSearch(false);
  }, []);

  // const handleGroup = (group: any) => {
  //   if (group?.owner?.id === user?.id) {
  //     navigate("/groupleader", {
  //       state: {
  //         groupInfo: { ...group, user: group?.owner, role: group?.role },
  //       },
  //     });
  //   } else if (group?.owner?.id !== user?.id) {
  //     if (group?.status === "unpaid_approved") {
  //       navigate("/grouppayment", { state: { groupInfo: group } });
  //     } else if (group?.status === "approved") {
  //       navigate("/groupleader", {
  //         state: {
  //           groupInfo: { ...group, user: group?.owner, role: group?.role },
  //         },
  //       });
  //     } else {
  //       handleReview(group);
  //     }
  //   }
  // };

  const handleGroup = (group: any) => {
    if (group?.owner?.id === user?.id) {
      navigate("/groupleader", {
        state: {
          groupInfo: { ...group, user: group?.owner, role: group?.role },
        },
      });
    } else if (group?.owner?.id !== user?.id) {
      if (group?.status === "unpaid_approved") {
        navigate("/grouppayment", { state: { groupInfo: group } });
      } else if (group?.status === "approved") {
        navigate("/groupleader", {
          state: {
            groupInfo: { ...group, user: group?.owner, role: group?.role },
          },
        });
      } else if(group?.status === "invited"){
        handleReview(group);
         
      } else {
        handleReview(group);
      }
    }
  };

  const handleReview = (groupInfo: any) => {
    // Dispatch action to save group details
    dispatch(
      setGroupInfo({
        user:groupInfo?.owner,
        id: groupInfo.id,
        groupName: groupInfo.name,
        groupIsPublic: groupInfo?.group_type,
        groupImage: groupInfo?.group_icon?`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`:'',
        groupMainImage:groupInfo?.banner_image? `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.banner_image}`:'', // From state
        groupDesc: groupInfo?.description, // From state
        groupAnnouncements: groupInfo?.member_group_activity_values_attributes, // From state
        groupWebURLs: groupInfo?.social_links, // You can replace this with the actual value
        groupApprovalRequired: groupInfo?.approval_required,
        piiRequired: groupInfo?.personal_information_needed ? "YES" : "NO",
        groupIsLimited: true,
        groupMaxMembers: groupInfo.participants_count,
        groupMemberShipFeeAmount: groupInfo?.joining_fee_amount,
        groupPaymentAutomatic: groupInfo?.payment_type,
        groupAutomaticPaymentDay: groupInfo?.membership_fee_deduction_date,
        isMonthly: groupInfo?.fee_type === "yearly", // Static value
        isPaid:groupInfo?.is_paid,
        groupPaymentField: groupInfo?.fee_amount,
        groupPaymentDescription: groupInfo?.fee_explanation,
        memberGroupUsers:groupInfo?.member_group_users
       
      })
    );
    navigate("/groupinforeview");
  };

  return (
    <div style={{ paddingTop: 100 }}>
         <GCBackButton title="back" onClick={() => navigate(-1)} />
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <GroupSearchbar focus reSearch={reSearch} onResults={handleSearch} />
      </div>
      <br />

      <Box display="flex" flexDirection="column">
        {memoizedGroups?.member_groups?.map((group: any, index: number) => (
          <IconButtonArrow
            key={index} // Use a unique key in a real app
            icon={
              <img
                alt=""
                height={26}
                width={26}
                style={{ borderRadius: 13 }}
                src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${group?.group_icon}`}
              />
            }
            title={group?.name || ""} // Title from the group
            role={group?.owner?.id===user?.id?'leader':group?.role}
            onClick={() => {
              handleGroup(group);
            }}
          />
        ))}
      </Box>
    </div>
  );
}

export default GroupSearch;
