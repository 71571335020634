import React, { useEffect, useState } from "react";
import { Avatar, Box, TextField, Typography, Checkbox, Button, Select, MenuItem } from "@mui/material";
import style from "./styles";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../../common/colors";
import ButtonOutlinedCentered from "../../../components/ButtonOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import ButtonPrimary from "../../../components/ButtonPrimary";
import SeclectGroup from "../../../components/GreenCity/SeclectGroup";
import { styles } from "../../styles";
import { isIOS } from "../../../util/utils";
import { AsyncImage } from "loadable-image";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import uploadFiles from "../../../util/upload-multiple-file";
import LoadingModal from "../../../components/LoadingModal";

const PaymentReimbursement = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.userAuth);
  const [props, setProps] = useState(useLocation().state);

  console.log("props: ", props);
  // States for inputs
  const [description, setDescription] = useState(""); // For the multiline text field
  const [checkbox1, setCheckbox1] = useState(false); // For the first checkbox
  const [checkbox2, setCheckbox2] = useState(false); // For the second checkbox
  const [groupError, setGroupError] = useState("");
  const [selectedGroup, setGroup] = useState<any>(useLocation().state?.selectedGroup || null);
  const [showGroups, setShowGroups] = useState(false);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [amount, setAmount] = useState("");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [paymentDestination, setPaymentDestination] = useState("");
  const [errors, setErrors] = useState({
    amount: "",
    payment_date: "",
    receipt_id: "",
    image: "",
    paymentDestination: "",
    details: "",
    message: "",
  });

  // Options for the dropdowns
  const years = Array.from({ length: 50 }, (_, i) => 2024 + i); // Years from 1970 to 2020
  const months = Array.from({ length: 12 }, (_, i) => i + 1); // Months 1 to 12
  const dates = Array.from({ length: 31 }, (_, i) => i + 1); // Dates 1 to 31

  const handleDescriptionChange = (event: any) => {
    setErrors((prev) => ({ ...prev, details: "" }));
    setDescription(event.target.value);
  };

  const handlePaymnetDestinationChange = (event: any) => {
    setErrors((prev) => ({ ...prev, paymentDestination: "" }));
    setPaymentDestination(event.target.value);
  };

  const handleCheckbox1Change = (event: any) => {
    setCheckbox1(event.target.checked);
  };

  const handleCheckbox2Change = (event: any) => {
    setCheckbox2(event.target.checked);
  };
  const handleImageUploadAndroid = () => {
    setErrors((prev) => ({ ...prev, image: "" }));
    try {
      window.parent.postMessage("image_upload", "*");
    } catch (error) {
      console.error("Image upload failed", error);
    }
  };

  const handleImageUploadIOS = (event: any) => {
    setErrors((prev) => ({ ...prev, image: "" }));
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);

      const newImages = selectedFiles.map((file: any) => {
        let reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (e: any) => {
            resolve({
              name: file.name,
              file: file,
              imageSrc: e.target.result,
              path: undefined,
              _destroy: "0",
            });
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(newImages).then((loadedImages) => {
        console.log("selectedFiles: ", loadedImages);
        setSelectedFile(loadedImages[0]);
        // setError(null); // Clear any previous error
      });
    }
  };
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    // Handle form submission here
    let selectedAmount = amount;
    let selectedDate = year + "-" + month + "-" + date;
    let paymentId = props?.selectedPayment?.id;

    if (!paymentId) {
      if (!amount) {
        setErrors((prev) => ({
          ...prev,
          amount: "Amount is required",
        }));
      }

      if (!year || !month || !date) {
        setErrors((prev) => ({
          ...prev,
          payment_date: "Date is required",
        }));
      }

      if (!paymentDestination) {
        setErrors((prev) => ({
          ...prev,
          paymentDestination: "Payment address is required",
        }));
      }

      if (!selectedFile) {
        setErrors((prev) => ({
          ...prev,
          image: "Image is required",
        }));
      }
    }

    if (!description) {
      setErrors((prev) => ({
        ...prev,
        details: "Details is required",
      }));
    }

    if (paymentId) {
      selectedAmount = props.selectedPayment.amount;
      selectedDate = props.selectedPayment.created_at.split("T")[0];
    } else if (selectedFile) {
      paymentId = "recipt_attached";
    }
    if (selectedAmount && selectedDate && selectedFile && paymentId) {
      try {
        setLoading(true);
        console.log(selectedFile);
        const allImages = selectedFile ? await uploadFiles.uploadMultipleImages([selectedFile]) : [];
        console.log(allImages);
        let body = {
          user_id: user?.id,
          member_group_id: selectedGroup?.id,
          amount: amount,
          payment_date: selectedDate,
          receipt_id: paymentId,
          image: allImages[0].path,
          payment_address: paymentDestination,
          details: description,
        };
        const res = await gcServices.paymentReimbursementsAdd(body);
        console.log(res)
        if(res.success){
          navigate('/reimbursements')
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    function handleMessage(event: any) {
      if (event.source === window.parent) {
        // Log the message sent from the parent window

        if (event?.data === "" || event?.data === null || event?.data === undefined) return;
        try {
          const base64Data = event?.data.split(",")[1];
          const byteCharacters = atob(base64Data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/jpeg" });
          const timestamp = new Date().toISOString().replace(/:/g, "-");
          const fileName = `any_${timestamp}.png`;

          // Create a File object from the Blob
          const file = new File([blob], fileName, { type: "image/jpeg" });
          console.log("selectedFile useEffect: ", file);
          setSelectedFile({
            name: fileName,
            file: file,
            imageSrc: event?.data,
            path: undefined,
            _destroy: "0",
          });
        } catch (error) {
          // alert("error" + error);
        }
      }
    }

    // Add an event listener to listen for messages from the parent window
    window.addEventListener("message", handleMessage);

    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Box sx={style.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={style.rowMargin}>
          <Avatar src={"data:image/jpeg;base64," + user.image} sx={{ ...style.avatar, width: "30px" }} />
          <Typography>立て替え精算</Typography>
        </Box>
      </Box>

      <Box sx={{ ...style.container, backgroundColor: colors.farm_gray15 }}>
        <Typography sx={style.titleSeprator}>お支払い先</Typography>

        {!showGroups && selectedGroup && (
          <Box style={style.marginTB}>
            <Typography style={{ fontWeight: "700" }}>
              <img
                src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${selectedGroup?.group_icon}`}
                alt={"img"}
                style={{
                  ...styles.farmProfileIcon,
                  height: 40,
                  width: 40,
                  margin: 0,
                }}
              />
              &nbsp;{selectedGroup?.name}
            </Typography>
          </Box>
        )}
        <Box>
          {showGroups && (
            <div style={{paddingBottom:20}}>
            <SeclectGroup
              onNext={(group) => {
                setGroup(group);
                setShowGroups(false);
                setGroupError("");
              }}
            />
            </div>
          )}
          {!showGroups && (
            <ButtonPrimary
              title="グループを選ぶ"
              onClick={() => {
                setShowGroups(true);
              }}
            />
          )}
          {!showGroups && <Typography style={{ color: "red" }}>{groupError}</Typography>}
        </Box>

        <Typography sx={style.titleSeprator}>立て替え内容：支払日、支払い先、金額</Typography>
        {!props?.selectedPayment ? (
          <Box>
            {/* <Box sx={{ marginTop: "10px" }}>
              <Typography>めぶくPayでお支払いしたものを立て替えた場合 </Typography>
              <Typography sx={style.subtitle}>利用履歴を選ぶだけで詳細が自動入力されます。</Typography>
            </Box>
            <ButtonOutlinedCentered
              disabled={selectedGroup ? false : true}
              onClick={() => navigate("/paymentreimbursement2", { state: { selectedGroup: selectedGroup } })}
              title="利用履歴を選ぶ"
            /> */}
            <Box sx={{ marginTop: "10px" }}>
              <Typography>現金、電子マネークレジットカードなどでお支払いしたものを立て替えた場合</Typography>
              {!selectedFile?.imageSrc ? (
                <Typography sx={style.subtitle}>レシートなど、お支払い情報が記載されている書類をアップロードし、金額、お支払い先、お支払日を入力してください。</Typography>
              ) : (
                <Box sx={style.imageContainer}>
                  <AsyncImage style={style.image} src={selectedFile?.imageSrc} alt="" />
                </Box>
              )}
            </Box>
            <Typography sx={style.error} color="error">
              {errors.image}
            </Typography>
            {isIOS(user?.client_id) ? (
              <Button color={errors.image ? "error" : "secondary"} sx={style.uploadImageButton} component="label" variant="outlined">
                書類をアップロードする
                <input accept="image/*" type="file" hidden multiple onChange={handleImageUploadIOS} />
              </Button>
            ) : (
              <Button color={errors.image ? "error" : "secondary"} sx={style.uploadImageButton} component="label" variant="outlined" onClick={handleImageUploadAndroid}>
                書類をアップロードする
              </Button>
            )}
            <Box
              sx={{
                ...style.container,
                backgroundColor: colors.farm_white,
                borderRadius: 2,
                marginBottom: "20px",
              }}
            >
              <Typography sx={style.smallText}>お支払日</Typography>
              {errors.payment_date && (!year || !month || !date) && (
                <Typography sx={style.smallText} color={"error"}>
                  {errors.payment_date}
                </Typography>
              )}
              <Box sx={style.dateContainer}>
                <Select error={!!errors.payment_date && !year} value={year} onChange={(e) => setYear(e.target.value)} displayEmpty sx={{ width: "33%", backgroundColor: "white" }}>
                  <MenuItem value="" disabled>
                    {year} 年
                  </MenuItem>
                  {years.map((yr) => (
                    <MenuItem key={yr} value={yr}>
                      {yr}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  error={!!errors.payment_date && !month}
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  displayEmpty
                  sx={{ width: "33%", backgroundColor: "white" }}
                >
                  <MenuItem value="" disabled>
                    {month} 月
                  </MenuItem>
                  {months.map((mn) => (
                    <MenuItem key={mn} value={mn}>
                      {mn}
                    </MenuItem>
                  ))}
                </Select>
                <Select error={!!errors.payment_date && !date} value={date} onChange={(e) => setDate(e.target.value)} displayEmpty sx={{ width: "33%", backgroundColor: "white" }}>
                  <MenuItem value="" disabled>
                    {date} 日
                  </MenuItem>
                  {dates.map((dt) => (
                    <MenuItem key={dt} value={dt}>
                      {dt}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box sx={style.separator} />
              <Typography sx={style.smallText}>お支払い先</Typography>

              <TextField
                error={!!errors.paymentDestination}
                helperText={errors.paymentDestination}
                sx={{
                  marginBottom: "10px",
                  width: "100%",
                  backgroundColor: "white",
                }}
                placeholder="〇〇スーパーマーケット"
                hiddenLabel
                value={paymentDestination}
                onChange={handlePaymnetDestinationChange}
                inputProps={{ maxLength: 500 }}
              />
              <Box sx={style.separator} />
              <Typography sx={style.smallText}>お支払い金額</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  className="amount-input"
                  value={amount}
                  error={!!errors.amount}
                  helperText={errors.amount}
                  multiline
                  style={{ textAlign: "right" }}
                  sx={{
                    marginBottom: "10px",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  placeholder="1,000"
                  hiddenLabel
                  type={"number"}
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setErrors((prev) => ({ ...prev, amount: "" }));
                  }}
                  inputProps={{ maxLength: 500 }}
                />
                <Typography sx={style.smallText}>円</Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box sx={{ marginTop: "10px", marginBottom: "10px", border: "1px solid #E8E8E8" }}>
              <Box sx={style.row}>
                <Typography sx={{ ...style.boxLeft, backgroundColor: colors.farm_gray50 }}>支払い先</Typography>
                <Typography sx={style.boxRight}>dummy value</Typography>
              </Box>
              <Box sx={style.separator2} />
              <Box sx={style.row}>
                <Typography sx={{ ...style.boxLeft, backgroundColor: colors.farm_gray50 }}>支払い方法</Typography>
                <Typography sx={style.boxRight}>{props?.selectedPayment.payment_type}</Typography>
              </Box>
              <Box sx={style.separator2} />
              <Box sx={style.row}>
                <Typography sx={{ ...style.boxLeft, backgroundColor: colors.farm_gray50 }}>支払い金額</Typography>
                <Typography sx={{ ...style.boxRight }}>{"¥ " + props?.selectedPayment.formatted_amount}</Typography>
              </Box>
              <Box sx={style.separator2} />
              <Box sx={style.row}>
                <Typography sx={{ ...style.boxLeft, backgroundColor: colors.farm_gray50 }}>取引ID</Typography>
                <Typography sx={style.boxRight}>{props?.selectedPayment.id}</Typography>
              </Box>
            </Box>
            <ButtonOutlinedCentered sx={{ padding: "0px 60px" }} onClick={() => setProps({ ...props, selectedPayment: null })} title="選び直す" />
          </Box>
        )}

        <Typography sx={style.titleSeprator}>メモ</Typography>
        <TextField
          error={!!errors.details}
          helperText={errors.details}
          required
          multiline
          sx={style.inputField}
          placeholder="例：X月X日にBBQをした時の食材代です。"
          hiddenLabel
          inputProps={{ maxLength: 500 }}
          value={description}
          rows={2}
          onChange={handleDescriptionChange}
        />
      </Box>
      <Box sx={style.container}>
        <Typography sx={style.titleSeprator}>注意事項</Typography>
        <Box sx={{ ...style.row, marginTop: "10px" }}>
          <Checkbox checked={checkbox1} onChange={handleCheckbox1Change} />
          <Typography>請求先と立て替え内容は合っています。</Typography>
        </Box>
        <Box sx={style.row}>
          <Checkbox checked={checkbox2} onChange={handleCheckbox2Change} />
          <Typography>料金の振込先はめぶくPayになります。</Typography>
        </Box>
      </Box>
      <Button
        disabled={!checkbox1 || !checkbox2 || !selectedGroup}
        color="secondary"
        sx={style.submitButton}
        size={"large"}
        variant="outlined"
        onClick={handleSubmit}
        endIcon={<FontAwesomeIcon style={style.endIcon} icon={faChevronRight} />}
      >
        立て替え申請を送る
      </Button>
      <LoadingModal visible={loading} />
    </Box>
  );
};

export default PaymentReimbursement;
