import { useEffect, useState } from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import GCBackButton from "../GreenCity/GCBackButton";
import { useSelector } from "react-redux";
import TableComponent from "../GreenCity/TableComponent";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import dayjs from "dayjs";
import LoadingModal from "../LoadingModal";
import SuccessModal from "../Models/GreenCity/SuccessModal";
import CancelConfirmationModal from "../Models/GreenCity/CancelConfirmation";
// import profile from "../../assets/images/profile_img.png";

const UserEventPaymentDetails = () => {
  // const { user } = useSelector((state: any) => state.userAuth);
  const groupInfo = useSelector((state: any) => state.gcGroup);
  console.log(groupInfo);

  const [detail, setDetail] = useState<any>([]);
  const [userDetail, setUserDetail] = useState<any>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { role, userPaymentDetail } = location.state || {};

  const [paymentDetails] = useState(
    JSON.parse(userPaymentDetail?.application_details)
  );

  console.log(paymentDetails, role);
  const [openModal, setModal] = useState(false);
  const [openModalSuccess, setModalSuccess] = useState(false);

  const getEventDetails = async () => {
    try {
      setLoading(true);
      const res = await gcServices.getSingleEvent(userPaymentDetail?.event_id);
      const response = await gcServices.getSingleUser(
        paymentDetails[0]?.user_id
      );
      setUserDetail(response?.user);
      setDetail(res?.post_event);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const cancel = async () => {
    try {
      setLoading(true);

      paymentDetails?.forEach((detail: any) => {
        detail.cancelledApplicationDate = dayjs(new Date()).format("YYYY-M-D");
      });

      let body = {};
      if (role === "member") {
        if (
          paymentDetails?.length > 0 &&
          detail?.application_cancellation_rule ===
            "1週間前まではキャンセルも返金も可能"
        ) {
          body = {
            id: userPaymentDetail?.id,
            status: "Cancel-pending",
            application_details: JSON.stringify(paymentDetails),
          };
          await gcServices.cancelEventApplication(body);
          setLoading(false);
          setModal(true);
          return;
        } else {
          await gcServices?.deleteEventMember(userPaymentDetail?.id);
          setLoading(false);
          setModal(true);
          return;
        }
      } else if (role === "leader") {
        await gcServices?.deleteEventMember(userPaymentDetail?.id);
        setLoading(false);
        setModal(true);
        return;
      }

      // navigate("/myfarm");
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getEventDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatEventDate = (start_date: any, end_date: any) => {
    const startDate = dayjs(start_date);
    const endDate = dayjs(end_date);

    if (startDate.isSame(endDate, "day")) {
      // If start and end dates are the same
      return startDate.format("YYYY年M月D日(ddd)");
    } else {
      // If start and end dates are different
      return `${startDate.format("YYYY年M月D日(ddd)")}~${endDate.format(
        "M月D日(ddd)"
      )}`;
    }
  };
  return (
    <Box>
      <LoadingModal visible={loading} handleClose={() => {}} />

      <div
        style={{
          paddingTop: 100,
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: "12px",
        }}
      >
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <CancelConfirmationModal
          open={openModal}
          message={"リクエストを受け入れますか?"}
          showButton
          onContinue={() => {
            setModal(false);
            setModalSuccess(true);
            cancel();
          }}
          onClose={() => {
            setModal(false);
          }}
        />
        <SuccessModal
          buttonTitle="近い"
          open={openModalSuccess}
          message={
            <>
              <Typography style={{ textAlign: "center" }}>
                イベント参加費の払い戻しは完了いたしました。
              </Typography>
            </>
          }
          showButton
          onContinue={() => {
            setModalSuccess(false);
            navigate("/myfarm");
          }}
          onClose={() => {
            setModalSuccess(false);
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Avatar
            sx={{ width: 30, height: 30 }}
            alt="Remy Sharp"
            src={groupInfo?.groupImage}
          />
          <Typography>{groupInfo?.groupName}</Typography>
        </Box>
        <Box component="div" style={styles.topButtonContainer}>
          <Box sx={{ display: "flex" }}>
            <CalendarMonthIcon
              style={{
                borderWidth: 1,
                width: 26,
                height: 26,
                padding: 2,
                margin: 2,
              }}
            />
            <Typography style={{ marginTop: "5px" }}>
              参加イベント詳細
            </Typography>
          </Box>
        </Box>

        <Box sx={{ marginTop: 4 }}>
          <TableComponent
            data={[
              {
                col1: "主催者",
                col2: (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Avatar
                      sx={{ width: 25, height: 25 }}
                      alt="Remy Sharp"
                      src={groupInfo?.groupImage}
                    />
                    <Typography>{groupInfo?.groupName}</Typography>
                  </Box>
                ),
              },
              {
                col1: "イベント名",
                col2: detail?.title,
              },
              {
                col1: "開催日",
                col2: (
                  <Box>
                    <Typography>
                      {formatEventDate(detail?.start_date, detail?.end_date)}
                    </Typography>
                  </Box>
                ),
              },
            ]}
          />
        </Box>
        <Box sx={{ marginTop: 4 }}>
          {paymentDetails?.length && (
            <>
              <Typography
                sx={{
                  borderBottom: "2px solid #86BB57",
                  marginTop: "10px",
                  textAlign: "left",
                  fontSize: "15px",
                }}
              >
                【参加項目】
              </Typography>
              {paymentDetails?.map((item: any, index: number) => (
                <Box
                  key={index}
                  sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  <TableComponent
                    data={[
                      {
                        col1: "項目",
                        col2: item?.name,
                      },
                      {
                        col1: "参加料",
                        col2: `1名: ${Number(item?.fee_amount)}円`,
                      },
                      {
                        col1: "申し込み人数",
                        col2: item?.number_of_participants,
                      },
                      {
                        col1: "合計料金",
                        col2: item?.total_amount,
                      },
                    ]}
                  />
                </Box>
              ))}
              <Box sx={{ paddingTop: "10px" }}>
                <TableComponent
                  data={[
                    {
                      col1: "お支払い金額",
                      col2: paymentDetails?.reduce(
                        (sum: number, item: any) => sum + item?.total_amount,
                        0
                      ),
                    },
                  ]}
                />
                <Typography
                  sx={{
                    borderBottom: "2px solid #86BB57",
                    paddingTop: "20px",
                    marginBottom: "10px",
                    textAlign: "left",
                    fontSize: "15px",
                  }}
                >
                  【お申し込み者】
                </Typography>
                <TableComponent
                  data={[
                    {
                      col1: "ニックネーム",
                      col2: userDetail?.nickname,
                    },
                  ]}
                />
                {role === "member" && (
                  <>
                    <Typography
                      sx={{
                        borderBottom: "2px solid #86BB57",
                        paddingTop: "20px",
                        marginBottom: "10px",
                        textAlign: "left",
                        fontSize: "15px",
                      }}
                    >
                      【人数を変更する】
                    </Typography>
                    <Typography>
                      大変申し訳ございませんが、人数を変更することはでき
                      ません。一度キャンセルし、再度お申し込みください。
                    </Typography>
                    <Typography
                      sx={{
                        borderBottom: "2px solid #86BB57",
                        paddingTop: "20px",
                        marginBottom: "10px",
                        textAlign: "left",
                        fontSize: "15px",
                      }}
                    >
                      【キャンセルについて】
                    </Typography>
                    <Typography>
                      下記ボタンからキャンセルを行えます。
                    </Typography>
                    <Typography>
                      キャンセル後はいかなる場合でもキャンセルの取り消し
                      は行えません。改めてお申し込みください。
                    </Typography>
                    <Typography
                      sx={{
                        borderBottom: "2px solid #86BB57",
                        paddingTop: "20px",
                        marginBottom: "10px",
                        textAlign: "left",
                        fontSize: "15px",
                      }}
                    >
                      【キャンセル料について】
                    </Typography>
                    <Typography>
                      {detail?.application_cancellation_rule}
                    </Typography>
                  </>
                )}
              </Box>
            </>
          )}
          {userPaymentDetail?.status === "Cancel-pending" ? (
            <Button
              color="secondary"
              sx={styles.submitButton}
              size={"large"}
              disabled={
                role === "member" &&
                userPaymentDetail?.status === "Cancel-pending"
              }
              onClick={cancel}
              variant="outlined"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    margin: "10px",
                  }}
                />
              }
            >
              {role !== "member"
                ? "Refund the participation fee"
                : "Cancel Pending"}
            </Button>
          ) : (
            <Button
              color="secondary"
              sx={styles.submitButton}
              onClick={cancel}
              size={"large"}
              variant="outlined"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    margin: "10px",
                  }}
                />
              }
            >
              キャンセルする
            </Button>
          )}
        </Box>
      </div>
    </Box>
  );
};

const styles = {
  eventPostingIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    padding: "3px 6px 3px 6px",
    color: "white",
  },

  topButtonContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginTop: 10,
  },

  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
};

export default UserEventPaymentDetails;
