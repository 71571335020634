import { Stack, Button, SxProps } from "@mui/material";
import { colors } from "../common/colors";

interface Props {
  title: string;
  onClick(): void;
  pLR?: number;
  sx?: SxProps;
  containersx?: SxProps;
  disabled?: boolean;
}

function ButtonOutlinedCentered({ title, onClick, pLR, disabled, sx, containersx }: Props) {
  return (
    <Stack sx={{ alignItems: "center", justifyContent: "center", ...containersx }}>
      <Button
        variant="outlined"
        disabled={disabled}
        onClick={onClick}
        sx={{
          paddingLeft: pLR || "",
          paddingRight: pLR || "",
          backgroundColor: "white",
          borderRadius: "36px",
          height: "36px",
          fontFamily: "mplus-1c-regular",
          borderColor: colors.farm_blue200, // Set outline color to blue
          color: colors.farm_black, // Set text color to black
          "&:hover": {
            borderColor: colors.farm_blue200, // Ensure outline color stays blue on hover
          },
          ...sx,
        }}
      >
        {title}
      </Button>
    </Stack>
  );
}

export default ButtonOutlinedCentered;
