import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";

export const getMembersGroups = createAsyncThunk(
  "membersGroups",
  async (ransank: string, thunkAPI: any) => {
    try {
      return await gcServices.getMembersGroups(ransank);
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const gcGroupReducer = createSlice({
  name: "gcGroup",
  initialState: {
    user: undefined,
    owner: undefined,
    id: "",
    edit: false,
    isLoading: false,
    isSuccess: false,
    member_groups: [],
    memberGroupUsers:[],
    groupName: "",
    groupImage: null, // Initially no image
    groupIsPublic: true,
    piiRequired: false,
    isPaid: false,
    groupMainImage: null, // Main image file from state
    groupDesc: "", // Description from state
    groupAnnouncements: [
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
    ],
    groupWebURLs: "", // You can replace this with the actual value
    groupApprovalRequired: false,
    groupIsLimited: false, // Represents whether group membership is limited
    groupMemberShipFeeAmount: 0, // Membership fee amount
    groupMaxMembers: "",
    groupPaymentAutomatic: false, // Payment method, whether it's automatic
    groupAutomaticPaymentDay: null, // The selected day for automatic payments
    isMonthly: true, // Static value for payment frequency
    groupPaymentField: "", // Additional payment field
    groupPaymentDescription: "", // Payment description
    role:""
  },

  reducers: {
    setGroupInfo: (state, action) => {
      // Update only fields that are provided in the payload
      const {
        user,
        owner,
        id,
        edit,
        groupName,
        groupImage,
        groupIsPublic,
        piiRequired,
        groupMainImage,
        groupDesc,
        groupAnnouncements,
        groupWebURLs,
        groupApprovalRequired,
        groupIsLimited,
        groupMaxMembers,
        memberGroupUsers,
        groupMemberShipFeeAmount,
        groupPaymentAutomatic,
        groupAutomaticPaymentDay,
        isMonthly,
        isPaid,
        groupPaymentField,
        role,
        groupPaymentDescription,
      } = action.payload;

      if (user !== undefined) state.user = user;
      if (owner !== undefined) state.owner = owner;

      if (isPaid !== undefined) state.isPaid = isPaid;
      if (role !== undefined) state.role = role;

      if (id !== undefined) state.id = id;
      if (edit !== undefined) state.edit = edit;
      
      if (memberGroupUsers !== undefined) state.memberGroupUsers = memberGroupUsers;
      if (groupName !== undefined) state.groupName = groupName;
      if (groupImage !== undefined) state.groupImage = groupImage;
      if (groupIsPublic !== undefined) state.groupIsPublic = groupIsPublic;
      if (piiRequired !== undefined) state.piiRequired = piiRequired;
      if (groupMainImage !== undefined) state.groupMainImage = groupMainImage;
      if (groupDesc !== undefined) state.groupDesc = groupDesc;
      if (groupAnnouncements !== undefined)
        state.groupAnnouncements = {
          ...state.groupAnnouncements,
          ...groupAnnouncements,
        };
      if (groupWebURLs !== undefined) state.groupWebURLs = groupWebURLs;
      if (groupApprovalRequired !== undefined)
        state.groupApprovalRequired = groupApprovalRequired;
      if (groupIsLimited !== undefined) state.groupIsLimited = groupIsLimited;
      if (groupMaxMembers !== undefined)
        state.groupMaxMembers = groupMaxMembers;
      if (groupMemberShipFeeAmount !== undefined)
        state.groupMemberShipFeeAmount = groupMemberShipFeeAmount;
      if (groupPaymentAutomatic !== undefined)
        state.groupPaymentAutomatic = groupPaymentAutomatic;
      if (groupAutomaticPaymentDay !== undefined)
        state.groupAutomaticPaymentDay = groupAutomaticPaymentDay;
      if (isMonthly !== undefined) state.isMonthly = isMonthly;
      if (groupPaymentField !== undefined)
        state.groupPaymentField = groupPaymentField;
      if (groupPaymentDescription !== undefined)
        state.groupPaymentDescription = groupPaymentDescription;
    },
    updateGroupAnnouncements: (state, action) => {
      // Update specific fields in groupAnnouncements
      state.groupAnnouncements = {
        ...state.groupAnnouncements,
        ...action.payload, // Only update the fields that are provided in the payload
      };
    },
    clearGroupInfo: (state) => {
      state.id = "";
      state.edit = false;
      state.groupName = "";
      state.groupImage = null;
      state.groupIsPublic = true;
      state.piiRequired = false;
      state.groupMaxMembers = "";
      state.groupMainImage = null;
      state.groupDesc = "";
      state.memberGroupUsers=[];
      state.groupAnnouncements = [
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
      ];
      state.groupWebURLs = "";
      state.groupApprovalRequired = false;
      state.groupIsLimited = false;
      state.groupMemberShipFeeAmount = 0;
      state.groupPaymentAutomatic = false;
      state.groupAutomaticPaymentDay = null;
      state.isMonthly = true;
      state.isPaid = false;
      state.groupPaymentField = "";
      state.groupPaymentDescription = "";
      state.role="";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMembersGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMembersGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.member_groups = action.payload;
      })
      .addCase(getMembersGroups.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { setGroupInfo, updateGroupAnnouncements, clearGroupInfo } =
  gcGroupReducer.actions;
export default gcGroupReducer.reducer;
