import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../common/colors";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ButtonOutlinedCentered from "../../components/ButtonOutlined";
import { useEffect, useState } from "react";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import ConfirmationModalV2 from "../../components/Models/ConfirmationModalV2";
import MessageModal from "../../components/Models/GreenCity/MessageModal";
import { useSelector } from "react-redux";

const ViewPostUser = () => {
  const navigate = useNavigate();
  const state = useLocation().state;
  const { user } = useSelector((state: any) => state.userAuth);
  const [postUser, setPostUsers] = useState<any>(state.item.post_private_users.filter((item: any) => item.user_id !== user.id));
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<number | null>(null);
  const [showMessage, setShowMessage] = useState("");

  const getImageSrc = () => {
    if (state.item?.is_anonymous === 3) {
      return "";
    } else if (state.item?.member_group) {
      return `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${state.item?.member_group?.group_icon}`;
    } else if (state.item?.user?.image) {
      return "data:image/jpeg;base64," + state.item?.user?.image;
    }
    return "";
  };

  const getName = () => {
    return state.item?.member_group ? state.item?.member_group?.name : state.item?.user?.nickname || state.item?.user?.name || " ";
  };

  const showButton = () => {
    if (state.item.user.id === user.id) {
      return true;
    }
  };

  const handleRemoveUser = (id: number | null) => {
    if (!id) return;
    setShowConfirmationModal(null);
    gcServices
      .RemoveUserInPost(id)
      .then((res) => {
        setShowMessage("ユーザーを削除しました");
        setPostUsers((prev: any) => prev.filter((item: any) => item.id !== id));
        console.log("res: ", res);
      })
      .catch((err) => {
        setShowMessage("ユーザーを削除できません");
      });
  };

  useEffect(() => {
    setLoading(true);
    gcServices
      .getPostUsers(state.item.id)
      .then((res) => {
        console.log("users xx: ", res);
        setPostUsers(res.post_private_users.filter((item: any) => item.user_id !== user.id));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={styles.rowCenter}>
          <Avatar sx={styles.image} src={getImageSrc()} />
          <p style={styles.nameText}>{getName()}</p>
          {loading && <CircularProgress size={25} />}
        </Box>
      </Box>
      <Box sx={{ padding: "16px", position: "relative" }}>
        <Typography style={styles.titleSeprator}>この投稿を見られるユーザー</Typography>
        {/* {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <CircularProgress size={25} />
            <Typography sx={{ marginLeft: "10px", color: colors.farm_gray200 }}>読み込み中</Typography>
          </Box>
        ) : ( */}
        <Box sx={{ paddingBottom: "100px" }}>
          {postUser.map((user: any, index: number) => (
            <Box key={user.id} sx={styles.itemContainer}>
              {/* <Typography sx={{ color: colors.farm_gray200 }}>{index + 1}</Typography> */}
              <Avatar sx={styles.userImage} src={user.image} />
              <p style={{ ...styles.nameText, fontWeight: "normal" }}>{user.nickname || user.user_nickname}</p>
              {showButton() && <RemoveCircleOutlineIcon onClick={() => setShowConfirmationModal(user.id)} sx={styles.removeIcon} />}
            </Box>
          ))}
        </Box>
        {/* )} */}
        {showButton() && (
          <Box sx={{ position: "fixed", bottom: "0px", left: "0px", right: "0px", backgroundColor: "white", padding: "10px" }}>
            <ButtonOutlinedCentered onClick={() => navigate("/addpostuser", { state: { item: state.item, users: postUser } })} title="Add Users" />
          </Box>
        )}
      </Box>
      <ConfirmationModalV2
        message="この投稿を見られるユーザーを削除しますか"
        onContinue={() => handleRemoveUser(showConfirmationModal)}
        open={showConfirmationModal !== null}
        onClose={() => setShowConfirmationModal(null)}
      />
      <MessageModal open={showMessage !== ""} message={showMessage} onClose={() => setShowMessage("")} />
    </Box>
  );
};

const styles = {
  container: {},
  headerBox: {
    padding: "1px 16px",
    paddingTop: "100px",
    borderBottom: "1px solid #ECEFF1",
  },
  image: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  userImage: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    objectFit: "cover",
    marginLeft: "10px",
  },
  nameText: {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: "10px",
    marginBottom: "0px",
    flex: 1,
  },
  itemContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: "16px",
    marginBottom: "16px",
    borderBottomStyle: "solid" as const,
    borderBottomColor: colors.farm_gray100,
    borderBottomWidth: 1,
    padding: "5px 0px",
  },
  rowCenter: {
    margin: "10px 0px",
    display: "flex",
    alignItems: "center",
  },
  titleSeprator: {
    borderBottomColor: colors.farm_green500,
    borderBottomWidth: 2,
    borderBottomStyle: "solid" as const,
    fontSize: 14,
    fontFamily: "mplus-1c-bold",
    marginBottom: 6,
  },
  removeIcon: {
    fontSize: "34px",
    cursor: "pointer",
    color: colors.farm_red600,
  },
  addButton: {},
};

export default ViewPostUser;
