import {
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { colors } from "../../common/colors";
import ButtonOutlinedCentered from "../../components/ButtonOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import CurrencyYenSharpIcon from "@mui/icons-material/CurrencyYenSharp";
import { styles } from "../styles";
import { useSelector } from "react-redux";
import SwitchComponent from "../../components/GreenCity/SwitchComponent";
import { useAppDispatch } from "../../redux/store";
import { setGroupInfo } from "../../redux/Reducers/gcGroupReducer";
import { isIOS } from "../../util/utils";
// import DayPicker from "../../components/GreenCity/DayPicker";

const defaultImage = require("../../assets/images/default.png");
function CreateGroupStep2(): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const { user } = useSelector((state: any) => state.userAuth);
  console.log(groupInfo);
  const [image, setImage] = useState<any>();
  const [mainImage, setMainImage] = useState<any>();
  const [mainImageFile, setMainImageFile] = useState<any>();
  const [desc, setDesc] = useState("");
  const [groupWebURLs, setGroupWebURLs] = useState("");
  const [approvalRequired, setApprovalRequired] = useState<any>("NO");
  const [isLimited, setIsLimited] = useState("No");
  const [memberShipFee, setMemberShipFee] = useState(false);
  const [memberShipFeeAmount, setMemberShipFeeAmount] = useState<any>();
  const [paymentAutomatic, setPaymentAutomatic] = useState("MANUAL");
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [paymentFrequency, setPaymentFrequency] = useState("MONTHLY");
  const [groupPaymentField, setGroupPaymentField] = useState("");
  const [paymentDesc, setPaymentDesc] = useState("");
  const [maxGroupMembers, setMaxGroupMembers] = useState("");
  // eslint-disable-next-line
  // const [selectedDay, setSelectedDay] = useState("");
  // eslint-disable-next-line
  // const handleDayChange = (day: any) => {
  //   setSelectedDay(day.target.value);
  // };
  const [announcements, setAnnouncements] = useState({
    anotherField1: "",
    anotherField2: "",
    anotherField3: "",
    anotherField4: "",
    anotherField5: "",
  });

  useEffect(() => {
    if (groupInfo) {
      if (groupInfo.groupImage) {
       
        const icon =
          groupInfo?.groupImage instanceof File
            ? URL.createObjectURL(groupInfo.groupImage)
            : `${groupInfo?.groupImage}` || defaultImage;

        setImage(icon); // Set the image URL
        
      }
      if (groupInfo.groupMainImage) {
        const groupMainImage =
        groupInfo?.groupMainImage instanceof File
          ? URL.createObjectURL(groupInfo.groupMainImage)
          : `${groupInfo?.groupMainImage}` || defaultImage;
          setMainImage(groupMainImage);
      }
      setDesc(groupInfo?.groupDesc || "");
      setGroupWebURLs(groupInfo?.groupWebURLs || "");
      setApprovalRequired(groupInfo?.groupApprovalRequired ? "YES" : "NO");
      setIsLimited(groupInfo?.groupIsLimited ? "Yes" : "No");
      if(groupInfo?.groupMemberShipFeeAmount)
      setMemberShipFeeAmount(groupInfo?.groupMemberShipFeeAmount);
      setMemberShipFee(groupInfo?.groupMemberShipFeeAmount ? true : false);

      try {
        setPaymentAutomatic(groupInfo?.groupPaymentAutomatic.toUpperCase());
      } catch (error) {
        setPaymentAutomatic(
          groupInfo?.groupPaymentAutomatic ? "AUTOMATIC" : "MANUAL"
        );
      }
      setIsPaid(!!groupInfo?.groupPaymentField);
      setPaymentFrequency(groupInfo?.isMonthly ? "MONTHLY" : "YEARLY");
      setGroupPaymentField(groupInfo?.groupPaymentField || "");
      setPaymentDesc(groupInfo?.groupPaymentDescription || "");
      setIsLimited(groupInfo?.groupMaxMembers ? "YES" : "NO");
      setMaxGroupMembers(groupInfo?.groupMaxMembers || "");
      const updatedData = {
        anotherField1: groupInfo.groupAnnouncements[0].value,
        anotherField2: groupInfo.groupAnnouncements[1].value,
        anotherField3: groupInfo.groupAnnouncements[2].value,
        anotherField4: groupInfo.groupAnnouncements[3].value,
        anotherField5: groupInfo.groupAnnouncements[4].value,
  };

  setAnnouncements(
    updatedData, // Override with new values
  );
    }
  }, [groupInfo]);

  const handleImageUploadAndroid = () => {
    try {
      window.parent.postMessage("image_upload", "*");
    } catch (error) {
      console.error("Image upload failed", error);
    }
  };
  useEffect(() => {
    function handleMessage(event: any) {
      if (event.source === window.parent) {
        // Log the message sent from the parent window

        if (event?.data === "" || event?.data === null || event?.data === undefined) return;
        try {
          const base64Data = event?.data.split(",")[1];
          const byteCharacters = atob(base64Data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/jpeg" });
          const timestamp = new Date().toISOString().replace(/:/g, "-");
          const fileName = `any_${timestamp}.png`;

          // Create a File object from the Blob
          const file = new File([blob], fileName, { type: "image/jpeg" });
 
          
          if (file) {
            setMainImageFile(file);
            const imageUrl = URL.createObjectURL(file); // Generate URL from the file
            setMainImage(imageUrl); // Set the image URL
          }
         
        } catch (error) {
          // alert("error" + error);
        }
      }
    }

    // Add an event listener to listen for messages from the parent window
    window.addEventListener("message", handleMessage);

    // eslint-disable-next-line
  }, []);



  // Handle change for each field
  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setAnnouncements((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    };
  const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentFrequency((event.target as HTMLInputElement).value);
  };
  const handlePPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //public private
    setApprovalRequired((event.target as HTMLInputElement).value);
  };
  // const handleLimitedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   //public private
  //   setIsLimited((event.target as HTMLInputElement).value);
  // };
  // eslint-disable-next-line
  const handlePaymentAutomaticChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //public private
    setPaymentAutomatic((event.target as HTMLInputElement).value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  
    // eslint-disable-next-line
  }, []);

  

  const handleSave = () => {
    // Dispatch action to save group details
    dispatch(
      setGroupInfo({
        groupMainImage: mainImageFile, // From state
        groupDesc: desc, // From state
        groupAnnouncements: [
          {value:announcements.anotherField1},
          {value:announcements.anotherField2},
          {value:announcements.anotherField3},
          {value:announcements.anotherField4},
          {value:announcements.anotherField5}
        ], // From state
        groupWebURLs: groupWebURLs, // You can replace this with the actual value
        groupApprovalRequired: approvalRequired === "YES",
        groupIsLimited: isLimited === "YES",
        groupMaxMembers: maxGroupMembers,
        groupMemberShipFeeAmount: memberShipFeeAmount,
        groupPaymentAutomatic: paymentAutomatic !== "MANUAL",
        groupAutomaticPaymentDay: '',
        isMonthly: paymentFrequency === "MONTHLY", // Static value
        groupPaymentField: groupPaymentField,
        groupPaymentDescription: paymentDesc,
      })
    );
    navigate("/groupinforeview");
  };

  const fileInputRef = useRef<any>();

  const handleButtonClick = () => {
    fileInputRef?.current.click();
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setMainImageFile(file);
      const imageUrl = URL.createObjectURL(file); // Generate URL from the file
      setMainImage(imageUrl); // Set the image URL
    }
  };

  // useEffect(() => {
  //   if (groupInfo.groupImage) {
  //     const imageUrl = URL.createObjectURL(groupInfo.groupImage); // Generate URL from the file
  //     setImage(imageUrl); // Set the image URL
  //   }
  // }, [groupInfo]);
  return (
    <div style={{ paddingTop: 100, height: "100vh" }}>
      <Box>
        <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 16,
            borderBottomColor: colors.farm_gray15,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <img
              alt=""
              style={{
                height: 32,
                width: 32,
                borderRadius: 18,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: colors.farm_green500,
              }}
              src={image || defaultImage}
            />
            <Typography style={{ fontSize: 14, fontWeight: 600 }}>
              &nbsp;{groupInfo.groupName}
            </Typography>
          </div>

          {/* <Typography>EDIT</Typography> */}
        </Box>
        <Box style={{}}>
          <Typography
            style={{
              borderBottomColor: colors.farm_green150,
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
              fontSize: 14,
              marginBottom: 6,
              marginTop: 16,
              marginLeft: 16,
              marginRight: 16,
            }}
          >
            メイン画像
          </Typography>
          <img
            alt=""
            style={{
              height: 220,
              width: "100%",
            }}
            src={mainImage || defaultImage}
          />
          <div>
             
             {isIOS(user?.client_id) ? (
              <>
           <ButtonOutlinedCentered
           title={"画像を選ぶ"}
           onClick={handleButtonClick}
           pLR={6}
         />
         <input
           type="file"
           accept="image/*"
           ref={fileInputRef}
           onChange={handleFileChange}
           style={{ display: "none" }} // Hide the file input
         />
         </>
        ) : (
          <ButtonOutlinedCentered
          title={"画像を選ぶ"}
          onClick={handleImageUploadAndroid}
          pLR={6}
        />
         
        )}
          </div>
          <Box style={{ padding: 16 }}>
            <Typography
              style={{
                borderBottomColor: colors.farm_green150,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                fontSize: 14,
                marginBottom: 6,
              }}
            >
              &nbsp;このグループの説明
            </Typography>
            <TextField
              // error={descError}
              value={desc}
              // helperText={descError ? "* fields are required.." : ""}
              sx={{ justifyContent: "start", width: "100%" }}
              hiddenLabel
              multiline
              rows={7}
              // inputProps={{ maxLength: MAX_LENGTH }}
              onChange={(e) => {
                setDesc(e.target.value);
                // if (desc.length > 0) {
                //   setDescError(false);
                // }
              }}
            />
            <Box>
              <Typography
                style={{
                  borderBottomColor: colors.farm_green150,
                  borderBottomWidth: 2,
                  borderBottomStyle: "solid",
                  fontSize: 14,
                  marginBottom: 6,
                  marginTop: 16,
                }}
              >
                <CampaignOutlinedIcon style={{ transform: "rotate(-30deg)" }} />
                &nbsp;グループの主な活動内容
              </Typography>
              <TextField
                fullWidth
                hiddenLabel
                id="outlined-basic"
                variant="outlined"
                value={announcements.anotherField1}
                onChange={handleChange("anotherField1")}
                InputProps={{
                  style: {
                    height: 40, // Set your desired height here
                    padding: "4px 8px", // Adjust padding to fit your design
                  },
                }}
                style={{ marginTop: 6 }} // Keep margin top if needed
              />
              <TextField
                fullWidth
                hiddenLabel
                id="outlined-basic"
                variant="outlined"
                value={announcements.anotherField2}
                onChange={handleChange("anotherField2")}
                InputProps={{
                  style: {
                    height: 40, // Set your desired height here
                    padding: "4px 8px", // Adjust padding to fit your design
                  },
                }}
                style={{ marginTop: 6 }} // Keep margin top if needed
              />

              <TextField
                fullWidth
                hiddenLabel
                id="outlined-basic"
                variant="outlined"
                value={announcements.anotherField3}
                onChange={handleChange("anotherField3")}
                InputProps={{
                  style: {
                    height: 40, // Set your desired height here
                    padding: "4px 8px", // Adjust padding to fit your design
                  },
                }}
                style={{ marginTop: 6 }} // Keep margin top if needed
              />
              <TextField
                fullWidth
                hiddenLabel
                id="outlined-basic"
                variant="outlined"
                value={announcements.anotherField4}
                onChange={handleChange("anotherField4")}
                InputProps={{
                  style: {
                    height: 40, // Set your desired height here
                    padding: "4px 8px", // Adjust padding to fit your design
                  },
                }}
                style={{ marginTop: 6 }} // Keep margin top if needed
              />
              <TextField
                fullWidth
                hiddenLabel
                id="outlined-basic"
                variant="outlined"
                value={announcements.anotherField5}
                onChange={handleChange("anotherField5")}
                InputProps={{
                  style: {
                    height: 40, // Set your desired height here
                    padding: "4px 8px", // Adjust padding to fit your design
                  },
                }}
                style={{ marginTop: 6 }} // Keep margin top if needed
              />
            </Box>
            <Typography
              style={{
                borderBottomColor: colors.farm_green150,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                fontSize: 14,
                marginBottom: 6,
                marginTop: 16,
              }}
            >
              WEBサイトなどのURL
            </Typography>
            <TextField
              // error={descError}
              value={groupWebURLs}
              // helperText={descError ? "* fields are required.." : ""}
              sx={{ justifyContent: "start", width: "100%" }}
              hiddenLabel
              multiline
              rows={7}
              // inputProps={{ maxLength: MAX_LENGTH }}
              onChange={(e) => {
                setGroupWebURLs(e.target.value);
                // if (desc.length > 0) {
                //   setDescError(false);
                // }
              }}
            />

            <Typography
              style={{
                borderBottomColor: colors.farm_green150,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                fontSize: 14,
                marginBottom: 6,
                marginTop: 16,
              }}
            >
              <PeopleAltOutlinedIcon />
              &nbsp;グループへの参加について
            </Typography>

            <>{groupInfo?.groupIsPublic? <RadioGroup
              aria-labelledby="payment-radio-buttons-group-label"
              value={approvalRequired}
              onChange={handlePPChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="NO"
                control={<Radio sx={{ alignSelf: "flex-start" }} />} // Aligns the radio button to the top
                label={
                  <div>
                    <Typography variant="body1">
                      このグループは誰でも参加できます。
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                      ONにすると承認なしで誰でもグループに参加できるようになります。
                    </Typography>
                    <br />
                  </div>
                }
                sx={{ alignItems: "flex-start" }} // Aligns the whole label to the top
              />
              <FormControlLabel
                value="YES"
                control={<Radio sx={{ alignSelf: "flex-start" }} />}
                label={
                  <div>
                    <Typography variant="body1">
                      このグループに参加するには承認が必要です。
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                      ONにするとこのグループは検索しても表示されなくなります。グループユーザーのみ閲覧可能です。
                    </Typography>
                  </div>
                }
                sx={{ alignItems: "flex-start" }}
              />
            </RadioGroup>: <RadioGroup
              aria-labelledby="payment-radio-buttons-group-label"
              value={approvalRequired}
              onChange={handlePPChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="NO"
                control={<Radio sx={{ alignSelf: "flex-start" }} />} // Aligns the radio button to the top
                label={
                  <div>
                    <Typography variant="body1">
                    このグループは招待されたユーザーのみ参加できます。
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                    非公開グループのため、このグループは検索しても表示されません。
                    招待されたユーザーのみ参加できます。
                    </Typography>
                    <br />
                  </div>
                }
                sx={{ alignItems: "flex-start" }} // Aligns the whole label to the top
              />
            
            </RadioGroup>}</>

           

            {/* <Typography
              style={{
                borderBottomColor: colors.farm_green150,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                fontSize: 14,
                marginBottom: 6,
                marginTop: 16,
              }}
            >
              <PeopleAltOutlinedIcon />
              &nbsp;参加募集人数について（必須）
            </Typography> */}

            {/* <RadioGroup
              aria-labelledby="payment-radio-buttons-group-label"
              value={isLimited}
              onChange={handleLimitedChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="NO"
                control={<Radio sx={{ alignSelf: "flex-start" }} />} // Aligns the radio button to the top
                label={
                  <div>
                    <Typography variant="body1">
                      参加募集人数に制限はありません。
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                      何人でもグループに参加することができます。
                    </Typography>
                    <br />
                  </div>
                }
                sx={{ alignItems: "flex-start" }} // Aligns the whole label to the top
              />
              <FormControlLabel
                value="YES"
                control={<Radio sx={{ alignSelf: "flex-start" }} />}
                label={
                  <div>
                    <Typography variant="body1">
                      以下の人数を募集しています。
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                      グループに参加でさる人数を制限できます。
                    </Typography>
                  </div>
                }
                sx={{ alignItems: "flex-start" }}
              />
            </RadioGroup>
            <>
              {isLimited === "YES" && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    paddingLeft: 16,
                  }}
                >
                  <TextField
                    hiddenLabel
                    id="outlined-basic"
                    variant="outlined"
                    value={maxGroupMembers}
                    onChange={(e: any) => setMaxGroupMembers(e?.target?.value)}
                    InputProps={{
                      style: {
                        height: 40, // Set your desired height here
                        padding: "4px 8px", // Adjust padding to fit your design
                      },
                    }}
                    style={{ marginTop: 6 }} // Keep margin top if needed
                  />
                  <Typography>人</Typography>
                </Box>
              )}
            </> */}

            <Typography
              style={{
                borderBottomColor: colors.farm_green150,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                fontSize: 14,
                marginBottom: 6,
                marginTop: 16,
              }}
            >
              <CurrencyYenSharpIcon
                style={{
                  borderWidth: 1,
                  borderStyle: "solid",
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  padding: 2,
                  margin: 2,
                }}
              />
              &nbsp;会費について
            </Typography>
            <SwitchComponent
              title="入会金があります。"
              desc="ONにすると金額を設定できます。
              入会費はグループ参加時に支払いになります。
              "
              checked={memberShipFee}
              onChange={(checked) => {
                setMemberShipFee(checked);
              }}
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <TextField
                hiddenLabel
                id="outlined-basic"
                variant="outlined"
                disabled={!memberShipFee}
                type='number'
                value={memberShipFeeAmount}
                onChange={(e: any) => setMemberShipFeeAmount(e?.target?.value)}
                InputProps={{
                  style: {
                    height: 40, // Set your desired height here
                    padding: "4px 8px", // Adjust padding to fit your design
                  },
                }}
                style={{ marginTop: 6 }} // Keep margin top if needed
              />
              <Typography>円</Typography>
            </Box>

            <SwitchComponent
              title="月額費または年会費があります。"
              desc="ONにすると料金を設定できます。"
              checked={isPaid}
              onChange={(checked) => {
                setIsPaid(checked);
              }}
            />
            <br />
            {isPaid && (
              <>
                {/* <RadioGroup
                  aria-labelledby="payment-radio-buttons-group-label"
                  value={paymentAutomatic}
                  onChange={handlePaymentAutomaticChange}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="MANUAL"
                    control={<Radio sx={{ alignSelf: "flex-start" }} />} // Aligns the radio button to the top
                    label={
                      <div>
                        <Typography variant="body1">任意支払い</Typography>

                        <Typography variant="body2" color="textSecondary">
                          選択すると、任意の会費の徴収設定ができます。
                          ＊めぶくPayからの自動引き落としはありません。
                        </Typography>
                        <br />
                      </div>
                    }
                    sx={{ alignItems: "flex-start" }} // Aligns the whole label to the top
                  />
                  <FormControlLabel
                    value="AUTOMATIC"
                    control={<Radio sx={{ alignSelf: "flex-start" }} />}
                    label={
                      <div>
                        <Typography variant="body1">自動支払い。</Typography>

                        <Typography variant="body2" color="textSecondary">
                          選択すると、メンバーのめぶくPayから自動で引き落とす設定ができます。
                        </Typography>
                      </div>
                    }
                    sx={{ alignItems: "flex-start" }}
                  />
                </RadioGroup>
                <br />
                <Typography style={{ fontSize: 12, textAlign: "center" }}>
                  会費引き落とし日を設定できます。
                </Typography>

                <DayPicker
                  disabled={paymentAutomatic === "MANUAL"}
                  selectedDay={selectedDay}
                  handleDayChange={(day) => {
                    handleDayChange(day);
                  }}
                /> */}

                <br />

                <Typography
                  style={{
                    fontSize: 14,
                    marginBottom: 6,
                  }}
                >
                  &nbsp;金額を設定します。
                </Typography>
                <RadioGroup
                  aria-labelledby="payment-radio-buttons-group-label"
                  value={paymentFrequency}
                  onChange={handlePaymentChange}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="MONTHLY"
                    control={<Radio />}
                    label="月会費" // Monthly
                  />
                  <FormControlLabel
                    value="YEARLY"
                    control={<Radio />}
                    label="年会費" // Yearly
                  />
                </RadioGroup>
                <Typography
                  style={{ fontSize: 12, color: colors.farm_gray200 }}
                >
                  グループメンバーは、マイページのファイナンスから会費をお支払いできます。
                </Typography>
                <Typography
                  style={{ fontSize: 12, color: colors.farm_gray200 }}
                >
                  注意：自動引き落とし機能はありません。
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    marginBottom: 24,
                  }}
                >
                  <TextField
                    hiddenLabel
                    id="outlined-basic"
                    variant="outlined"
                    value={groupPaymentField}
                    onChange={(e: any) =>
                      setGroupPaymentField(e?.target?.value)
                    }
                    InputProps={{
                      style: {
                        height: 40, // Set your desired height here
                        padding: "4px 8px", // Adjust padding to fit your design
                      },
                    }}
                    style={{ marginTop: 6 }} // Keep margin top if needed
                  />
                  <Typography>円</Typography>
                </Box>
                <Typography
                  style={{
                    fontSize: 14,
                    marginBottom: 6,
                  }}
                >
                  &nbsp;メモ欄
                </Typography>
                <TextField
                  // error={descError}
                  value={paymentDesc}
                  // helperText={descError ? "* fields are required.." : ""}
                  sx={{ justifyContent: "start", width: "100%" }}
                  hiddenLabel
                  multiline
                  rows={7}
                  // inputProps={{ maxLength: MAX_LENGTH }}
                  onChange={(e) => {
                    setPaymentDesc(e.target.value);
                    // if (desc.length > 0) {
                    //   setDescError(false);
                    // }
                  }}
                />
              </>
            )}
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  handleSave();
                }}
                style={{
                  ...styles.introSliderBtn,
                  marginTop: 40,
                  width: 220,
                }}
                size={"large"}
                variant="contained"
                endIcon={<ArrowForwardIosIcon />}
              >
                <span style={{ paddingRight: 30, paddingLeft: 40 }}>
                  保存する
                </span>
              </Button>
              <Box style={{ height: 100 }}></Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default CreateGroupStep2;
