import { Avatar, Box, CircularProgress, MenuItem, Select, Typography } from "@mui/material";
import style from "./styles";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ButtonOutlinedCentered from "../../../components/ButtonOutlined";
import { colors } from "../../../common/colors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import moment from "moment";

const GroupPaymentHistory = () => {
  const { user } = useSelector((state: any) => state.userAuth);
  const navigate = useNavigate();
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const years = Array.from({ length: 50 }, (_, i) => 2024 + i);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const [payments, setPayments] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const dropdownOptions = ["All", "Incomming", "Outgoing"];
  const [selectedOption, setSelectedOption] = useState("All");

  const handleAPICall = (page: number) => {
    setLoading(true);
    gcServices.getGroupPaymentInfo(groupInfo.id).then((res) => {
      setPayments(res.payments);
      setLoading(false);
    });
  };

  const showAdd = (item: any) => {
    return item.payee.id === groupInfo.id;
  };

  const filterData = () => {
    if (selectedOption === "All") {
      return payments;
    } else if (selectedOption === "Incomming") {
      return payments.filter((item: any) => item.payee.id === user.id);
    } else if (selectedOption === "Outgoing") {
      return payments.filter((item: any) => item.payee.id !== user.id);
    }
  };

  useEffect(() => {
    handleAPICall(1);
  }, []);

  return (
    <Box>
      <Box sx={style.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={style.rowMargin}>
          <Avatar src={"data:image/jpeg;base64," + user.image} sx={{ ...style.avatar, width: "30px" }} />
          <Typography>立て替え精算</Typography>
        </Box>
      </Box>
      <Box sx={style.container}>
        <Typography sx={style.titleSeprator}>めぶくPay 利用履歴</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} displayEmpty sx={{ width: "33%", backgroundColor: "white", height: "40px" }}>
            <MenuItem value="" disabled>
              {selectedYear} 年
            </MenuItem>
            {years.map((yr) => (
              <MenuItem key={yr} value={yr}>
                {yr}
              </MenuItem>
            ))}
          </Select>
          <Select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} displayEmpty sx={{ width: "30%", backgroundColor: "white", height: "40px" }}>
            <MenuItem value="" disabled>
              {selectedMonth} 月
            </MenuItem>
            {months.map((mn) => (
              <MenuItem key={mn} value={mn}>
                {mn}
              </MenuItem>
            ))}
          </Select>

          <ButtonOutlinedCentered onClick={() => handleAPICall(1)} title="表示する" />
        </Box>
        <Select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)} displayEmpty sx={{ height: "40px", width: "100%", marginTop: "10px" }}>
          {dropdownOptions.map((mn) => (
            <MenuItem key={mn} value={mn}>
              {mn}
            </MenuItem>
          ))}
        </Select>

        {!loading ? (
          filterData()?.map((item) => (
            <Box sx={{ marginTop: "20px" }}>
              <Box sx={{ ...style.boxContainer, borderColor: showAdd(item) ? colors.farm_green500 : colors.farm_red600 }}>
                <Box sx={{ ...style.row, backgroundColor: showAdd(item) ? colors.farm_green100 : colors.farm_red10 }}>
                  <Typography sx={{ padding: "5px", flex: 1 }}>{moment(item?.created_at).format("YYYY-MM-DD")}</Typography>
                  {showAdd(item) ? (
                    <AddCircleOutlineIcon sx={{ ...style.addIcon, color: colors.farm_green500 }} />
                  ) : (
                    <RemoveCircleOutlineOutlinedIcon sx={{ ...style.addIcon, color: colors.farm_red600 }} />
                  )}
                </Box>
                <Box sx={style.separator2} />
                <Box sx={style.row}>
                  <Typography sx={style.boxLeft}>支払い先</Typography>
                  <Typography sx={style.boxRight}>{showAdd(item) ? item?.payer.nickname || item?.payer.name : item?.payee.nickname || item?.payee?.name}</Typography>
                </Box>
                <Box sx={style.separator2} />
                <Box sx={style.row}>
                  <Typography sx={style.boxLeft}>支払い方法</Typography>
                  <Typography sx={style.boxRight}>{item?.payment_type}</Typography>
                </Box>
                <Box sx={style.separator2} />
                <Box sx={style.row}>
                  <Typography sx={style.boxLeft}>支払い金額</Typography>
                  <Typography
                    sx={{ ...style.boxRight, color: showAdd(item) ? colors.farm_green500 : colors.farm_red600, fontFamily: "mplus-1c-bold" }}
                  >{`¥ ${item.formatted_amount}`}</Typography>
                </Box>
                <Box sx={style.separator2} />
                <Box sx={style.row}>
                  <Typography sx={style.boxLeft}>取引ID</Typography>
                  <Typography sx={style.boxRight}>{item?.id}</Typography>
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GroupPaymentHistory;
