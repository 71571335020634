import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { useNavigate, useLocation } from "react-router-dom";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import TableComponent from "../../components/GreenCity/TableComponent";
import GroupIcon from "@mui/icons-material/Group";
import profile from "../../assets/images/profile_img.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import OptionsModal2 from "../../components/Models/OptionsModal2";
import LoadingModal from "../../components/LoadingModal";
import appServices from "../../services/app-services/appServices";
import { useSelector } from "react-redux";

interface EventDetail {
  address: string;
  anyone_can_participate: boolean;
  application_cancellation_rule: string;
  application_end_date: string;
  application_install_needed: boolean;
  application_start_date: string;
  description: string;
  end_date: string;
  id: number;
  participant_settings: {
    id: number;
    name: string;
    number_of_participants: number | null;
    fee_amount: string | null;
    free: boolean;
  }[];
  post_id: number;
  postal_code: string;
  start_date: string;
  title: string;
  url: string;
  users: {
    id: number;
    name: string;
    status: string;
    role: null;
    application_details: null;
  }[];
  venue_name: string;
  voting_question: string;
}
interface ApplicationDetails {
  id: number;
  name: string;
  free: boolean;
  group: boolean;
  fee_amount: string;
  number_of_participants: number;
  total_amount: number;
}

interface User {
  id: number;
  name: string;
  status: string;
  role: string | null;
  application_details: string; // JSON string
}

const EventUsers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { event } = location.state || {};
  const [eventDetail, setEventDetail] = useState<EventDetail>({
    address: "",
    anyone_can_participate: false,
    application_cancellation_rule: "",
    application_end_date: "",
    application_install_needed: false,
    application_start_date: "",
    description: "",
    end_date: "",
    id: 0,
    participant_settings: [],
    post_id: 0,
    postal_code: "",
    start_date: "",
    title: "",
    url: "",
    users: [],
    venue_name: "",
    voting_question: "",
  });
  const { user } = useSelector((state: any) => state.userAuth);
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const [loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [openOptions, setOpenOptions] = useState<any>(null);
  const Options = [
    { title: "投稿を見る", path: "eventPost", disabled: false },
    { title: "チャットする", path: "chat", disabled: false },
    { title: "プライベート投稿を送る", path: "private_users", disabled: false },
    { title: "役割を変更する", path: "", disabled: true },
    { title: "強制退会させる", path: "", disabled: true },
  ];

  const getEventDetails = async () => {
    try {
      setLoading(true);
      const res = await gcServices.getSingleEvent(event?.eventId);
      console.log(res?.post_event, "res");
      if (res?.post_event?.participant_settings.length === 0) {
        setFilteredUsers(res?.post_event?.users);
      }
      setEventDetail(res?.post_event);
      setUsers(res?.post_event?.users);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const filterUsersByCategory = (category: string) => {
    const filtered = users?.filter((user) => {
      const appDetails: ApplicationDetails[] = JSON.parse(
        (user as User).application_details
      );
      return appDetails.some(
        (detail) =>
          detail.name === category && detail.number_of_participants > 0
      );
    });

    setFilteredUsers(filtered);
  };

  const handleClick = (index: number) => {
    setSelectedIndex(index);
  };

  const singleUserChat = async (newUser_id: any) => {
    try {
      const existChat = await appServices.singleUserChatExist(
        groupInfo?.id,
        newUser_id,
        user.auth_token
      );

      // Find chat where both users exist
      const existingChat = existChat?.chats?.find(
        (chat: any) =>
          chat.chat_type === "one_to_one" &&
          chat.users.some((u: any) => u.id === newUser_id) &&
          chat.users.some((u: any) => u.id === user.id)
      );
      if (existingChat) {
        navigate("/userchat", {
          state: {
            chatId: existingChat.id,
            chatUsers: existingChat.users,
            chat_type: "one_to_one",
            isGroupChat: false,
            isCreator: existingChat.creator_id === user.id,
            groupInfo: groupInfo,
          },
        });
      } else {
        const response = await appServices.addSingleUserToChat(
          groupInfo?.id,
          newUser_id,
          user.auth_token
        );
        if (response?.chat?.id) {
          navigate("/userchat", {
            state: {
              chatId: response.chat.id,
              chatUsers: response.chat.users,
              chat_type: "one_to_one",
              isGroupChat: false,
              isCreator: response.chat.creator_id === user.id,
              groupInfo: groupInfo,
            },
          });
        }
      }
    } catch (error) {
      console.error("Failed to create single user chat:", error);
    }
  };

  const handleSelect = async (path: string) => {
    console.log("userid: ", openOptions);
    if (path === "chat") {
      singleUserChat(openOptions?.user_id);
    }
    if (path === "private_users") {
      console.log("user: ", openOptions);
      navigate("/createGroupPost", {
        state: {
          post_type: "private_users",
          preUsers: [
            {
              nickname: openOptions?.nickname,
              user_id: openOptions?.user_id,
              image: openOptions?.image,
            },
          ],
        },
      });
    } else if (path === "eventPost") {
      navigate("/viewGroupPost", {
        state: {
          post_type: "user",
          user: {
            nickname: user?.nickname,
            id: user?.id,
            image: user?.image,
          },
        },
      });
    }
  };

  const changeUserStatus = async (user: any) => {
    const today = new Date();
    const eventEndDate = new Date(eventDetail?.end_date);
    if (eventEndDate < today) return;

    let body = {
      id: user?.id,
      status: "confirmed",
    };
    try {
      const res = await gcServices.updateUserStatus(body);
      console.log(res);

      // Update the local `users` state directly
      const updatedUsers = users?.map((u: User) =>
        u.id === user.id ? { ...u, status: "confirmed" } : u
      );
      setUsers(updatedUsers);

      if (eventDetail.participant_settings.length === 0) {
        setFilteredUsers(updatedUsers);
        return;
      }

      // If a category is currently selected, update `filteredUsers`
      if (selectedIndex !== null) {
        const selectedCategory =
          eventDetail.participant_settings[selectedIndex].name;

        const filtered = updatedUsers?.filter((u) => {
          const appDetails: ApplicationDetails[] = JSON.parse(
            (u as User).application_details
          );
          return appDetails?.some(
            (detail) =>
              detail.name === selectedCategory &&
              detail.number_of_participants > 0
          );
        });

        setFilteredUsers(filtered);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getEventDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <LoadingModal visible={loading} handleClose={() => {}} />

      <Box style={style.headBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={{ display: "flex", gap: 1, marginTop: 1 }}>
          <Box sx={style.BoxIcon}>中</Box>
          <Typography sx={{ fontWeight: "bold" }}>
            参加者確定のイベントメンバー
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: "0 20px" }}>
        <Box sx={{ marginTop: 4 }}>
          <TableComponent
            data={[
              {
                col1: "イベント名",
                col2: event?.eventTitle,
              },
              {
                col1: "参加人数",
                col2: users?.length,
              },
            ]}
          />
        </Box>
        {eventDetail?.participant_settings?.length > 0 && (
          <Box sx={style.ApplicationFilterBox}>
            <Typography sx={{ fontWeight: "bold" }}>参加項目</Typography>
            <Box sx={{ marginTop: 2 }}>
              {eventDetail?.participant_settings?.map(
                (item: any, index: number) => (
                  <Box
                    sx={{
                      ...style.ApplicationFilter,
                      backgroundColor:
                        selectedIndex === index ? "#90C660" : "white",
                    }}
                    key={index}
                    onClick={() => {
                      filterUsersByCategory(item?.name);
                      handleClick(index);
                    }}
                  >
                    <Typography sx={{}}>
                      {item?.name}：
                      {item?.group
                        ? "1名様："
                        : `${item?.number_of_participants}名様：`}
                      {item?.free ? "無料" : `${Number(item?.fee_amount)}円`}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          </Box>
        )}
        <Box
          sx={{
            padding: "20px 0px",
            marginTop: 1,
          }}
        >
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            <GroupIcon /> メンバー一覧
          </p>
          {filteredUsers?.map((user: any, index: number) => {
            return (
              <Box>
                <Box sx={style.UserBox}>
                  <Typography>{index + 1}</Typography>
                  <Box sx={style.UserDetail}>
                    <Avatar
                      alt="Remy Sharp"
                      sx={{ width: 30, height: 30 }}
                      src={
                        user.image !== null
                          ? "data:image/jpeg;base64," + user?.image
                          : profile
                      }
                    />
                    <Typography>{user?.nickname}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginLeft: "auto" }}>
                    <CheckCircleIcon
                      sx={{
                        color: user?.status === "confirmed" ? "green" : "gray",
                        fontSize: "33px",
                      }}
                      onClick={() => {
                        if (
                          groupInfo?.role === "sub-leader" ||
                          groupInfo?.role === "leader"
                        ) {
                          changeUserStatus(user);
                        }
                      }}
                    />
                    <Box
                      sx={{ margin: "0 5px" }}
                      style={style.optionButton}
                      onClick={() => setOpenOptions(user)}
                    >
                      <MoreHorizIcon sx={style.MoreOptions} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
          <OptionsModal2
            avatar={
              openOptions?.image !== null
                ? "data:image/jpeg;base64," + openOptions?.image
                : profile
            }
            userName={openOptions?.nickname}
            open={openOptions !== null}
            onClose={() => setOpenOptions(null)}
            options={Options}
            onSelect={handleSelect}
          />
        </Box>
      </Box>
    </Box>
  );
};
const style = {
  optionButton: {
    border: "1px solid #4792E0",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1px",
  },
  headBox: {
    paddingTop: 100,
    paddingLeft: 16,
    paddingBottom: "12px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
  BoxIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    padding: 1.5,
  },

  ApplicationFilterBox: {
    padding: "30px 20px",
    backgroundColor: "#F4F4F4",
    marginTop: 4,
    borderRadius: 2,
  },
  ApplicationFilter: {
    marginTop: 2,
    display: "flex",
    gap: 1,
    padding: "12px 10px",
    borderRadius: "5px",
  },
  UserBox: {
    borderBottom: "1px gray solid",
    marginTop: "10px",
    textAlign: "left",
    paddingBottom: "8px",
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
  },
  UserDetail: {
    display: "flex",
    alignItems: "center",
    marginLeft: 2,
    gap: 1,
  },
  MoreOptions: {
    color: "#4792E0",
    cursor: "pointer",
    fontSize: "25px",
  },
};
export default EventUsers;
