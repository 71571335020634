import { Box, Button, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { styles } from "../../styles";
import "./pay.css";

interface Props {}

function ScanStoreQR({}: Props): ReactElement {
  const navigate = useNavigate();
  const groupInfo = useSelector((state: any) => state.gcGroup);


  // Result
  const [scannedResult, setScannedResult] = useState<string | undefined>("");


  useEffect(()=>{
    window.parent.postMessage("camera_request", "*");
    window.addEventListener('message', (event) => {
      if (event.data.type === 'qr_code_data') {
        
        window.parent.postMessage("camera_close", "*")
        console.log('....',event?.data?.qrCodeData)
        setScannedResult(event?.data?.qrCodeData)
        alert('data'+ JSON.stringify(event?.data?.qrCodeData))
        
      }
    });
    return () => {
      window.parent.postMessage("camera_close", "*");
    }

  },[]);

  return (
    <div style={{ paddingTop: 100, marginLeft: 16 }}>
      <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
      <Box style={{ padding: 16 }}>
        <Typography style={{ fontWeight: "700" }}>
          <img
            src={`${groupInfo?.groupImage}`}
            alt={"img"}
            style={{
              ...styles.farmProfileIcon,
              height: 40,
              width: 40,
              margin: 0,
            }}
          />
          &nbsp;{groupInfo?.groupName}
        </Typography>
      </Box>

      {/* QR Scanner */}
      <div className="qr-reader">

        <Button onClick={()=>{window.parent.postMessage("camera_request", "*")}} >Scan QR</Button>
        {/* <video ref={videoEl} style={{ width: "100%", height: "auto" }}></video>
        <div ref={qrBoxEl} className="qr-box">
          <img
            src={QrFrame}
            alt="Qr Frame"
            width={256}
            height={256}
            className="qr-frame"
          />
        </div> */}

        {/* Show scanned result */}
       
        {/* Show errors if any */}
       
      </div>
      <Typography>  Scanned Result: {scannedResult}</Typography>
    </div>
  );
}

export default ScanStoreQR;
