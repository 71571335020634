import React, { useEffect, useState } from "react";
import { Modal, Box, Typography } from "@mui/material";

// Define the props interface
interface UserDeleteModalProps {
  open: boolean;
  showButton?: boolean;
  message: string;
  onClose: () => void;
  onContinue: () => void;
  noText?:string;
  yesText?:string;
}

const UserDeleteModal: React.FC<UserDeleteModalProps> = ({ open, onClose, onContinue, message,noText,yesText }) => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showMainModal, setShowMainModal] = useState(open);

  // Update showMainModal when 'open' prop changes
  useEffect(() => {
    setShowMainModal(open);
  }, [open]);

  const handleDelete = () => {
    setShowMainModal(false); // Hide first modal
    onContinue();
    setShowSuccessPopup(true);
    setTimeout(() => {
      setShowSuccessPopup(false);
    }, 1100);
   
    setTimeout(() => {
     
      onClose(); // Close the modal completely
      
    }, 1800);
  };

  return (
    <>
      <Modal open={showMainModal} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85%",
            maxWidth: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            opacity: showMainModal ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          <Typography variant="body1" sx={{ mb: 2, textAlign: "center", padding: "30px 20px" }}>
            {message}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{ cursor: "pointer", width: "50%", textAlign: "center", border: "0.5px solid gray", borderRight: "none", borderLeft: "none", padding: "10px" }}
              onClick={onClose}
            >
              {noText?noText:'近い'} 
            </Typography>
            <Typography sx={{ cursor: "pointer", width: "50%", textAlign: "center", border: "0.5px solid gray", borderRight: "none", padding: "10px" }} onClick={handleDelete}>
            {yesText?yesText:'消去'}  
            </Typography>
          </Box>
        </Box>
      </Modal>

      <Modal open={showSuccessPopup}>
       <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85%",
            maxWidth: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            padding: "20px",
            opacity: showSuccessPopup ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
        >
        
          {message==="このチャットから退出しますか？一度退出するとチャットの履歴は確認できなくなります。"&&<Typography variant="body1" sx={{ textAlign: "center" }}>
          退出しました。
          </Typography>}
          {message==="このユーザーをチャットから削除してもよろしいですか ?"&&<Typography variant="body1" sx={{ textAlign: "center" }}>
            削除されました
          </Typography>}
        </Box>
      </Modal>
    </>
  );
};

export default UserDeleteModal;
