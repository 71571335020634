import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography, CircularProgress } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { colors } from "../../common/colors";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import moment from "moment";

function GroupMemberFee() {
  const navigate = useNavigate();
  const location = useLocation();
  const { groupInfo } = location?.state || {};
  const [data, setData] = useState(null);
  const [laoding, setLoading] = useState(true);

  useEffect(() => {
    gcServices
      .getGroupPayments(groupInfo?.id, groupInfo?.fee_type)
      .then((res) => {
        setLoading(false);
        setData(res);
      })
      .catch((error) => {
        setLoading(false);
        console.log("errrs: ", error);
      });
  }, []);

  return (
    <Box>
      <Box style={style.headerContainer}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box style={style.headerContent}>
          <Box style={style.eventPostingBox}>
            <Box component="div" style={style.eventPostingIcon}>
              <Avatar src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`} style={style.groupIcon} />
            </Box>
            <Typography style={style.paymentStatus}>Payment Status</Typography>
          </Box>
        </Box>
      </Box>

      {laoding ? (
        <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Box style={style.container}>
          <div style={style.tableContainer}>
            <table style={style.table}>
              <thead>
                <tr>
                  <th style={{ ...style.tableHeaderCell, ...style.firstColoumn }}>
                    <div style={style.tableHeaderDiv}>
                      <Typography style={style.monthlyText}>{data.payment_type === "monthly" ? "毎月" : "毎年"}</Typography>
                    </div>
                  </th>
                  <th style={style.tableHeaderCell}>
                    <Typography sx={style.name}>{moment(data.payment_dates[0]).format(data.payment_type === "monthly" ? "MM" : "YYYY")} 月分</Typography>
                  </th>
                  <th style={style.tableHeaderCell}>
                    <Typography sx={style.name}>{moment(data.payment_dates[1]).format(data.payment_type === "monthly" ? "MM" : "YYYY")} 月分</Typography>
                  </th>
                  <th style={style.tableHeaderCell}>
                    <Typography sx={style.name}>{moment(data.payment_dates[2]).format(data.payment_type === "monthly" ? "MM" : "YYYY")} 月分</Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.data.map((row, index) => (
                  <tr key={row.id}>
                    <td style={{ ...style.tableCell, borderLeft: "0px" }}>
                      <div style={style.tableCellContent}>
                        <Typography sx={style.name}>{index + 1}</Typography>
                        <Avatar src={row?.image !== null && "data:image/jpeg;base64," + row?.image} alt={"img"} style={style.avatar} />
                        <Typography sx={style.name}>{row?.nickname}</Typography>
                      </div>
                    </td>
                    {row.payments.map((status, idx) => (
                      <td key={idx} style={style.tableCell}>
                        <CheckCircleOutlineIcon style={{ ...style.checkIcon, color: status.status ? colors.farm_green500 : colors.farm_gray200 }} />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Box>
      )}
    </Box>
  );
}

const style = {
  headerContainer: {
    paddingTop: 100,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: "12px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "10px",
  },
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  eventPostingIcon: {
    borderRadius: "50%",
    padding: "3px 6px",
    color: "white",
  },
  groupIcon: {
    width: 30,
    height: 30,
  },
  paymentStatus: {
    fontWeight: "700",
    marginTop: "5px",
  },
  container: {
    backgroundColor: "#ECEFF1",
    padding: "20px 30px",
    flex: 1,
  },
  tableContainer: {
    overflowX: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeaderCell: {
    width: "20%",
    textAlign: "left",
    padding: "8px",
    borderLeft: "1px solid #BCBCBC",
    borderBottom: `2px solid ${colors.farm_green500}`,
  },
  tableHeaderDiv: {
    maxWidth: "100px",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #BCBCBC",
  },
  monthlyText: {
    backgroundColor: colors.farm_white,
    textAlign: "center",
  },
  tableCell: {
    padding: "8px",
    borderTop: "1px solid #BCBCBC",
    borderLeft: "1px solid #BCBCBC",
  },
  tableCellContent: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    width: 30,
    height: 30,
    margin: "0px 10px",
  },
  checkIcon: {
    fontSize: "26px",
    fontWeight: "400",
  },
  firstColoumn: {
    borderLeft: "0px",
    width: "40%",
  },
  name: {
    fontSize: "12px",
  },
};

export default GroupMemberFee;
