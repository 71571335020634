import { Badge, Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { colors } from "../../common/colors";
interface Props {
  onClick(): void;
  disabled?: boolean;
  title: string;
  style?: any;
  badge?: any;
}
export default function ButtonArrow({
  title,
  onClick,
  disabled = false,
  badge,
}: Props) {
  return (
    <Box
      style={{
        height: 40,
        marginLeft: 16,
        marginRight: 16,
        borderRadius: 8,
        marginBottom: 12,
        padding: 10,
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderWidth: 1,
        borderColor: colors.farm_gray100,
        borderStyle: "solid",

        backgroundColor: disabled ? colors.farm_gray100 : "white",
      }}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      <Typography style={{ fontSize: 12 }} color={"black"}>
        {title}
      </Typography>
      <ArrowForwardIosIcon style={{ marginRight: 6, fontSize: 14 }} />

      {badge?.length > 0 && !disabled && (
        <Box sx={{ position: "absolute", right: "76px", marginTop: "-35px" }}>
          <Badge
            badgeContent={badge?.length} // Notification count
            color="error"
            sx={{
              "& .MuiBadge-badge": {
                minWidth: 20,
                height: 20,
                background: "#FF3B00",
                borderRadius: "50%",
                fontSize: "0.75rem", // Adjust the text size
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
}
