import { Box, Button, Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GCBackButton from '../../../../components/GreenCity/GCBackButton'
import { styles } from '../../../styles'



function InviteMembers(): ReactElement {

    const navigate = useNavigate();
    const groupInfo = useSelector((state: any) => state.gcGroup);
    
  return (
    <div style={{ paddingTop: 100, marginLeft: 16 }}>
    <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
    <Box style={{ padding: 16 }}>
      <Typography style={{ fontWeight: "700" }}>
        <img
          src={`${groupInfo?.groupImage}`}
          alt={"user_img"}
          style={{
            ...styles.farmProfileIcon,
            height: 50,
            width: 50,
            margin: 0,
          }}
        />
        &nbsp;{groupInfo?.groupName}
      </Typography>
    </Box>
<Typography>検索する</Typography>
    <Button
        color="secondary"
        sx={style.submitButton}
        size={"large"}
        variant="outlined"
        // onClick={copyToClipboard}
       
      >
       ユーザーを検索する
      </Button>
    </div>
  )
}

export default InviteMembers

const style = {
   
    submitButton: {
      width: "80%",
      alignSelf: "center",
      margin: "0 auto",
      display: "flex",
      borderRadius: 20,
      border: "2px solid",
      marginBottom: 8,
    },
  };
