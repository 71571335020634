import axiosService from "../../axios-util/axios-services";
// import { Createchat } from "../../../util/types";

const createGroup = async (body: any) => {
  const response = await axiosService.post(`external/member_groups`, body);
  console.log(response);
  return response;
};

const editGroup = async (id: any, body: any) => {
  const response = await axiosService.put(`external/member_groups/${id}`, body);
  console.log(response);
  return response;
};

/**
 * Fetches all groups that a user is a member of
 * @returns {Promise<AxiosResponse<any>>} - the response from the server
 */
const getMembersGroups = async (ransank: string) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(`external/member_groups${ransank}`);
  return response.data;
};

/**
 * Fetches posts that belong to a group
 * @param {string | number} group_id - the id of the group
 * @returns {Promise<AxiosResponse<any>>} - the response from the server
 */
const getGroupPosts = async (group_id: string | number, isPrivate: boolean, privateUsers: boolean, page: number) => {
  const response = await axiosService.get(`posts?q[member_group_id_eq]=${group_id}&q[status_eq]=approved&q[private_users_eq]=${isPrivate}&q[is_private_group_user_eq]=${privateUsers}&page=${page}`);
  return response.data;
};
const getUserPosts = async (user_id: number, page: number) => {
  const response = await axiosService.get(`external/posts?q[status_eq]=approved&q[user_id_eq]=${user_id}&page=${page}&[member_group_eq]=''`);
  return response.data;
};

const search = async (term: string, config: any) => {
  const response = await axiosService.get(`external/member_groups?q[group_type_not_eq]=Private&q[name_i_cont]=${term}`, config);
  console.log(response);
  return response.data;
};

const searchById = async (id: string, config: any) => {
  const response = await axiosService.get(`external/member_groups?q[id_eq]=${id}`, config);
  console.log(response);
  return response.data;
};

const updateMember = async (id: any, status: any) => {
  const body = {
    status: status,
  };

  const response = await axiosService.put(`external/member_group_users/${id}`, body);
  return response.data;
};

const updateMemberAccept = async (id: any, body: any) => {
  const response = await axiosService.put(`external/member_group_users/${id}`, body);
  return response.data;
};

const updateMemberRoleOnly = async (id: any, role: any) => {
  const body = {
    role: role,
  };

  const response = await axiosService.put(`external/member_group_users/${id}`, body);
  return response.data;
};

const deleteMember = async (id: any) => {
  const response = await axiosService.delete(`external/member_group_users/${id}`);
  return response.data;
};
const deleteEventMember = async (id: any) => {
  const response = await axiosService.delete(`external/post_event_users/${id}`);
  return response.data;
};
const checkIsMembership = async (userId: any, groupId: any) => {
  const response = await axiosService.get(`external/member_group_users?q[member_group_id_eq]=${groupId}&q[user_id_eq]=${userId}`);
  return response.data;
};

const getMemberList = async (groupId: any) => {
  const response = await axiosService.get(`external/member_group_users?q[member_group_id_eq]=${groupId}&q[status_eq]=approved&per_page=20000`);
  return response.data;
};
const getMemberPendingList = async (groupId: any) => {
  const response = await axiosService.get(`external/member_group_users?q[member_group_id_eq]=${groupId}&q[status_eq]=pending&per_page=20000`);
  return response.data;
};

const getChatList = async (groupId: any) => {
  const response = await axiosService.get(`external/chats?filter_chats=1&q[member_group_id_eq]=${groupId}}`);
  return response.data;
};

const getAllChatList = async () => {
  const response = await axiosService.get(`external/chats`);
  return response.data;
};

// eslint-disable-next-line
const getApprovedMemberList = async (groupId: any) => {
  // q[status_eq]=approved
  const response = await axiosService.get(`external/member_group_users?q[member_group_id_eq]=${groupId}}`);
  return response.data;
};

const joinGroup = async (userId: any, groupId: any, status: any) => {
  const body = {
    role: "member",
    member_group_id: groupId,
    user_id: userId,
    status: status,
  };

  const response = await axiosService.post(`external/member_group_users`, body);
  return response.data;
};

const inviteMember = async (userId: any, groupId: any, status: any) => {
  const body = {
    role: "member",
    member_group_id: groupId,
    user_id: userId,
    status: status,
  };

  const response = await axiosService.post(`external/member_group_users`, body);
  return response.data;
};

const payToGroup = async (body: any) => {
  const response = await axiosService.post(`payments`, body);
  return response.data;
};

const paymentReimbursementsAdd = async (body: any) => {
  const response = await axiosService.post(`external/reimbursements`, body);
  return response.data;
};
const getPaymentReimbursements = async (user_id: any) => {
  const response = await axiosService.get(`external/reimbursements?q[user_id_eq]=${user_id}`);
  return response.data;
};

const getPaymentGroupReimbursements = async (id: any) => {
  const response = await axiosService.get(`external/reimbursements?q[member_group_id_eq]=${id}&q[status_eq]=pending`);
  return response.data;
};

const approveRejectReimbursements = async (id: any, status: string) => {
  const response = await axiosService.patch(`external/reimbursements/${id}/${status}`);
  return response.data;
};

const cancelEventApplication = async (body: any) => {
  const payload = {
    status: body?.status,
    application_details: body?.application_details,
  };
  const response = await axiosService.put(`external/post_event_users/${body?.id}`, payload);
  return response.data;
};

const userEvents = async (userId: any) => {
  const response = await axiosService.get(`external/post_event_users?per_page=2000&q[user_id_eq]=${userId}`);
  return response.data;
};

const getUsersGroupOREvents = async (url: any) => {
  const response = await axiosService.get(url);
  // const response = await axiosService.get(
  //   `external/post_event_users?per_page=200&q[user_id_eq]=${userId}?per_page=20000`
  // );
  return response.data;
};

const editEvent = async (id: number, body: any) => {
  // const user = await userAuth.getUser();

  const response = await axiosService.put(`external/posts/${id}`, body);
  return response;
};

const getSingleEventById = async (id: number) => {
  const response = await axiosService.get(`external/posts/${id}`);
  return response.data;
};

const getCancelRequestsOfGroup = async (groupId: any) => {
  const response = await axiosService.get(`external/post_event_users?q[status_eq]=Cancel-pending&q[post_event_post_member_group_id_eq]=${groupId}`);
  return response.data;
};

const getSingleEvent = async (id: number) => {
  const response = await axiosService.get(`external/post_events/${id}`);
  return response.data;
};

const getEventPostApplications = async (id: number) => {
  const response = await axiosService.get(`external/post_event_users?per_page=200&q[post_event_post_member_group_id_eq]=${id}&q[status_in][]=applied&q[status_in][]=confirmed`);
  return response.data;
};

const updateUserStatus = async (body: any) => {
  const payload = {
    status: body?.status,
  };
  const response = await axiosService.put(`external/post_event_users/${body?.id}`, payload);
  return response.data;
};

const searchUsers = async (term: string) => {
  const response = await axiosService.get(`external/users?q[nickname_cont]=${term}`);
  console.log(response);
  return response.data;
};

const searchUserRealName = async (term: string) => {
  const response = await axiosService.get(`external/users?q[name_cont]=${term}`);
  console.log(response);
  return response.data;
};

const getPaymentInfo = async (group_id: number, user_id: number) => {
  const res = await axiosService.get(`payments?q[member_group_id_eq]=${group_id}&q[from_user_id_eq]=${user_id}`);
  console.log("res: ", res.data);
  return res.data;
};

const getUserPaymentInfo = async (user_id: number, selectedYear: string, selectedMonth: string) => {
  const res = await axiosService.get(
    `payments?q[m]=or&q[payee_id_eq]=${user_id}&q[payer_id_eq]=${user_id}&q[created_at_gteq]=${selectedYear}-${selectedMonth}-01&q[created_at_lteq]=${selectedYear}-${selectedMonth}-31`
  );
  return res.data;
};

const getGroupPaymentInfo = async (id: number) => {
  const res = await axiosService.get(`payments?q[m]=or&q[payee_id_eq]=${id}&q[payer_id_eq]=${id}`);
  return res.data;
};

const changePostStatus = async (post_id: number, status: any) => {
  // reject approve
  const response = await axiosService.patch(`posts/${post_id}/${status}`);

  return response;
};

const getGroupPayments = async (group_id: number, fee_type: string) => {
  const response = await axiosService.get(`payments/user_payments?q[payment_type_eq]=${fee_type}&q[payer_type_eq]=User&q[payee_type_eq]=MemberGroup&q[payee_id_eq]=${group_id}`);
  return response.data;
};

const createPersonalPlan = async (body: any) => {
  const response = await axiosService.post(`personal_plans`, body);
  return response.data;
};
const getPersonalPlans = async (user_id: number) => {
  const response = await axiosService.get(`personal_plans?q[user_id_eq]=${user_id}`);
  return response.data;
};

const getSinglePersonalPlan = async (id: number) => {
  const response = await axiosService.get(`personal_plans/${id}`);
  return response.data;
};
const editPersonalPlan = async (id: number, body: any) => {
  const response = await axiosService.put(`personal_plans/${id}`, body);
  return response.data;
};
const getSingleUser = async (id: number) => {
  const response = await axiosService.get(`external/users/${id}`);
  return response.data;
};

const AddUserInPost = async (post_id: number, user_id: number) => {
  const res = await axiosService.post(`external/post_private_users`, {
    post_id: post_id,
    user_id: user_id,
  });
  return res.data;
};
const RemoveUserInPost = async (user_id: number) => {
  const res = await axiosService.delete(`external/post_private_users/${user_id}`);
  return res.data;
};

const getPostUsers = async (post_id: number) => {
  const res = await axiosService.get(`external/post_private_users?q[post_id_eq]=${post_id}`);
  return res.data;
};

const getGroupBalance = async (groupId: number) => {
  const res = await axiosService.get(`external/member_groups/${groupId}/balance`);
  return res.data;
};

const getUserBalance = async (userId: number) => {
  const res = await axiosService.get(`external/users/${userId}/balance`);
  return res.data;
};



const gcServices = {
  createPersonalPlan,
  getSingleUser,
  deleteEventMember,
  editPersonalPlan,
  getSinglePersonalPlan,
  getPersonalPlans,
  approveRejectReimbursements,
  getPaymentGroupReimbursements,
  getPaymentReimbursements,
  paymentReimbursementsAdd,
  changePostStatus,
  updateUserStatus,
  updateMemberRoleOnly,
  createGroup,
  getEventPostApplications,
  getCancelRequestsOfGroup,
  userEvents,
  getSingleEventById,
  getSingleEvent,
  editEvent,
  getMembersGroups,
  editGroup,
  search,
  getGroupPosts,
  deleteMember,
  updateMember,
  checkIsMembership,
  getMemberList,
  getMemberPendingList,
  joinGroup,
  inviteMember,
  updateMemberAccept,
  cancelEventApplication,
  getChatList,
  searchById,
  getAllChatList,
  searchUsers,
  searchUserRealName,
  payToGroup,
  getUsersGroupOREvents,
  getPaymentInfo,
  getUserPaymentInfo,
  getGroupPaymentInfo,
  getGroupPayments,
  getUserPosts,
  RemoveUserInPost,
  AddUserInPost,
  getPostUsers,
  getGroupBalance,
  getUserBalance
};
export default gcServices;
