import { Avatar, Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./styles";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { useEffect, useState } from "react";
import ButtonOutlinedCentered from "../../../components/ButtonOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { colors } from "../../../common/colors";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";

const PaymentReimbursement2 = () => {
  const navigate = useNavigate();
  const props = useLocation().state;
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const years = Array.from({ length: 50 }, (_, i) => 2024 + i);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const [payments, setPayments] = useState<any[]>([]);
  const { user } = useSelector((state: any) => state.userAuth);

  useEffect(() => {
    gcServices.getPaymentInfo(props.selectedGroup.id, user.id).then((res) => {
      setPayments(res.payments);
    });
  }, []);

  return (
    <Box>
      <Box sx={style.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={style.rowMargin}>
          <Avatar src={"data:image/jpeg;base64," + user.image} sx={{ ...style.avatar, width: "30px" }} />
          <Typography>立て替え精算</Typography>
        </Box>
      </Box>
      <Box sx={style.container}>
        <Typography sx={style.titleSeprator}>めぶくPay 利用履歴</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} displayEmpty sx={{ width: "33%", backgroundColor: "white", height: "40px" }}>
            <MenuItem value="" disabled>
              {selectedYear} 年
            </MenuItem>
            {years.map((yr) => (
              <MenuItem key={yr} value={yr}>
                {yr}
              </MenuItem>
            ))}
          </Select>
          <Select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} displayEmpty sx={{ width: "30%", backgroundColor: "white", height: "40px" }}>
            <MenuItem value="" disabled>
              {selectedMonth} 月
            </MenuItem>
            {months.map((mn) => (
              <MenuItem key={mn} value={mn}>
                {mn}
              </MenuItem>
            ))}
          </Select>

          <ButtonOutlinedCentered onClick={() => console.log("clicked")} title="表示する" />
        </Box>

        {payments.map((item) => (
          <Box sx={{ ...style.row, marginTop: "20px" }}>
            <Box sx={style.boxContainer}>
              <Typography sx={{ padding: "5px", backgroundColor: colors.farm_red10 }}>some text here</Typography>
              <Box sx={style.separator2} />
              <Box sx={style.row}>
                <Typography sx={style.boxLeft}>支払い先</Typography>
                <Typography sx={style.boxRight}>dummy value</Typography>
              </Box>
              <Box sx={style.separator2} />
              <Box sx={style.row}>
                <Typography sx={style.boxLeft}>支払い方法</Typography>
                <Typography sx={style.boxRight}>{item.payment_type}</Typography>
              </Box>
              <Box sx={style.separator2} />
              <Box sx={style.row}>
                <Typography sx={style.boxLeft}>支払い金額</Typography>
                <Typography sx={{ ...style.boxRight, color: colors.farm_red600, fontFamily: "mplus-1c-bold" }}>{"¥ " + item.formatted_amount}</Typography>
              </Box>
              <Box sx={style.separator2} />
              <Box sx={style.row}>
                <Typography sx={style.boxLeft}>取引ID</Typography>
                <Typography sx={style.boxRight}>{item.id}</Typography>
              </Box>
            </Box>
            <AddCircleOutlineIcon sx={style.addIcon} onClick={() => navigate("/paymentreimbursement", { state: { selectedPayment: item, selectedGroup: props.selectedGroup } })} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default PaymentReimbursement2;
