import React, { useEffect } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import GCBackButton from "../components/GreenCity/GCBackButton";
import ButtonArrow2 from "../components/GreenCity/ButtonArrow2";
import gcServices from "../services/app-services/greenCity/gcServicesR6";
import dayjs from "dayjs";
import LoadingModal from "../components/LoadingModal";
import { useSelector } from "react-redux";

const CancelRequestsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const groupInfo = useSelector((state: any) => state.gcGroup);
  console.log(groupInfo);

  const [cancelRequests, setCancelRequests] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { groupId } = location.state || {}; // Destructure your props

  const getCancelRequests = async () => {
    try {
      setLoading(true);
      const res = await gcServices.getCancelRequestsOfGroup(groupId);
      setCancelRequests(res?.post_event_users);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  const navigateToPaymentDetails = (userPaymentDetail: any) => {
    navigate("/usereventpaymentdetailscreen", {
      state: {
        role: "leader",
        userPaymentDetail: userPaymentDetail,
      },
    });
  };

  useEffect(() => {
    getCancelRequests();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <LoadingModal visible={loading} handleClose={() => {}} />

      <div
        style={{
          paddingTop: 100,
          paddingBottom: "12px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box component="div" style={styles.topButtonContainer}>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Avatar
              sx={{ width: 30, height: 30 }}
              alt="Remy Sharp"
              src={groupInfo?.groupImage}
            />
            <Typography style={{ marginTop: "5px", fontWeight: "bold" }}>
              イベントキャンセルの精算
            </Typography>
          </Box>
        </Box>
      </div>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          width: "100%",
          paddingTop: "10px",
          padding: "10px 15px",
        }}
      >
        グループの「めぶくPay」残高: 10,594円
      </Box>
      <Box sx={{ padding: "10px 15px" }}>
        <Typography
          sx={{
            borderBottom: "2px solid #86BB57",
            marginTop: "10px",
            textAlign: "left",
            fontSize: "15px",
          }}
        >
          キャンセル待ちのイベント
        </Typography>
        <Box sx={{ marginTop: "30px" }}>
          {cancelRequests?.map((item: any, index: number) => {
            const detail = JSON.parse(item?.application_details);
            let cancelDate = detail[0]?.cancelledApplicationDate;
            return (
              <ButtonArrow2
                key={index}
                title={item?.event_title}
                desc={`申請日：${dayjs(cancelDate).format(
                  "YYYY年M月D日 (ddd)"
                )}`}
                onClick={() => {
                  navigateToPaymentDetails(item);
                }}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  eventPostingIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    padding: "3px 6px 3px 6px",
    color: "white",
  },

  topButtonContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginTop: 10,
    paddingLeft: 16,
    paddingRight: 16,
  },

  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
};

export default CancelRequestsPage;
