import * as React from "react";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import profile from "../assets/images/profile_img.png";
import { LanguageList } from "react-translator-component";
import LogoutIcon from "@mui/icons-material/Logout";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { useState } from "react";
import appServices from "../services/app-services/appServices";
import LoadingModal from "../components/LoadingModal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { styles } from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { T } from "react-translator-component";
import { Box, IconButton, colors } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/store";
import { reset } from "../redux/Reducers/userAuthReducer";
import setAuthorizationToken from "../services/axios-util/set-authorization-token";
import EventCalendar from "../components/GreenCity/EventCalender";
import { colors as farm_colors } from "../common/colors";
import ButtonArrow from "../components/GreenCity/ButtonArrow";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import IconButtonArrow from "../components/GreenCity/IconButtonArrow";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import IconWithBackground from "../components/GreenCity/IconWithBackground";
import ChatIcon from "@mui/icons-material/Chat";
import { clearGroupInfo, getMembersGroups, setGroupInfo } from "../redux/Reducers/gcGroupReducer";
import gcServices from "../services/app-services/greenCity/gcServicesR6";
import AddLinkIcon from "@mui/icons-material/AddLink";
// import { User } from "../util/types";
import TextButton from "../components/GreenCity/TextButton";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import dayjs from "dayjs";
import Separator from "../components/Separator";
import MessageModal from "../components/Models/GreenCity/MessageModal";

interface EventDate {
  title: string;
  color: string;
  start: string;
  end: string;
  id: number | string;
  post_id?: number | string;
}

interface PersonalPlan {
  title: string;
  color: string;
  start: string;
  end: string;
  id: number | string;
  type: "personal_plan";
  user_id: number | string;
}

type CombinedDate = EventDate | PersonalPlan;

export default function MyFarm() {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: any) => state.userAuth);
  const { member_groups } = useSelector((state: any) => state.gcGroup);
  const [mygroups, setMyGroups] = useState<any>([]);
  const [allGroups, setAllGroups] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [dates, setDates] = useState<CombinedDate[]>([]);
  const [personalPlan, setPersonalPlan] = useState<any>(null);
  const [showDeniedMessage,setDeniedMessage]=useState<any>();
  // const [allChats, setAllChats] = useState<listChats[]>([]);

  const navigate = useNavigate();
  const AddIcon = () => {
    return (
      <div style={style.addIcon}>
        <AddRoundedIcon sx={{ width: 20, height: 20 }} />
      </div>
    );
  };
 


  const getUserEvents = async (user_id: number) => {
    try {
      const res = await gcServices.userEvents(user_id);
      const leaderPlans = await gcServices.getPersonalPlans(user_id);

      const eventDates: EventDate[] =
        res?.post_event_users
          ?.filter((event: any) => event?.status !== "Cancelled") // Exclude events with status "Cancelled"
          .map((event: any) => ({
            title: event?.event_title,
            color: event?.status === "applied" ? "#86BB57" : "gray",
            start: event?.event_start_date,
            end: dayjs(event?.event_end_date).add(1, "day").format("YYYY-MM-DD"),
            id: event?.id,
            post_id: event?.post_id,
          })) || []; // Default to empty array if undefined

      const personalPlans: PersonalPlan[] =
        leaderPlans?.personal_plans?.map((plan: any) => ({
          title: plan?.title,
          color: "#3a79f0",
          start: plan?.start_date,
          end: dayjs(plan?.end_date).add(1, "day").format("YYYY-MM-DD"),
          id: plan?.id,
          type: "personal_plan",
          user_id: plan?.user_id,
        })) || []; // Default to empty array if undefined

      // Concatenate both arrays and set in setDates hook
      const combinedDates: CombinedDate[] = [...eventDates, ...personalPlans];
      setDates(combinedDates);
    } catch (error) {
      console.error(error);
    }
  };

  const getSelectedEvent = async (date: any) => {
    try {
      if (date?.type === "personal_plan") {
        setVisible(true);
        const res = await gcServices.getSinglePersonalPlan(date.id);
        setPersonalPlan(res?.personal_plan);
        setVisible(false);
      } else {
        const res = await gcServices.getSingleEventById(date.post_id);
        navigate("/calenderevent", { state: { event: [res?.post] } });
      }
    } catch (error) {
      setVisible(false);
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (user?.id) {
     apiCalls();
    }
    const rs = `?per_page=5&q[member_group_users_status_in][]=approved&q[member_group_users_status_in][]=denied&q[member_group_users_status_in][]=invited&q[member_group_users_status_in][]=unpaid_approved&q[member_group_users_user_id_eq]=${user.id}`;
    dispatch(getMembersGroups(rs));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    const combineAndDeduplicateGroups = () => {
      const combinedGroups = [...(member_groups?.member_groups || []), ...(mygroups || [])];

      // Deduplicate using a key generated from unique properties
      const groupMap = new Map();
      combinedGroups.forEach((group) => {
        const uniqueKey = group?.id || `${group?.name}-${group?.created_at}`;
        if (uniqueKey) {
          groupMap.set(uniqueKey, group);
        }
      });

      // Sort by `created_at` in descending order
      const sortedGroups = Array.from(groupMap.values()).sort((a, b) => {
        const dateA = new Date(a?.created_at).getTime();
        const dateB = new Date(b?.created_at).getTime();
        return dateB - dateA;
      });

      return [ ...sortedGroups];
    };

    // Debugging logs
    // console.log("Combined Groups (before deduplication):", [...(member_groups?.member_groups || []), ...(mygroups || [])]);
    // console.log("Invited Groups:", invited);

    // Update state
    setAllGroups(combineAndDeduplicateGroups());
  }, [mygroups, member_groups]);

  const [expanded, setExpanded] = useState("0");
  const [accordianHeight, setAccordianHeight] = useState(40);

  const handleChange = (expand: string) => {
    setExpanded(expand);
    if (expand === "0") {
      setAccordianHeight(40);
    } else {
      setAccordianHeight(100);
    }
  };

  const apiCalls = async () => {
    setVisible(true);
    try {
      getUserEvents(user.id);
      await appServices.getHistoryPosts(user.id, 1);
      // const invitedrs = `?per_page=5&q[member_group_users_user_id_eq]=${user.id}&q[status_eq]=invited`;
      // const invited = await gcServices.getMembersGroups(invitedrs);
      // setInvited(invited.member_groups);
      const mygroup = `?per_page=5&q[user_id_eq]=${user.id}`;
      const mygroups = await gcServices.getMembersGroups(mygroup);
      setMyGroups(mygroups.member_groups);
      setVisible(false);
    } catch (error: any) {
      if (error?.response?.status === 403) {
        logout();
      }
      setVisible(false);
    }
  };

  const handleLangage = () => {
    navigate(0);
  };

  const handleGroup = (group: any) => {
    if (group?.owner?.id === user?.id) {
      navigate("/groupleader", {
        state: {
          groupInfo: { ...group, user: group?.owner, role: group?.role },
        },
      });
    } else if (group?.owner?.id !== user?.id) {
      if (group?.status === "unpaid_approved") {
        navigate("/grouppayment", { state: { groupInfo: group } });
      } else if (group?.status === "approved") {
        navigate("/groupleader", {
          state: {
            groupInfo: { ...group, user: group?.owner, role: group?.role },
          },
        });
      } else if (group?.status === "denied") {
        setDeniedMessage(group)
      }
      else if (group?.status === "denied") {
        handleReview(group);
      } else {
        handleReview(group);
      }
    }
  };
   const handleDeleteMember = async (id: any) => {
    console.log(`User with ID ${id} deleted`);
  
    try {
      await gcServices.deleteMember(id);
      const rs = `?per_page=5&q[member_group_users_status_in][]=approved&q[member_group_users_status_in][]=denied&q[member_group_users_status_in][]=invited&q[member_group_users_status_in][]=unpaid_approved&q[member_group_users_user_id_eq]=${user.id}`;
      await dispatch(getMembersGroups(rs));
      // apiCall();
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  const handleDenied=async ()=>{
    setVisible(true)
   try {
    const res = await gcServices.checkIsMembership(user?.id, showDeniedMessage?.id);
    console.log(res);
    handleDeleteMember(res?.member_group_users[0]?.id);
    setVisible(false)
   } catch (error) {
    setVisible(false)
   }
   

  }

  const handleReview = (groupInfo: any) => {
    // Dispatch action to save group details
    dispatch(
      setGroupInfo({
        user: groupInfo?.owner,
        id: groupInfo.id,
        groupName: groupInfo.name,
        groupIsPublic: groupInfo?.group_type,
        groupImage: groupInfo?.group_icon ? `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}` : "",
        groupMainImage: groupInfo?.banner_image ? `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.banner_image}` : "", // From state
        groupDesc: groupInfo?.description, // From state
        groupAnnouncements: {}, // From state
        groupWebURLs: groupInfo?.social_links, // You can replace this with the actual value
        groupApprovalRequired: true,
        piiRequired: groupInfo?.personal_information_needed ? "YES" : "NO",
        groupIsLimited: true,
        groupMaxMembers: groupInfo.participants_count,
        groupMemberShipFeeAmount: groupInfo?.fee_amount,
        groupPaymentAutomatic: groupInfo?.payment_type,
        groupAutomaticPaymentDay: groupInfo?.membership_fee_deduction_date,
        isMonthly: groupInfo?.fee_type === "yearly", // Static value
        groupPaymentField: groupInfo?.fee_amount,
        groupPaymentDescription: groupInfo?.fee_explanation,
        memberGroupUsers: groupInfo?.member_group_users,
      })
    );
    navigate("/groupinforeview");
  };

  const logout = () => {
    dispatch(reset());
    setAuthorizationToken("");
    navigate("/navigator");
  };

  return (
    <React.Fragment>
      <div style={{ ...styles.farmContainer, padding: 0 }}>
        <LoadingModal visible={visible} handleClose={() => console.log("here")} />
        <LanguageList onChange={handleLangage} Theme="Dropdown" />
        <div style={{ ...styles.farmProfileInfo, flexDirection: "column" }}>
          <img src={user.image !== null ? "data:image/jpeg;base64," + user.image : profile} alt={"userimg"} style={styles.farmProfileIcon} />

          <span style={styles.farmInfoTitle}>{user.nickname}</span>
          {user?.id_kind === 2 && (
            <span
              style={{
                fontSize: 10,
                color: colors.red[400],
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              temp user
            </span>
          )}
          {user?.nav === "1" && (
            <IconButton
              onClick={() => {
                logout();
              }}
              color="inherit"
            >
              Logout &nbsp;
              <LogoutIcon />
            </IconButton>
          )}
        </div>
        <div style={style.leaderEvent}>
          <TextButton onClick={() => navigate("/createleadereventpost")} Icon={AddIcon} title="投稿する" />
        </div>
        <div>
          <EventCalendar getSelectedEvent={getSelectedEvent} dates={dates} />
        </div>
        {personalPlan && (
          <Box>
            <Box sx={style.planHeader}>{personalPlan?.title}</Box>
            <Box sx={{ padding: "5px 10px" }}>
              <Typography sx={{ color: "#A2A2A2", fontSize: 12 }}>
                予定日:
                {dayjs(personalPlan?.start_date).format("YYYY-MM-DD (dddd)")}
              </Typography>
              <Typography>{personalPlan?.description}</Typography>
              <Box sx={{ marginTop: 2 }}>
                <Typography sx={{ textAlign: "left" }}>【WEBサイト】</Typography>
                <Separator color={farm_colors.farm_green500} sx={{ height: "2px" }} />
                <Link to={personalPlan?.web_url}>{personalPlan?.web_url}</Link>
              </Box>
              <Typography
                onClick={() =>
                  navigate("/createleadereventpost", {
                    state: { personalPlan },
                  })
                }
                sx={{ marginTop: 3, color: "#A2A2A2", fontSize: 12 }}
              >
                修正する
              </Typography>
            </Box>
          </Box>
        )}
        <div style={styles.portionBg}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            <BorderColorOutlinedIcon /> 意見広場・みんなの投稿
          </p>
          {/* // */}

          <ButtonArrow title="プライベート投稿" onClick={() => navigate("/posthistory")} />

          <ButtonArrow title={T("Comment_History")} onClick={() => navigate("/commenthistory")} />

          <ButtonArrow title="「みんなの投稿」履歴" onClick={() => navigate("/everyoneshistory")} />
        </div>

        <div style={styles.portionBg}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            <ChatIcon /> チャット
          </p>

          <ButtonArrow title="チャットリスト" onClick={() => navigate("/userchatlist")} />
          {/* 
          <Box display="flex" flexDirection="column">
            {allChats.map((chat) => {
              let icon;

              if (chat.chat_type === "one_to_one") {
                icon = (
                  <img
                    style={{ width: "26px", height: "26px", borderRadius: "50%", objectFit: "cover" }}
                    src={chat.users[1]?.image ? `data:image/png;base64,${chat.users[1].image}` : profile_placeholder}
                    alt={chat.users[0]?.nickname || "User"}
                  />
                );
              } else if (chat.chat_type === "one_to_many") {
                icon = (
                  <div style={{ display: "flex" }}>
                    <img
                      alt="User"
                      style={{
                        width: "26px",
                        height: "26px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src={chat.users[1]?.image ? `data:image/png;base64,${chat.users[1].image}` : profile_placeholder}
                    />
                    <img
                      alt="User"
                      style={{
                        width: "26px",
                        height: "26px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        marginLeft: "-20px",
                      }}
                      src={chat.users[2]?.image ? `data:image/png;base64,${chat.users[2].image}` : profile_placeholder}
                    />
                  </div>
                );
              } else {
                icon = (
                  <img
                    style={{ width: "26px" }}
                    src={chat.member_group_image ? `data:image/png;base64,${chat.member_group_image}` : group_placeholder}
                    alt={chat.member_group_name || "Group"}
                  />
                );
              }

              return (
                <IconButtonArrow
                  key={chat.id} // Use a unique key in a real app
                  icon={icon} // Pass the icon variable
                  title={chat.chat_type === "group" ? chat.member_group_name || "No Group Name" : getChatUserName(chat.users, user.id)}
                  role={""}
                  onClick={() => {
                    navigate("/userchat", {
                      state: {
                        chatId: chat.id,
                        chatUsers: chat.users,
                        chat_type: chat.chat_type,
                        isGroupChat: chat.chat_type === "group",
                        isCreator: chat.creator_id === user.id,
                        groupMembers: [],
                        groupInfo: { id: chat.member_group_id, name: chat.member_group_name },
                      },
                    });
                  }}
                />
              );
            })}
          </Box> */}
        </div>

        <div style={styles.portionBg}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            <PeopleAltOutlinedIcon /> グループ
          </p>
          <Box
            style={{
              display: "flex", // Flexbox to align items in one row
              flexDirection: "row", // Horizontal row
              gap: 6, // Optional: gap between items
            }}
          >
            <IconButtonArrow
              icon={<IconWithBackground backgroundColor={farm_colors.farm_green500} icon={<SentimentSatisfiedAltIcon style={{ color: "white", fontSize: 20 }} />} />}
              title="新しく作る"
              onClick={() => {
                dispatch(clearGroupInfo());
                navigate("/disclosure");
              }}
            />
            <IconButtonArrow
              icon={<IconWithBackground backgroundColor={farm_colors.farm_green500} icon={<SearchIcon style={{ color: "white", fontSize: 20 }} />} />}
              title="探す"
              onClick={() => {
                navigate("/group_search");
              }}
            />
          </Box>
          <IconButtonArrow
            // icon={
            //   <IconWithBackground
            //     backgroundColor={farm_colors.farm_blue200}
            //     icon={<AddLinkIcon style={{ color: "white", fontSize: 20 }} />}
            //   />
            // }
            icon={<IconWithBackground backgroundColor={farm_colors.farm_blue200} icon={<AddLinkIcon style={{ color: "white", fontSize: 20 }} />} />}
            title="招待リンクからグループに参加する"
            onClick={() => {
              navigate("/groupinvitation");
            }}
          />

          {/* groups list */}
          <Box display="flex" flexDirection="column">
            {allGroups?.map((group: any, index: number) => (
              <IconButtonArrow
                key={index} // Use a unique key in a real app
                icon={<img alt="" height={26} width={26} style={{ borderRadius: 13 }} src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${group?.group_icon}`} />}
                title={group?.name || ""} // Title from the group
                role={group?.owner?.id === user?.id ? "leader" : group?.status === "invited" ? "invited" : group?.role} // Title from the group
                onClick={() => {
                  handleGroup(group);
                }}
              />
            ))}
          </Box>
        </div>

        <div style={styles.portionBg}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            <span
              style={{
                fontSize: 18,
                border: 1,
                borderRadius: 18,
                borderStyle: "solid",
                paddingLeft: 5,
                paddingRight: 5,
                width: 10,
                height: 10,
              }}
            >
              &#165;
            </span>{" "}
            お支払い関係
          </p>
          {/* // */}

          <ButtonArrow
            title="グループに支払う"
            onClick={() => {
              navigate("/grouppayment");
            }}
          />

          <ButtonArrow
            title={"立て替え精算"}
            onClick={() => {
              navigate("/paymentreimbursement");
            }}
          />

          <ButtonArrow title="利用履歴" onClick={() => navigate("/paymenthistory")} />
          <ButtonArrow
            title="立て替え精算履歴"
            onClick={() => {
              navigate("/reimbursements");
            }}
          />
        </div>

        <div style={styles.portionBg}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            <SettingsOutlinedIcon /> その他
          </p>
          {/* // */}

          <Accordion
            // variant="elevation"
            className=""
            style={{
              boxShadow: "none",
              marginLeft: 16,
              marginRight: 16,
              borderRadius: 8,
              marginBottom: 14,
              height: accordianHeight,
              paddingTop: -4,
            }}
            expanded={expanded === "3" ? true : false}
            onChange={() => handleChange(expanded !== "0" ? "0" : "3")}
          >
            <AccordionSummary
              sx={{ color: "black", minHeight: 40, height: 40 }}
              expandIcon={
                expanded === "3" ? (
                  <KeyboardArrowUpOutlinedIcon fontSize={"large"} style={{ color: "black", fontSize: 22 }} />
                ) : (
                  <ArrowForwardIosIcon style={{ color: "black", fontSize: 18 }} />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontSize: 14 }}>{T("Questionnaire")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  onClick={(event) => {
                    const target = event.target as HTMLElement;
                    if (target.tagName === "A") {
                      event.preventDefault();
                      const url = (target as HTMLAnchorElement).href;
                      // You can add additional logic here, such as opening the URL in a new tab/window
                      //window.open(url, "_system");
                      try {
                        const messageData = {
                          url: url,
                          condition: true,
                        };
                        window.parent.postMessage({ type: "url", data: messageData }, "*");
                      } catch (error) {}
                    }
                  }}
                >
                  <a style={{ fontSize: 14 }} href="https://forms.office.com/r/7fnxwKXWRW">
                    https://forms.office.com/r/7fnxwKXWRW
                  </a>
                </p>
                <Divider style={styles.farmDivider3} />
              </div>
            </AccordionDetails>
          </Accordion>

          <ButtonArrow title={"ダッシュボード"} onClick={() => navigate("/dashboard")} />
            <MessageModal showButton open={showDeniedMessage?.id?true:false} message={'恐れ入りますが、参加可能人数に達したなどの理由によりグループに参加できませんでした。'} onClose={(()=>setDeniedMessage(undefined))} onContinue={()=>{ setDeniedMessage(undefined);handleDenied()}} />
          {/* <ButtonArrow title="災害時の安否確認" onClick={() => {}} /> */}
        </div>
      </div>
    </React.Fragment>
  );
}

const style = {
  addIcon: {
    border: "2px solid",
    borderRadius: 5,
    borderColor: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  leaderEvent: {
    width: "fit-content",
    marginLeft: "auto",
    marginRight: "10px",
  },
  planHeader: {
    width: "100%",
    backgroundColor: "#E3F0FF",
    padding: "5px 10px",
    fontWeight: 600,
  },
};
