import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "@mui/material/Tooltip";
import OptionsModal2 from "../OptionsModal2";
import AddUserSuccessModal from "./AddUserSuccessModal";
import ConfirmationModal from "./ConfirmationModal";
import { useNavigate } from "react-router-dom";

interface Option {
  title: string;
  path: string;
  disabled: boolean;
}

interface User {
  id: number | string;
  [key: string]: any; // Add more fields as needed
}

interface UserCardListProps {
  user?: User;
  userName: string;
  role?: string;
  currentUserId?: string | number;
  onApprove: () => void;
  onDelete?: (id: number | string) => void;
  onMoreOptions: () => void;
  onChatPress: (user: any) => void;
  onSwitchingRoles?: (user: any) => void;
  showMoreOptionsIcon?: boolean;
  userAvatar?: string;
  groupOwnerId?: number | string;
  isInChat?: boolean;
  onAddNewUser?: (user: any) => void;
  openAddUserModal?: boolean;
  allowedShowName?: boolean;
  setOpenAddUserModal?: (open: boolean) => void;
  myRole?: string;
}

const UserCardList: React.FC<UserCardListProps> = ({
  user,
  userName,
  role,
  currentUserId,
  onChatPress,
  onSwitchingRoles,
  showMoreOptionsIcon = true,
  onDelete,
  userAvatar,
  onAddNewUser,
  openAddUserModal,
  setOpenAddUserModal,
  myRole,
  allowedShowName = false,
}) => {
  const [openOption, setOpenOptions] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showName, setShowName] = useState(false);
  const navigate = useNavigate();

  const options: Option[] = [
    { title: "投稿を見る", path: "/userpost", disabled: false },
    { title: "チャットする", path: "/chat", disabled: false },
    { title: "プライベート投稿を送る", path: "/private_post", disabled: false },
    { title: "役割を変更する", path: "/switchingRole", disabled: false },
    { title: "強制退会させる", path: "/deleteUser", disabled: false },
  ];

  const currentUserOptions: Option[] = [
    { title: "投稿を見る", path: "/userpost", disabled: false },
    { title: "チャットする", path: "/chat", disabled: true },
    { title: "プライベート投稿を送る", path: "/private_post", disabled: true },
    { title: "役割を変更する", path: "/switchingRole", disabled: true },
    { title: "強制退会させる", path: "/deleteUser", disabled: true },
  ];

  const getOptions = (): Option[] => {
    let opt = options;
    // If the current user is the user themselves, return their specific options
    if (user && currentUserId === user.id) {
      return currentUserOptions;
    }

    if (myRole?.toLocaleLowerCase() === "leader" || myRole?.toLocaleLowerCase() === "admin") {
      return options;
    }

    if (myRole?.toLocaleLowerCase() === "sub-leader") {
      if (role?.toLocaleLowerCase() === "leader" || role?.toLocaleLowerCase() === "admin") {
        opt = opt.map((option) => (option.path === "/switchingRole" || option.path === "/deleteUser" ? { ...option, disabled: true } : option));
        return opt;
      } else {
        return options;
      }
    }

    if (myRole?.toLocaleLowerCase() === "member" || myRole?.toLocaleLowerCase() === "finance") {
      opt = opt.map((option) => (option.path === "/switchingRole" || option.path === "/deleteUser" ? { ...option, disabled: true } : option));
    }

    return opt;
  };

  const handleOptionsModal = () => {
    setOpenOptions(!openOption);
  };

  const handleSelect = (path: string) => {
    if (path === "/userpost") {
      navigate("/viewGroupPost", {
        state: {
          post_type: "user",
          user: {
            nickname: user?.nickname,
            id: user?.id,
            image: user?.image,
          },
        },
      });
    }
    if (path === "/private_post") {
      navigate("/createGroupPost", {
        state: {
          post_type: "private_users",
          preUsers: [
            {
              nickname: user?.nickname,
              user_id: user?.id,
              image: user?.image,
            },
          ],
        },
      });
    }

    if (path === "/chat" && onChatPress) {
      onChatPress(user);
    }
    if (path === "/addNewUser" && onAddNewUser) {
      onAddNewUser(user);
    }

    if (path === "/switchingRole" && onSwitchingRoles) {
      onSwitchingRoles(user);
    }

    if (path === "/deleteUser" && onDelete) {
      console.log("onDelete");
      setOpenOptions(false);
      setShowConfirmationModal(true);
    }
  };

  useEffect(() => {
    if (showName) {
      setTimeout(() => {
        setShowName(false);
      }, 2000);
    }
  }, [showName]);

  return (
    <Tooltip
      placement="bottom-start"
      open={showName}
      onClose={() => setShowName(false)}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      slotProps={{
        popper: {
          disablePortal: true,
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [30, -50],
              },
            },
          ],
        },
      }}
      title={user?.name}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          padding: "7px",
          margin: "0 16px",
          borderBottom: "2px solid #E5E5E5",
        }}
      >
        {/* Left Section: User Icon, Name, Role */}
        <Box onClick={() => {if(myRole==='member')return; setShowName(allowedShowName && true)}} sx={{ display: "flex", alignItems: "center" }}>
          <Avatar src={userAvatar} sx={{ width: 30, height: 30, mr: 2 }} alt={userName?.charAt(0)?.toUpperCase()} />
          <Box>
            <Typography>{userName}</Typography>
            {role && (
              <Typography variant="body2" color="text.secondary">
                &nbsp; {role}
              </Typography>
            )}
          </Box>
        </Box>

        {/* Options Button */}
        {showMoreOptionsIcon && (
          <IconButton onClick={handleOptionsModal} sx={{ padding: 0 }}>
            <MoreHorizIcon sx={{ color: "#4792E0" }} />
          </IconButton>
        )}

        <OptionsModal2 open={openOption} onClose={handleOptionsModal} options={getOptions()} onSelect={handleSelect} avatar={userAvatar} userName={userName} />
        <ConfirmationModal
          message={"このユーザーを削除してもよろしいですか?"}
          showButton
          onClose={() => setShowConfirmationModal(false)}
          open={showConfirmationModal}
          onContinue={() => onDelete && onDelete(user?.id || "")}
        />
        <AddUserSuccessModal open={openAddUserModal || false} onClose={() => setOpenAddUserModal && setOpenAddUserModal(false)} />
      </Box>
    </Tooltip>
  );
};

export default UserCardList;
