import { Avatar, Box, CircularProgress, InputAdornment, TextField, Typography } from "@mui/material";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../common/colors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect, useState } from "react";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import MessageModal from "../../components/Models/GreenCity/MessageModal";

const AddPostUser = () => {
  const navigate = useNavigate();
  const state = useLocation().state;
  const [postUser, setPostUsers] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const [showMessage, setShowMessage] = useState("");
  const [searching, setSearching] = useState(false);

  const getImageSrc = () => {
    if (state.item?.is_anonymous === 3) {
      return "";
    } else if (state.item?.member_group) {
      return `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${state.item?.member_group?.group_icon}`;
    } else if (state.item?.user?.image) {
      return "data:image/jpeg;base64," + state.item?.user?.image;
    }
    return "";
  };

  const getName = () => {
    return state.item?.member_group ? state.item?.member_group?.name : state.item?.user?.nickname || state.item?.user?.name || " ";
  };

  useEffect(() => {
    if (state.item?.member_group) {
      setLoading(true);
      gcServices.getMemberList(state.item?.member_group?.id).then((res) => {
        console.log(res);
        const usersArray = res.member_group_users.filter((item: any) => !state.users?.some((u: any) => u?.user_id === item?.user?.id));
        setPostUsers(usersArray);
        setFilteredOptions(usersArray);
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (state.item?.member_group) {
      let res = postUser.filter((item: any) => item?.user.nickname.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
      res = res.map((item: any) => ({
        ...item,
        added: state.users?.some((u: any) => u?.id === item.id),
      }));
      setFilteredOptions(res);
    } else {
      searchUsersOnline(searchTerm);
    }
  }, [searchTerm]);

  function searchUsersOnline(search: string) {
    if (search.length < 2) {
      setFilteredOptions([]);
      return;
    }
    setSearching(true);
    gcServices.searchUsers(search).then((res) => {
      // setFilteredOptions(res.users);
      console.log("res", res);
      let usersArray = res.users.filter((item: any) => !state.users?.some((u: any) => u?.user_id === item?.user?.id));
      usersArray = usersArray.map((item: any) => ({
        user: item,
      }));
      setFilteredOptions(usersArray);
      setSearching(false);
    });
  }

  const handleAddUser = (id: number) => {
    gcServices
      .AddUserInPost(state.item.id, id)
      .then((res) => {
        setShowMessage("ユーザーを追加しました");
        setFilteredOptions((prev: any) => prev.filter((item: any) => item.user.id !== id));
        setPostUsers((prev: any) => prev.filter((item: any) => item.user.id !== id));
      })
      .catch((err) => {
        setShowMessage("ユーザーを追加できません");
      });
  };

  return (
    <Box>
      <Box sx={{ padding: "1px 16px", paddingTop: "100px", borderBottom: "1px solid #ECEFF1" }}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={styles.rowCenter}>
          <Avatar sx={styles.image} src={getImageSrc()} />
          <p style={styles.nameText}>{getName()}</p>
        </Box>
      </Box>
      <Box sx={{ padding: "16px" }}>
        <Box sx={styles.searchBoxStyle}>
          <Typography sx={{ mb: 1 }}>記号を入力してユーザーを検索できます</Typography>
          <TextField
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="ユーザーを検索"
            variant="outlined"
            placeholder="ユーザーを検索"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{searching && <CircularProgress size={20} />}</InputAdornment>,
            }}
          />
        </Box>
        <Typography style={styles.titleSeprator}>この投稿を見られるユーザー</Typography>
        {loading && state.item.member_group ? (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <CircularProgress size={25} />
            <Typography sx={{ marginLeft: "10px", color: colors.farm_gray200 }}>読み込み中</Typography>
          </Box>
        ) : (
          <Box>
            {filteredOptions.map((item: any, index: number) => (
              <Box key={item.user.id} sx={styles.itemContainer}>
                <Avatar sx={styles.userImage} src={"data:image/jpeg;base64," + item.user?.image} />
                <p style={{ ...styles.nameText, fontWeight: "normal" }}>{item.user?.nickname}</p>
                <AddCircleOutlineIcon onClick={() => handleAddUser(item.user?.id)} sx={styles.addIcon} />
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <MessageModal open={showMessage !== ""} onClose={() => setShowMessage("")} message={showMessage} />
    </Box>
  );
};

const styles = {
  headerBox: {
    padding: "1px 16px",
    paddingTop: "100px",
    // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
  },
  image: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  userImage: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    objectFit: "cover",
    marginLeft: "10px",
  },
  nameText: {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: "10px",
    marginBottom: "0px",
    flex: 1,
  },
  itemContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: "16px",
    marginBottom: "16px",
    borderBottomStyle: "solid" as const,
    borderBottomColor: colors.farm_gray100,
    borderBottomWidth: 1,
    padding: "5px 0px",
  },
  rowCenter: {
    margin: "10px 0px",
    display: "flex",
    alignItems: "center",
  },
  titleSeprator: {
    borderBottomColor: colors.farm_green500,
    borderBottomWidth: 2,
    borderBottomStyle: "solid" as const,
    fontSize: 14,
    fontFamily: "mplus-1c-bold",
    marginBottom: 6,
  },
  addIcon: {
    color: colors.farm_green500,
    fontSize: "35px",
    cursor: "pointer",
    marginLeft: "10px",
  },
  searchBoxStyle: { padding: "10px 0", width: "100%", marginTop: "10px" },
};
export default AddPostUser;
