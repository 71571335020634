import { Avatar, Box, Button, Typography } from "@mui/material";
import GCBackButton from "../components/GreenCity/GCBackButton";
import { useNavigate, useLocation } from "react-router-dom";
import Separator from "../components/Separator";
import { colors } from "../common/colors";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import profile from "../assets/images/profile_img.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import CancelConfirmationModal from "../components/Models/GreenCity/CancelConfirmation";
import gcServices from "../services/app-services/greenCity/gcServicesR6";
import LoadingModal from "../components/LoadingModal";
import { useSelector } from "react-redux";
import { styles as oldstyles } from "./styles";

const EventCancelDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const { event } = location.state || {};
  const [openModal, setModal] = useState(false);
  const Users = event?.post_events[0]?.users;
  const [loading, setLoading] = useState(false);

  const userData = Users?.map(
    (response: { application_details: string; name: any; image: string }) => {
      const applicationDetails = JSON.parse(response.application_details);
      console.log(response);

      // Sum up the total_amount for each user's activities
      const totalAmount = applicationDetails.reduce(
        (sum: any, app: { total_amount: any }) => sum + app.total_amount,
        0
      );

      return {
        userName: response.name,
        Image: response?.image,
        totalAmount,
      };
    }
  );
  // Calculate the total number of users
  const totalUsers = userData?.length;

  // Calculate the grand total of all amounts
  const grandTotal = userData?.reduce(
    (sum: any, data: any) => sum + data.totalAmount,
    0
  );
  console.log(event);

  const cancelEvent = async () => {
    try {
      setLoading(true);
      await gcServices?.changePostStatus(event?.id, "cancel");
      setLoading(false);
      setTimeout(() => {
        navigate("/myfarm");
      }, 500);
     
    } catch (error) {
      setLoading(false);
      console.error(error);
    }finally{
      setLoading(false);
    }
  };

  return (
    <Box>
      <div
        style={{
          paddingTop: 100,
          paddingBottom: "12px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <LoadingModal visible={loading} handleClose={() => {}} />

        <CancelConfirmationModal
          open={openModal}
          message={"参加リクエストを承認しますか?"}
          showButton
          onContinue={() => {
            setModal(false);
            cancelEvent();
          }}
          onClose={() => {
            setModal(false);
          }}
        />
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box style={styles.eventPostingBox}>
          <Typography style={{ fontWeight: "700" }}>
            <img
              src={`${groupInfo?.groupImage}`}
              alt={"user"}
              style={{
                ...oldstyles.farmProfileIcon,
                height: 50,
                width: 50,
                margin: 0,
              }}
            />
            &nbsp;{groupInfo?.groupName}
          </Typography>
        </Box>
      </div>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          width: "100%",
          paddingTop: "10px",
          padding: "10px 15px",
        }}
      >
        グループの「めぶくPay」残高: 10,594円
      </Box>
      <Box sx={{ padding: "10px 15px" }}>
        <Typography sx={{ marginTop: 2 }}>
          イベント申込者と参加費金額
        </Typography>
        <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
        {userData?.map((user: any, index: number) => (
          <Box key={index}>
            <Box sx={styles.UserBox}>
              <Typography>{index + 1}</Typography>
              <Box sx={styles.UserDetail}>
                <Avatar
                  sx={{ width: 30, height: 30 }}
                  src={
                    user.Image !== null
                      ? "data:image/jpeg;base64," + user?.Image
                      : profile
                  }
                  alt={"userimg"}
                />
                <Typography>{user?.userName}</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "50px", marginLeft: "auto" }}>
                <Typography>{user?.totalAmount}円</Typography>
                <Box
                  sx={{ margin: "0 5px" }}
                  style={styles.optionButton}
                  //   onClick={() => setOpenOptions(true)}
                >
                  <MoreHorizIcon sx={styles.MoreOptions} />
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            イベント申込者数: {totalUsers}人
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            返金額合計: {grandTotal}円
          </Typography>
        </Box>
        <Button
          color="secondary"
          onClick={() => setModal(true)}
          sx={styles.submitButton}
          size={"large"}
          variant="outlined"
          endIcon={
            <ArrowForwardIosIcon
              sx={{ position: "absolute", right: 0, top: 0, margin: "10px" }}
            />
          }
        >
          参加費を返金しイベントを中止
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  topButtonContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginTop: 10,
    paddingLeft: 16,
    paddingRight: 16,
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
  optionButton: {
    border: "1px solid #4792E0",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1px",
  },
  headBox: {
    paddingTop: 100,
    paddingLeft: 16,
    paddingBottom: "12px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
  BoxIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    padding: 1.5,
  },

  ApplicationFilterBox: {
    padding: "30px 20px",
    backgroundColor: "#F4F4F4",
    marginTop: 4,
    borderRadius: 2,
  },
  ApplicationFilter: {
    marginTop: 2,
    display: "flex",
    gap: 1,
    padding: "12px 10px",
    borderRadius: "5px",
  },
  UserBox: {
    borderBottom: "1px gray solid",
    marginTop: "10px",
    textAlign: "left",
    paddingBottom: "8px",
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
  },
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItmes: "center",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  UserDetail: {
    display: "flex",
    alignItems: "center",
    marginLeft: 2,
    gap: 1,
  },
  MoreOptions: {
    color: "#4792E0",
    cursor: "pointer",
    fontSize: "25px",
  },
};
export default EventCancelDetails;
